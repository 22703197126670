export const maintenance = {
    "title": "Maintenance",
    "portfolio": "Une partie de notre travail",
    hero:{
        "title": "Gardez vos actifs numériques ",
        "span": "sécuritaires et performants",
        "text": "Nous offrons une gamme complète de forfaits de maintenance qui assurent la sécurité, la rapidité et l'optimisation continue de vos actifs numériques.",
    },
    keypoints:{
        "title": "Protégez-vous grâce à une ",
        "span":" maintenance proactive et planififié",
        "text": "L'excellence numérique nécessite un entretien vigilant. La maintenance régulière ne consiste pas seulement à résoudre les problèmes, il s'agit de les prévenir avant qu'ils n'affectent votre entreprise.",
    },
    banner:{
        "title": "Au-delà de la maintenance<br>",
        "span": "de base",
        "title2": "",
        "text": "<strong>La maintenance numérique est un investissement dans la continuité des affaires.</strong><br><br>Notre approche globale combine des mises à jour proactives, l'optimisation des performances et des améliorations stratégiques pour garantir que votre plateforme non seulement fonctionne parfaitement mais évolue avec vos besoins. Des correctifs de sécurité critiques aux améliorations de vitesse, nous maintenons votre présence numérique avec précision et prévoyance.",
    },
    carousel:{
        "title": "Soin numérique<br>",
        "span": "complet",
        "text": "Nous fournissons des services de maintenance continus qui protègent votre investissement et améliorent les performances de votre plateforme au fil du temps.",
    },
    form:{
        "title": "Gardez vos <span>actifs numériques</span><br>performants.",
        "span": "",
        "title2": "",
        "text": "Assurons-nous que votre plateforme reste sécurisée, efficace et optimisée pour le succès. Associez-vous à nous pour une maintenance proactive qui protège votre investissement numérique.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "Offrez-vous la paix d'esprit grâce à des ",
        "span": "entretiens constants",
        "text": "Transformez la maintenance de votre site web, passant de réparations réactives à une optimisation proactive.",
        "button": "Discutons-en",
    }
}