export const newCraft = {
    "title": "Développement Craft CMS",
    "portfolio": "Quelques-unes de nos réalisations",
    hero:{
        "title": "Flexible, intuitif et<br>",
        "span": "conçu pour la performance.",
        "text": "Nous utilisons Craft CMS, un système de gestion de contenu puissant, sécurisé et adapté aux développeurs, pour créer des expériences numériques personnalisées aussi intuitives qu'évolutives.",
    },
    keypoints:{
        "title": "Un <span>CMS qui s'adapte à vous</span>, pas l'inverse.",
        "span":"",
        "text": "La gestion de contenu ne devrait pas être frustrante ou limitante. Craft CMS combine flexibilité et expérience utilisateur simplifiée, ce qui en fait un choix idéal pour les entreprises qui ont besoin d'une alternative puissante et conviviale à WordPress.",
    },
    banner:{
        "title": "Là ou la vision rencontre",
        "span": "l'excellence",
        "title2": "",
        "text": "<strong>Craft CMS n'est pas simplement un autre système de gestion de contenu, c'est une plateforme axée sur le contenu, flexible en matière de design et adaptée aux développeurs, conçue pour les entreprises qui ont besoin de précision, de performance et d'évolutivité.</strong><br><br>Nous veillons à ce que chaque projet Craft CMS soit adapté à vos besoins uniques, combinant une gestion de contenu intuitive avec des pratiques de développement de pointe.",
    },
    carousel:{
        "title": "Développement <span>Craft CMS</span> sur mesure",
        "span": "",
        "text": "Nous fournissons des services de développement Craft CMS complets, de la configuration aux intégrations avancées, garantissant que votre plateforme numérique est fluide, efficace et évolutive.",
    },
    form:{
        "title": "Construisez votre<br>site web avec<br>",
        "span": "Craft CMS",
        "title2": "",
        "text": "Vous recherchez un CMS flexible et évolutif qui donne le contrôle aux créateurs de contenu et aux développeurs? Nous sommes là pour vous aider.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "Libérez votre ",
        "span": "potentiel digital",
        "text": "Construisons ensemble une expérience numérique axée sur le contenu et haute performance, adaptée aux besoins de votre entreprise.",
        "button": "Discutons-en",
    }
}