export const newVrar = {
    "title": "Expériences VR/AR",
    "portfolio": "Quelques-unes de nos réalisations",
    hero:{
        "title": "Transcendez la réalité avec des <span>expériences de réalité virtuelle</span> immersives",
        "span": "",
        "text": "Nous créons des solutions interactives de VR et AR qui captent l'attention du public, redéfinissent l'engagement et donnent vie à votre marque dans des dimensions entièrement nouvelles.",
    },
    keypoints:{
        "title": "Des activations uniques qui ",
        "span":"redéfinissent l'engagement",
        "text": "La réalité virtuelle et augmentée transforment la façon dont les entreprises se connectent, s'engagent et communiquent—mais créer des expériences VR/AR significatives nécessite une expertise tant en technologie qu'en narration.",
    },
    banner:{
        "title": "Des experiences qui vous font ",
        "span": "ressentir",
        "title2": "",
        "text": "<strong>La VR et la AR vont au-delà des écrans traditionnels—elles placent les utilisateurs au cœur de l'expérience.</strong><br><br> Qu'il s'agisse de visites virtuelles, de simulations de formation interactives ou d'interactions augmentées avec les produits, nous créons des solutions à fort impact qui laissent une impression durable.",
    },
    carousel:{
        "title": "De la <span>réalité virtuelle</span> sur mesure pour chaque industrie",
        "span": "",
        "text": "Nous développons des solutions VR/AR hautement immersives adaptées aux entreprises, événements, à l'éducation et au divertissement.",
    },
    form:{
        "title": "Créez une expérience VR/AR ",
        "span": "qui se démarque",
        "title2": "",
        "text": "Parlez-nous de votre projet, et nous créerons une solution immersive de VR/AR qui favorise l'innovation et l'impact.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "L'innovation se produit là où la ",
        "span": "réalité rencontre l'imagination.",
        "text": "Créons ensemble une expérience de VR ou AR totalement immersive qui transforme la façon dont votre public interagit avec votre marque.",
        "button": "Discutons-en",
    }
}