export const security = {
    "title": "Sécurité",
    "portfolio": "Une partie de notre travail",
    hero:{
        "title": "Nos <span>services de sécurité</span> protègent et renforcent vos ",
        "span": "actifs numériques",
        "text": "Vos actifs numériques ont une immense valeur—les protéger n'est pas seulement une précaution, c'est une nécessité. Nous mettons en œuvre des cadres de sécurité robustes qui protègent les données, assurent la conformité et fortifient votre entreprise contre les menaces évolutives.",
    },
    keypoints:{
        "title": "La cybersécurité n'est pas une option, c'est un ",
        "span":"impératif commercial",
        "text": "Avec l'augmentation des cybermenaces et des exigences réglementaires plus strictes, les entreprises doivent adopter des mesures de sécurité proactives pour prévenir les violations, assurer la conformité et protéger la confiance des clients. Nos services de sécurité offrent le plus haut niveau de protection, répondant aux normes de l'industrie telles que SOC 2, ISO 27001, PCI DSS et PIPEDA.",
    },
    banner:{
        "title": "Solutions de sécurité<br>",
        "span": "axées sur la conformité",
        "title2": "",
        "text": "<strong>La sécurité est plus que des pare-feu et du chiffrement, il s'agit de conformité, de gestion des risques et de résilience à long terme.</strong><br><br>Notre approche garantit que votre entreprise répond aux normes de sécurité les plus élevées tout en restant agile et préparée aux menaces futures.",
    },
    carousel:{
        "title": "Services complets de sécurité ",
        "span": "et de conformité",
        "text": "Nous fournissons des solutions de sécurité de bout en bout qui protègent vos systèmes, assurent la conformité réglementaire et renforcent votre posture de sécurité globale.",
    },
    form:{
        "title": "Une sécurité rigoureuse et <span>conforme aux normes</span>",
        "span": "",
        "title2": "",
        "text": "Parlez-nous de vos défis en matière de sécurité, et nous élaborerons une stratégie de sécurité proactive axée sur la conformité, adaptée aux besoins de votre entreprise.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "Protégez vos données,",
        "span": "sécurisez votre avenir",
        "text": "Contactez-nous pour mettre en œuvre des protections complètes qui sauvegardent vos actifs et votre réputation.",
        "button": "Discutons-en",
    }
}