export const newOptimizely = {
    "title": "Optimizely CMS/DXP",
    "portfolio": "Quelques-uns de nos travaux",
    hero:{
        "title": "Des expériences numériques plus intelligentes<br>",
        "span": "avec Optimizely.",
        "text": "En tant que partenaires certifiés Optimizely, nous construisons des plateformes sophistiquées de contenu et d'expérience numérique (DXP) qui allient harmonieusement performance, personnalisation et évolutivité pour aider les entreprises à se développer.",
    },
    keypoints:{
        "title": "Transforming Content Management into ",
        "span":"Data-Driven Digital Excellence",
        "text": "Les entreprises d'aujourd'hui ont besoin de plus qu'un simple CMS. Optimizely combine contenu, expérimentation, personnalisation pilotée par l'IA et commerce dans une seule plateforme puissante—mais libérer son plein potentiel nécessite une implémentation experte.",
        "keypoint1Title": "Vous avez des difficultés avec l'efficacité du contenu?",
        "keypoint1Text": "Nous vous aidons à structurer votre CMS Optimizely pour rationaliser les flux de travail de contenu, facilitant la création, la gestion et la diffusion de contenu pour votre équipe.",
        "keypoint2Title": "Vous recherchez une expérience de commerce numérique fluide?",
        "keypoint2Text": "Nous intégrons Optimizely Commerce Cloud pour offrir une expérience d'achat optimisée qui stimule les conversions.",
        "keypoint3Title": "Besoin d'expériences utilisateur hyper-personnalisées?",
        "keypoint3Text": "Tirez parti des recommandations alimentées par l'IA d'Optimizely pour servir du contenu personnalisé et augmenter l'engagement.",
    },
    banner:{
        "title": "Beyond CMS—Your Complete ",
        "span": "Digital Experience Engine",
        "title2": "",
        "text": "<strong>Optimizely transcends traditional content management, offering a comprehensive platform for creating and optimizing data-driven customer journeys.</strong><br><br>From sophisticated content management to A/B testing and AI personalization, we customize Optimizely solutions to match your strategic objectives.",
    },
    carousel:{
        "title": "Solutions de bout en bout pour ",
        "span": "Optimizely CMS et DXP",
        "text": "Nous fournissons des solutions complètes Optimizely CMS/DXP, de l'implémentation à l'optimisation, garantissant que votre plateforme est flexible, évolutive et prête pour l'avenir.",
        "slide1Title": "Développement personnalisé<br>CMS Optimizely",
        "slide1Text": "Nous construisons des sites web entièrement personnalisés propulsés par Optimizely, adaptés à votre stratégie de contenu et à vos objectifs commerciaux.",
        "slide2Title": "Développement Optimizely<br>Commerce Cloud",
        "slide2Text": "Nous intégrons les solutions de commerce d'Optimizely, offrant une expérience e-commerce personnalisée et performante.",
        "slide3Title": "Personnalisation & recommandations<br>pilotées par l'IA",
        "slide3Text": "En utilisant les outils alimentés par l'IA d'Optimizely, nous créons des expériences de contenu dynamiques et spécifiques à l'utilisateur qui stimulent l'engagement.",
        "slide4Title": "Gestion multi-sites &<br> multi-langues pour entreprise",
        "slide4Text": "Nous configurons des environnements Optimizely multi-sites et multi-langues pour assurer une diffusion de contenu fluide sur les marchés mondiaux.",
    },
    form:{
        "title": "Construisez votre expérience ",
        "span": "Optimizely",
        "title2": "",
        "text": "Vous recherchez une expérience numérique évolutive, intelligente et axée sur la conversion?<br><br>Parlez-nous de votre projet, et nous élaborerons une solution qui transforme votre présence numérique avec Optimizely.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "Votre plateforme numérique devrait",
        "span": "stimuler la croissance, pas la limiter.",
        "text": "Construisons ensemble une expérience Optimizely évolutive, intelligente et performante adaptée aux besoins de votre entreprise.",
        "button": "Discutons-en",
    }
}