export const newAutomation = {
    "title": "Automation",
    "portfolio": "Some of our work",
    hero:{
        "title": "Work smarter,<br>",
        "span": "not harder",
        "text": "We help businesses automate repetitive tasks, streamline workflows, and optimize operations, allowing teams to focus on what truly matters—innovation and growth.",
    },
    keypoints:{
        "title": "Eliminate inefficiencies and  ",
        "span":"maximize productivity.",
        "text": "Time-consuming manual processes slow teams down, increase errors, and limit scalability. Automation allows your business to work faster, smarter, and more efficiently—without sacrificing quality.",
    },
    banner:{
        "title": "Intelligent automation for ",
        "span": "future-ready businesses",
        "title2": "",
        "text": "<strong>Automation isn’t just about saving time—it’s about unlocking potential.</strong><br><br> We implement tailored solutions that enhance productivity, streamline operations, and drive measurable business results. Whether it’s automating customer support, financial reporting, or inventory management, we help you stay ahead of the curve.",
    },
    carousel:{
        "title": "Smart, scalable<br>solutions for<br>",
        "span": "every industry",
        "text": "We develop custom automation solutions that integrate seamlessly into your business, optimizing efficiency across departments.",
    },
    form:{
        "title": "Time is your most valuable resource— ",
        "span": "automation ensures you use it wisely.",
        "title2": "",
        "text": "Tell us about your processes, and we’ll design a custom automation solution that increases efficiency, reduces costs, and drives growth.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "Automation isn’t just about efficiency ",
        "span": "—it’s about transformation.",
        "text": "Let’s build intelligent, scalable automation solutions that eliminate bottlenecks and drive growth.",
        "button": "Let's talk",
    }
}