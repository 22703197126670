export const newDesign = {
    "title": "Design & creative",
    "portfolio": "Some of our work",
    hero:{
        "title": "Crafting visuals<br>that ",
        "span": "captivate and convert",
        "text": "We design compelling visuals that don’t just look great—they work. Rooted in strategic insight and behavioral psychology, our creative approach enhances brand identity and user engagement.",
    },
    keypoints:{
        "title": "When good design<br>",
        "span":"isn't enough",
        "text": "A striking aesthetic is just the beginning. To truly resonate, your brand needs visuals that communicate, persuade, and drive action.",
    },
    banner:{
        "title": "Visual ",
        "span": "storytelling ",
        "title2": "with strategic design",
        "text": "<strong>We don’t just create—we craft experiences that shape perception and drive engagement.</strong><br><br> Whether it’s a complete brand identity, a user-focused web interface, or high-impact marketing assets, we combine creativity with data to design solutions that deliver.",
    },
    carousel:{
        "title": "Design services built for ",
        "span": "your brand",
        "text": "From digital experiences to print materials, we craft visuals that enhance your brand and create meaningful connections with your audience.",
    },

    form:{
        "title": "Let’s bring<br>",
        "span": "your vision ",
        "title2": "to life",
        "text": "<strong>Looking for a creative partner?</strong><br><br>Our team is ready to help shape your brand’s next big moment.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "Let’s Bring Your",
        "span": "Vision to Life",
        "text": "Our creative team is ready to craft visuals that captivate and convert.",
    },


}