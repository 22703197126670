export const newAi = {
    "title": "Intelligence artificielle",
    "portfolio": "Quelques-uns de nos travaux",
    hero:{
        "title": "Transformer l'IA d'un concept en un ",
        "span": "avantage concurrentiel",
        "text": "Nous développons des solutions d'IA intelligentes qui améliorent la prise de décision, automatisent les flux de travail complexes et génèrent un impact commercial mesurable.",
    },
    keypoints:{
        "title": "L'<span>IA dépasse</span> les limites des simples automatisations",
        "span":"",
        "text": "L'IA n'est plus un concept futuriste; c'est une nécessité commerciale. Les entreprises qui exploitent l'IA gagnent en efficacité, en perspectives prédictives et en capacités d'apprentissage adaptatif—mais pour exploiter pleinement son potentiel, vous avez besoin de la bonne stratégie.",
    },
    banner:{
        "title": "Solutions alimentées par l'IA pour un ",
        "span": "avenir plus intelligent",
        "title2": "",
        "text": "<strong>L'IA ne se contente pas de rendre les processus plus rapides—elle les rend plus intelligents.</strong><br><br> Nous créons des solutions d'IA sur mesure qui s'intègrent parfaitement à vos systèmes existants, permettant aux entreprises d'automatiser, de prédire et d'optimiser leurs opérations.",
    },
    carousel:{
        "title": "Solutions d'IA innovantes conçues pour ",
        "span": "un impact concret",
        "text": "Nous concevons, développons et déployons des solutions basées sur l'IA qui optimisent la performance, améliorent la prise de décision et enrichissent les expériences client.",
    },
    form:{
        "title": "Profitez des outils IA disponibles pour ",
        "span": "favoriser l'innovation",
        "title2": "",
        "text": "Parlez-nous de votre projet d'IA, et nous développerons une solution qui transforme vos opérations, améliore votre efficacité et crée des interactions plus intelligentes.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "L'IA pour autonomiser,",
        "span": "et révolutionner",
        "text": "Développons ensemble une solution d'IA évolutive et intelligente qui soutient l'innovation et l'efficacité pour votre organisation.",
        "button": "Discutons-en",
    }
}