export const newCloud = {
    "title": "Infrastructure Cloud (CI/CD)",
    "portfolio": "Quelques-uns de nos travaux",
    hero:{
        "title": "Découvrez la puissance de ",
        "span": "l'architecture Cloud",
        "text": "Nous concevons et implémentons des infrastructures Cloud haute performance et des pipelines CI/CD qui améliorent l'agilité, la sécurité et l'évolutivité—permettant à votre entreprise d'innover plus rapidement.",
    },
    keypoints:{
        "title": "Profitez du plein potentiel des ",
        "span":"infrastructures Cloud",
        "text": "Les technologies Cloud offrent une évolutivité à la demande, une rentabilité et une sécurité améliorée, mais gérer efficacement une infrastructure Cloud nécessite une expertise approfondie et une approche stratégique. ",
    },
    banner:{
        "title": "<span>Solutions Cloud</span> durables pour les entreprises modernes",
        "span": "",
        "title2": "",
        "text": "<strong>Les infrastructures Cloud ne consistent pas seulement à migrer l'hébergement, il s'agit de construire une base pour l'innovation.</strong><br><br> Nous aidons les entreprises à déployer, optimiser et automatiser des environnements Cloud qui améliorent la performance, réduisent les coûts et renforcent l'efficacité opérationnelle.",
    },
    carousel:{
        "title": "<span>Solutions Cloud</span> sécurisés et automatisés",
        "span": "",
        "text": "Nous fournissons des solutions Cloud et DevOps personnalisées qui rationalisent le déploiement, améliorent la sécurité et assurent une performance optimale.",
        "slide1Title": "",
        "slide1Text": "",
        "slide2Title": "",
        "slide2Text": "",
        "slide3Title": "",
        "slide3Text": "",
        "slide4Title": "",
        "slide4Text": "",
    },
    form:{
        "title": "Transformez <span>votre vision</span> en réalité",
        "span": "",
        "title2": "",
        "text": "Parlez-nous de vos défis, et nous aiderons votre organisation à mettre en œuvre des politiques pour sécuriser et développer votre infrastructure.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "Infrastructure Cloud,",
        "span": "agile et robuste",
        "text": "Construisons ensemble un environnement Cloud évolutif et sécurisé pour supporter la croissance de votre entreprise.",
        "button": "Discutons-en",
    }
}