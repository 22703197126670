export const home = {
    help: 'How can <span>we help?</span>',
    hero: {
        "group-title": "We're a small group of",
        "group-word": "senior",
        "group-word2": "developers",
        "group-title_2": "Digital",
        "group-word_2": "mastery",
        "group-word2_2": "at your service.",
        "help-businesses": "We help businesses, start-ups and government entities overcome their technical challenges by building amazing apps, websites and custom interfaces.",
        "get-started": "Get started",
        "our-services": "Our services",
        notificationHeader1: "Christine accepted the quote",
        notificationHeader2: "Daniel posted new content",
        notificationHeader3: "Luciana received 79 orders",
        "notification1": "A new course path has been created and an invoice was generated.",
        "notification2": "Daniel posted a new course and is currently waiting for approval.",
        "notification3": "79 new orders for the course \"Optimize your MBTI mastery\".",
        "timestamp1": "12 minutes ago",
        "timestamp2": "just now",
        "timestamp3": "yesterday at 19:21",
        "response1": "Course path created",
        "response2": "Invoice scheduled",
        "response3": "New content posted",
        "response4": "An email was dispatched to the manager",
        "response5": "orders were processed successfully",
        "response6": "Backup initiated successfully",
        "process-text": "Our process",
        "vision-planning": {
            "title": "Vision & planning",
            "description": "We build your success based on a clear strategy and SMART objectives.",
            "description2": "We rigorously plan applications that are both complex and intuitive and that reach your target audience."
        },
        "design-development": {
            "title": "Design & development",
            "description": "We understand your technical challenges and know how to help you overcome them.",
            "description2": "Our goal is to provide you with resourceful development solutions that ensure your digital success."
        },
        "maintenance-evolution": {
            "title": "Maintenance & evolution",
            "description": "Our team is here to help you keep your digital assets secure and up-to-date.",
            "description2": "We offer a reliable approach to the maintenance and continuous improvement of your digital ecosystem."
        },
        "browse-services": "Browse our services",
        "creative-services": {
            "title": "We offer creative services to medium and large organizations looking for",
            "title-word": "expertise and excellence",
            "description": "We provide simple and elegant solutions to custom and complex IT requirements. Our process allows you to secure top-tier and scalable digital assets."
        }
    },
    about: {
        "we_are_lucky": "We are lucky to work with",
        "amazing_clients": "amazing clients",
        "and_well_established_partners": "and well-established partners",
        "we_work_closely": "We work closely with",
        "high_end_marketing_agencies": "high-end marketing agencies",
        "in_Canada": "in Canada.",
        "we_help_them_create": "We help them create",
        "powerful_digital_campaigns": "powerful digital campaigns",
        "biggest_brands_world": "for some of the biggest brands in the world",
        "about_us": "What people say",
        "about_us2": "about us."
    },
    process: {
        "deliverables": "Deliverables",
        "step1": "Discovery",
        "step1_text": "We collaborate closely with key stakeholders to understand requirements and define success criteria. Our process includes researching your target audience, identifying business objectives, and establishing KPIs.",
        "step1_1": "Stakeholder Interviews",
        "step1_2": "Scope Documentation",
        "step1_3": "Objectives and KPIs",
        "step2": "Audit",
        "step2_text": "We thoroughly assess your digital assets, analyze content, brand voice, and technical infrastructure. Our team identifies potential improvements and ensures alignment with your organizational objectives. This process yields insights that inform and guide the execution phase.",
        "step2_1": "Infrastructure Analysis",
        "step2_2": "Performance Evaluation",
        "step2_3": "Audit report",
        "step3": "Prototyping",
        "step3_text": "We craft visual representations of your project's structure and layout, mapping out user journeys and core functionalities. This blueprint lays the foundation for development and ensures clarity and alignment before we write a single line of code.",
        "step3_1": "Wireframes",
        "step3_2": "Behavioural Journeys",
        "step3_3": "UI/UX Designs",
        "step4": "Development",
        "step4_text": "Our team brings the design to life by writing clean, efficient and testable code. We focus on modern programming paradigms, scalable architectures, and intuitive solutions that exceeds expectations.",
        "step4_1": "Accessibility",
        "step4_2": "Secure Backend",
        "step4_3": "API Integrations",
        "step5": "QA & Delivery",
        "step5_text": "Our QA team performs meticulous unit testing and monitors critical system components to ensure peak performance. Subsequently, our deployment specialists manage the final implementation phases and orchestrate a fluid transition for a successful product launch.",
        "step5_1": "Performance Optimization",
        "step5_2": "CI/CD Deployment",
        "step5_3": "Quality Assurance Report",
        "step5_4": "Warranty Certificate"
    },
    design:'Design & Strategy',
    design_text:'We explore the intersection of user experience and business objectives to create digital solutions that serve both.',
    development:'Development',
    development_text:'Our development practice transforms concepts into functioning digital systems, building solutions that balance performance, scalability, and maintainability.',
    automation:'Automation & Artificial Intelligence',
    automation_text:'Our solutions range from straightforward process automation to sophisticated AI implementations that learn and adapt. ',
    security: 'Security & Compliance',
    security_text:'We stay current with evolving regulatory requirements across industries and jurisdictions, ensuring your digital presence maintains compliance without compromising user experience',
    maintenance: 'Maintenance & Evolution',
    maintenance_text:'We provide structured support that extends beyond fixing issues to include performance optimization, feature enhancement, and strategic updates.',
}
