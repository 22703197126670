export const newEmails = {
    "title": "Email journeys",
    "portfolio": "Some of our work",
    hero:{
        "title": "Build stronger<br>",
        "span": "customer relationships",
        "text": "Turn subscribers into loyal customers with automated email journeys that deliver the right message at the right time.",
    },
    keypoints:{
        "title": "Smarter email<br>",
        "span":"marketing",
        "text": "Successful email marketing is about more than just sending messages. It requires well-timed, relevant communication based on customer behavior.",
    },
    banner:{
        "title": "Strategic email<br>",
        "span": "automation",
        "title2": "",
        "text": "<strong>Automation isn’t just a convenience—it’s a way to build better customer relationships.</strong><br><br>We use data, timing, and messaging strategies to create email sequences that drive results.",
    },
    carousel:{
        "title": "Email solutions ",
        "span": "designed for success",
        "text": "We build automated email sequences that adjust to customer behavior and help you achieve your goals.",
    },
    form:{
        "title": "Build an effective<br>",
        "span": "email strategy",
        "title2": "",
        "text": "Let’s create automated email sequences that increase engagement and drive sales.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "Supercharge your",
        "span": "email marketing",
        "text": "Let’s build an automated system that helps you connect with customers and grow your business.",
        "button": "Let's talk",
    }
}