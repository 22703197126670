import {home} from "./home";
import {services} from "./services";
import {caseStudies} from "./case-studies";
import {about} from "./about";
import {interfaces} from "./interfaces";
import {industries} from "./industries";
import {newDesign} from "./new-design";
import {newAi} from "./new-ai";
import {newAutomation} from "./new-automation";
import {newCloud} from "./new-cloud";
import {newCraft} from "./new-craft";
import {newCustom} from "./new-custom";
import {newCustomWeb} from "./new-customweb";
import {newDrupalWeb} from "./new-drupalweb";
import { newEmails } from "./new-emails";
import { newMobile } from "./new-mobile";
import { newOptimizely } from "./new-optimizely";
import { newVrar } from "./new-vrar";
import {commerce} from "./commerce";
import { contentStrategy } from "./content-strategy";
import { research } from "./research";
import { audits } from "./audits";
import { maintenance } from "./maintenance";
import {security} from "./security";
import { accessibility } from "./accessibility";

export const en = {
    meta: {
        home: 'Drupal Development Agency',
        services: 'Our services',
        automation: 'Automation services',
        cloudhosting: 'Cloud Hosting Services (CI/CD)',
        craftweb: 'CraftCMS Web development services',
        customweb: 'Custom Web development services',
        design: 'Design & Creative services',
        drupalweb: 'Drupal Web development services',
        emails: 'Email Journeys services',
        interfaces: 'Custom Interfaces development services',
        mobile: 'Mobile applications development services',
        vrar: 'VR/AR Experiences development services',
        casestudies: 'Case studies',
        casestudies1: 'Case Studies: Webcampus',
        casestudies2: 'Case Studies: JB Conseil',
        casestudies3: 'Case Studies: Haribo',
        casestudies4: 'Case Studies: NSI Solutions (CPA 005)',
        about: 'About us',
        careers: 'Careers',
        whyrollin: 'Why work with us?',
        contact: 'Contact us',
        resources: 'Technical Resources',
        optimizely: 'Optimizely CMS/DXP Development',
        industries: 'Industries',
        ai: 'Artificial Intelligence',
        commerce : 'E-commerce',
        contentStrategy: 'Content strategy',
        research: 'Research & Consultation',
        audits: 'Technical Audits',
        maintenance: 'Maintenance',
        accessibility: 'Accessibility',
        security: 'Security',

    },
    general: {
        "read_more": "Read more",
        "email": "hello",
        "featured": "Featured",
        "interested": "You might also be interested in...",
        "platforms": "Platforms",
        "deliverables": "Deliverables",
        "techstack": "Tech stack",
        "message": "We received your inquiry, we'll get back to you shortly.",
        "error": "Something went wrong, please try again later.",
        "casestudy": "Read case study",
        "notfound": "Not found",
        "notfound1": "The page you are looking for does not exist.",
        "notfound2": "Return to home page",
        "lastupdate": "Last update",
        "search": "Search...",
        "noresults": "There is no result that matches your search.",
        "noresults2": "Return to resources.",
        "related": "In the same category",
        "share": "Share this article",
        'back': 'Back to the blog',
    },
    menu: {
        home: 'Home',
        services: 'Our services',
        about: 'About us',
        casestudies: 'Our work',
        customweb: 'Custom Web Development',
        drupalweb: 'Drupal Development',
        craftweb: 'Craft CMS Development',
        optimizely: 'Optimizely CMS/DXP',
        interfaces: 'Admin Interfaces',
        mobile: 'Mobile Applications',
        ecommerce: 'E-Commerce',
        design: 'Design & Creative',
        vrar: 'VR/AR Experiences',
        contentstrategy: 'Content Strategy',
        research: 'Research & Consultation',
        audits: 'Technical Audits',
        automation: 'Automation',
        emails: 'Email Journeys',
        ai: 'Artificial Intelligence',
        maintenance: 'Maintenance',
        cloudhosting: 'Cloud infrastructure',
        security: 'Security',
        accessibility: 'Accessibility',
        whyrollin: 'Why Rollin?',
        careers: 'Careers',
        resources: 'Resources',
        industries: 'Industries',
        contact: 'Contact us',
        access: 'Client access',
    },
    "book":"Book an appointment",
    "menutexts": {
        "development":"We build meaningful digital tools to solve real-life challenges.",
        "designstrategy":"We shape screens and interactions that guide users forward.",
        "automationai":"We architect intelligent systems that transform complexity into efficiency.",
        "securitycomp":"We protect digital assets with enterprise-grade security.",
        "maintenanceevol":"We support and strengthen digital platforms as they grow.",
        "customweb": "Web development services tailored to your custom requirements.",
        "drupalweb": "An enterprise-level CMS for crafting vast, scalable, and high-impact digital solutions.",
        "craftweb": "A powerful alternative to WordPress for small and medium-sized websites.",
        "optimizely": "Powerful solutions that drive enterprise success and growth.",
        "interfaces": "Custom built intranet and extranet interfaces designed to elevate your business efficiency.",
        "mobile": "Harness mobile technology to tap into new markets and broaden your reach.",
        "ecommerce": "We build reliable, innovative digital commerce platforms that convert browsers into buyers.",
        "design": "Data-driven designs that empower your brand voice.",
        "vrar": "Immersive and engaging environments that let your customers interact with your products.",
        "contentstrategy": "Efficient planning and coordination to ensure timely, successful projects.",
        "research": "In-depth analysis and insights to drive smarter digital decisions.",
        "audits": "Optimizing your website for higher visibility and sustained growth.",
        "automation": "Automate repetitive and time-consuming tasks so your team can focus on what they do best.",
        "emails": "Automated emails campaigns sent to your subscribers based on their behavior.",
        "ai": "Leverage the power of AI to automate tasks and improve your organization's capacities.",
        "maintenance": "Ongoing support to keep your site running smoothly.",
        "cloudhosting": "Cloud infrastructure that is SOC 2, ISO 27001, and PCI compliant.",
        "security": "Protecting your website with robust, cutting-edge defenses.",
        "accessibility": "Inclusive, user-friendly experiences for everyone.",
    },
    "about-texts": {
        "why-rollin": "Because we care.",
        "careers": "We are a close-knit team and we're always on the lookout for great talent.",
        "resources": "Understand the technical reasoning behind the technologies we use.",
        "industries": "Providing expert web design and development for your sector."
    },
    "aria-labels": {
        "senior_web_developer_mail": "Apply for Senior web developer position",
        "sales_specialist_mail": "Apply for Sales Director position",
        "junior_web_developer_mail": "Apply for Junior web developer position",
        "graphic_designer_mail": "Apply for Graphic designer position",
        "motion_designer_mail": "Apply for Motion designer position",
        "footer_phone": "Call us",
        "footer_phone_toll_free": "Call us toll-free",
        "footer_email": "Email us",
        "footer_linkedin": "Follow Rollin on LinkedIn",
        "glow-button": "Contact Us",
    },
    pages: {
        home: home,
        services: services,
        caseStudies: caseStudies,
        about: about,
        interfaces: interfaces,
        industries: industries,
        newDesign: newDesign,
        newAi: newAi,
        newAutomation: newAutomation,
        newCloud: newCloud,
        newCraft: newCraft,
        newCustom: newCustom,
        newCustomWeb: newCustomWeb,
        newDrupalWeb: newDrupalWeb,
        newEmails: newEmails,
        newMobile: newMobile,
        newOptimizely: newOptimizely,
        newVrar: newVrar,
        commerce: commerce,
        contentStrategy: contentStrategy,
        research: research,
        audits: audits,
        maintenance: maintenance,
        security: security,
        accessibility: accessibility,
    },
    contact: {
        "title": "Let's talk!",
        "description_1": "You have a great project idea and you need a technical partner to execute it.",
        "emphasis_1": "We are here to help you.",
        "description_2": "Fill the form to tell us about your project or send us an email at",
        form: {
            "name": "Name",
            "email": "Email",
            "phone": "Phone number",
            "company": "Company",
            "project": "Project description",
            "budget": "Project budget",
            "how_did": "How did you hear about us?",
            "submit": "Submit",
            "required": "is required",
            "telvalidation": "Phone number must be in the format xxx-xxx-xxxx",
            "emailvalidation": "Please enter a valid email address",
            "message":"Message",
        },
        options: [
            {value: 'Referral', label: 'Referral'},
            {value: 'Google', label: 'Google search'},
            {value: 'LinkedIn', label: 'LinkedIn'},
            {value: 'Dribbble', label: 'Dribbble'},
            {value: 'DesignRush', label: 'DesignRush'},
            {value: 'Reddit', label: 'Reddit'},
            {value: 'Other', label: 'Other'},
        ]
    },
    footer: {
        "ready_to_start": "Ready to start",
        "your_project": "your project?",
        "our_team_of_developers": "Our experienced development team is ready to transform your vision into reality and bring your next innovation to life.",
        "contact_us": "Contact us",
        "follow": "Follow us on",
    },
    login: 'Email',
    password: 'Password',
    connect: 'Login',
    testimonials: [
        {
          name: 'Mary price',
          position: 'Marketing Director',
          company: 'Pulse Canada',
          text: 'Would recommend <i>Rollin</i> took over a website project for us that had originally been developed by another agency, but had not met our needs in terms of functionality. They re-built and completed it very quickly and even made improvements to our user experience beyond what we had initially thought of. They were adaptable to emerging requests and easy to communicate with throughout the whole process.',
        },
        {
          name: 'Josée Boulianne',
          position: 'CEO',
          company: 'JB Conseil',
          text: 'Visionnaire ! Le travail de <i>Rollin</i> est au dela de mes attentes , qualité , rapidité et visionnaire ! ',
        },
        {
          name: 'Natalie Bertrand',
          position: 'CEO',
          company: 'Gestion mieux-être',
          text: 'Extraordinaire ! Un support extraordinaire et rassurant. Chaque étape est réalisée avec professionnalisme. Merci à toute l\'équipe de <i>Rollin</i>',
        },
        {
          name: 'Louis Drapeau',
          position: 'CMO',
          company: 'Webcampus',
          text: 'Excellent ! Nous ne pouvons pas être plus satisfait des services fournis par <i>Rollin</i>. L\'équipe est professionnelle et a travaillé efficacement pour terminer notre projet à temps et à l\'intérieur de notre budget.',
        },
        {
          name: 'Suzy Walsh',
          position: 'Vice-president marketing',
          company: 'SB Canada',
          text: 'Amazing experience ! We had a great experience working with <i>Rollin</i>. They delivered an outstanding platform that worked exactly like we wanted and are still improving it to this day.',
        },
        {
          name: 'Rita Beneen',
          position: 'Brand supervisor',
          company: 'Diesel',
          text: 'Professional, knowledgeable<br> and responsive</b>The team was professional, knowledgeable, and responsive to all of our requests. We would highly recommend <i>Rollin</i> to anyone looking for quality.',
        },
        {
          name: 'Christine Ward-Page',
          position: 'CEO',
          company: 'eOceans',
          text: 'Thank you <i>Rollin</i> enthusiastically helped us to improve important customer-facing features of our app — enabling us to meet the needs of ocean explorers around the world.',
        },
      ],

    // Design & Creative Page
    slidesDesign:[
        {
            title: "Brand Identity & Logo Design",
            text: "Distinctive visual systems that capture attention and build lasting connections.",
            image: '/images/services/design_creative/graphic-style.svg',
        },
        {
            title: "UI/UX & Web Design",
            text: "Performance-driven interfaces that maximize engagement and conversion.",
            image: '/images/services/design_creative/web-design.svg',
        },
        {
            title: "Print & Digital Marketing",
            text: "Strategic assets that amplify your message and drive action.",
            image: '/images/services/design_creative/catalog-alt.svg',
        },
        {
            title: "Illustration & Custom Graphics",
            text: "Bespoke visuals that tell your story with impact and originality.",
            image: '/images/services/design_creative/drawer.svg',
        },
        {
            title: "Motion Design & Animation",
            text: "Dynamic experiences that capture attention and enhance engagement.",
            image: '/images/services/craft/web-design.svg',
        },
        {
            title: "Packaging & Product Design",
            text: "Strategic packaging solutions that elevate perception and drive sales.",
            image: '/images/services/custom-web/code-editing.svg',
        },
    ],
    keypointsDesign:[
        {
            title: "Blending Into the Background?",
            text: "Stand out with custom design that captures your unique essence and commands attention in crowded markets.",
        },
        {
            title: "Struggling with Conversion?",
            text: "Transform browsers into buyers with psychology-backed designs that guide, engage, and convert.",
        },
        {
            title: "Brand Disconnect?",
            text: "Unite your brand experience with cohesive design systems that build recognition and trust across every touchpoint.",
        },
    ],

    // Artificial Intelligence Page
    slidesAi:[
        {
            title: "Predictive Analytics & Business Intelligence ",
            text: "Transform complex data into strategic insights through AI-powered forecasting and pattern recognition.",
            image: '/images/services/ai/brain-circuit.svg',
        },
        {
            title: "Custom Machine Learning Solutions",
            text: "Sophisticated algorithms that evolve and optimize through continuous learning.",
            image: '/images/services/ai/book-world.svg',
        },
        {
            title: "Natural Language Processing (NLP)",
            text: "Advanced text analytics, conversational AI, and emotion-aware customer engagement.",
            image: '/images/services/ai/comment-alt.svg',
        },
        {
            title: "Intelligent Process Automation",
            text: "Elevate workflows through smart automation and advanced RPA implementation.",
            image: '/images/services/ai/big-data-ai.svg',
        },
        {
            title: "Advanced Computer Vision",
            text: "Deploy cutting-edge visual recognition for object detection and facial analysis.",
            image: '/images/services/design_creative/web-design.svg',
        },
        {
            title: "Next-Generation Conversational AI",
            text: "Transform customer service with adaptive, intelligent virtual assistants.",
            image: '/images/services/optimizely/cyborg.svg',
        },
    ],
    keypointsAi:[
        {
            title: "Bottlenecked by manual processes?",
            text: "Our AI automation eliminates repetitive tasks, reducing overhead while enabling teams to focus on strategic initiatives.",
        },
        {
            title: "Seeking deeper business insights?",
            text: "Our AI systems analyze complex datasets to uncover actionable intelligence that shapes strategic decisions.",
        },
        {
            title: "Want to elevate customer engagement?",
            text: "Our AI platforms learn from user interactions to deliver tailored experiences at scale.",
        },
    ],

    // Automation Page

    slidesAutomation:[
        {
            title: "Workflow Optimization & Process Intelligence",
            text: "Transform manual procedures into automated workflows, driving productivity and ensuring precision.",
            image: '/images/services/automatisation/master-plan.svg',
        },
        {
            title: "Automated Data Management",
            text: "Transform raw data into actionable insights through automated extraction, verification, and comprehensive reporting.",
            image: '/images/services/automatisation/master-plan-integrate.svg',
        },
        {
            title: "Marketing Automation Excellence",
            text: "Deploy sophisticated automation for targeted campaigns, customer journeys, and conversion optimization.",
            image: '/images/services/automatisation/practice.svg',
        },
        {
            title: "Intelligent Customer Experience",
            text: "Implement advanced chatbots and automated support systems that enhance customer satisfaction and response efficiency.",
            image: '/images/services/automatisation/mobile-message.svg',
        },
        {
            title: "Supply Chain Intelligence",
            text: "Deploy smart inventory management and supply chain automation to optimize stock levels and minimize waste.",
            image: '/images/services/content/strategy-chess-risk.svg',
        },
        {
            title: "AI-Powered Innovation",
            text: "Leverage advanced AI solutions for predictive analytics, consumer insights, and operational excellence.",
            image: '/images/services/ai/big-data-ai.svg',
        },
    ],
    keypointsAutomation:[
        {
            title: "Trapped in repetitive tasks?",
            text: "Our solutions eliminate manual bottlenecks, enhance accuracy, and accelerate workflow execution.",
        },
        {
            title: "Seeking cost optimization?",
            text: "Implement automation that reduces operational expenses, eliminates waste, and strengthens your bottom line.",
        },
        {
            title: "Ready for exponential growth?",
            text: "Deploy scalable automation frameworks that adapt and expand alongside your business evolution.",
        },
    ],

    // Cloud Infrastructure Page

    slidesCloud:[
        {
            title: "Cloud Infrastructure Architecture & Migration",
            text: "Expert migration and custom cloud solutions optimized for AWS, Azure, and Google Cloud.",
            image: '/images/services/cloud/system-cloud.svg',
        },
        {
            title: "CI/CD Pipeline Engineering",
            text: "Sophisticated build, test, and deployment automation that accelerates delivery cycles.",
            image: '/images/services/cloud/network-cloud.svg',
        },
        {
            title: "Multi-Cloud & Hybrid Architecture",
            text: "Strategic multi-cloud implementations that optimize availability, flexibility, and cost efficiency.",
            image: '/images/services/cloud/clouds.svg',
        },
        {
            title: "Cloud Security & Compliance",
            text: "Advanced IAM, encryption, and security monitoring for data protection and compliance assurance.",
            image: '/images/services/cloud/cloud-check.svg',
        },
        {
            title: "Kubernetes & Container Orchestration",
            text: "Enterprise container deployments using Docker, Kubernetes, and Helm for maximum scalability. ",
            image: '/images/services/drupal/data-migration.svg',
        },
        {
            title: "Cloud Cost Management",
            text: "Strategic resource optimization and waste elimination to maximize cloud investment returns.",
            image: '/images/services/ecommerce/chart-mixed-up-circle-dollar.svg',
        },
    ],
    keypointsCloud:[
        {
            title: "Managing complex cloud ecosystems?",
            text: "We craft optimized cloud architectures that maximize performance while ensuring ironclad security and compliance.",
        },
        {
            title: "Seeking rapid, reliable deployments?",
            text: "Our CI/CD pipelines automate development workflows, enabling seamless integration, testing, and zero-downtime deployments.",
        },
        {
            title: "Concerned about data protection?",
            text: "We integrate industry-leading security protocols and compliance frameworks to safeguard your assets.",
        },
    ],
    // Craft CMS Page
    slidesCraft:[
        {
            title: "Bespoke Development",
            text: "We engineer Craft-powered platforms that amplify your brand and exceed user expectations.",
            image: '/images/services/craft/code-editing.svg',
        },
        {
            title: "Strategic Design",
            text: "Our designers craft immersive interfaces that elevate your brand while prioritizing user experience.",
            image: '/images/services/craft/web-design.svg',
        },
        {
            title: "Custom Plugin Engineering",
            text: "We extend Craft's capabilities through purpose-built plugins that solve your unique challenges.",
            image: '/images/services/craft/settings-window.svg',
        },
        {
            title: "Seamless Integrations",
            text: "We unite your digital ecosystem by connecting CRMs, ERPs, and essential business tools.",
            image: '/images/services/craft/api.svg',
        },
        {
            title: "Performance Engineering",
            text: "We optimize every aspect of your Craft platform for peak performance, security, and visibility.",
            image: '/images/services/maintenance/limit.svg',
        },
        {
            title: "Headless Architecture",
            text: "We implement flexible content architectures that power omnichannel experiences.",
            image: '/images/services/security/browser.svg',
        },
    ],
    keypointsCraft:[
        {
            title: "Beyond WordPress",
            text: "Craft CMS offers advanced customization and developer tools, giving you complete control over your digital ecosystem.",
        },
        {
            title: "Enterprise-grade foundation",
            text: "Built on Yii framework, Craft CMS delivers bank-level security and lightning performance for mission-critical applications.",
        },
        {
            title: "Future-proof platform",
            text: "Craft's modular architecture and flexible content modeling evolve with your organization, eliminating technical debt.",
        },
    ],

    // Custom Interface Page
    slidesCustom:[
        {
            title: "Enterprise Collaboration Hubs",
            text: "Next-generation platforms that elevate internal communication and drive workplace innovation.",
            image: '/images/services/custom/portal-enter.svg',
        },
        {
            title: "Intelligence Visualization Systems",
            text: "Strategic dashboards that transform complex data into actionable insights, empowering decisive leadership.",
            image: '/images/services/custom/web-design.svg',
        },
        {
            title: "Process Automation Solutions",
            text: "Advanced interfaces that streamline operations, eliminate redundancy, and maximize organizational efficiency.",
            image: '/images/services/custom/arrow-progress.svg',
        },
        {
            title: "Stakeholder Engagement Portals",
            text: "Sophisticated platforms that enhance collaboration and deliver critical information on demand.",
            image: '/images/services/custom/people-network-partner.svg',
        },
        {
            title: "Process Automation Solutions",
            text: "Advanced interfaces that streamline operations, eliminate redundancy, and maximize organizational efficiency.",
            image: '/images/services/automatisation/master-plan-integrate.svg',
        },
        {
            title: "Stakeholder Engagement Portals",
            text: "Sophisticated platforms that enhance collaboration and deliver critical information on demand.",
            image: '/images/services/automatisation/mobile-message.svg',
        },
    ],
    keypointsCustom:[
        {
            title: "Bottlenecks slowing you down?",
            text: "Our adaptive interfaces eliminate friction points and optimize your core operations.",
        },
        {
            title: "Data security keeping you up at night?",
            text: "We implement enterprise-grade security to safeguard your assets and maintain compliance.",
        },
        {
            title: "Disconnected systems causing frustration?",
            text: "Our seamless integrations unify your technology stack for maximum efficiency.",
        },
    ],

    // Custom Web Development Page
    slidesCustomWeb:[
        {
            title: "Custom Web Applications",
            text: "Purpose-built solutions that solve complex challenges and accelerate business growth.",
            image: '/images/services/custom-web/app-notification.svg',
        },
        {
            title: "E-commerce Development",
            text: "Scalable platforms that maximize sales and deliver exceptional shopping experiences.",
            image: '/images/services/custom-web/marketplace-alt.svg',
        },
        {
            title: "API Development & Integration",
            text: "Seamless connectivity solutions that unify your digital ecosystem.",
            image: '/images/services/custom-web/api.svg',
        },
        {
            title: "Headless & Decoupled CMS",
            text: "Flexible content architecture that powers omnichannel experiences.",
            image: '/images/services/custom-web/code-editing.svg',
        },
        {
            title: "Enterprise Portal Development",
            text: "Centralized platforms that optimize operations and enhance collaboration.",
            image: '/images/services/optimizely/language-exchange.svg',
        },
        {
            title: "SaaS Platform Development",
            text: "Robust, scalable solutions that transform your business model.",
            image: '/images/services/optimizely/cyborg.svg',
        },
    ],
    keypointsCustomWeb:[
        {
            title: "Limited by Templates?",
            text: "Break free with tailor-made solutions that match your exact specifications and amplify your competitive advantage.",
        },
        {
            title: "Future-Proof Performance?",
            text: "Scale confidently with flexible architectures designed for growth and long-term success.",
        },
        {
            title: "Unique Requirements?",
            text: "From complex integrations to custom workflows, we build solutions that transform your challenges into opportunities.",
        },
    ],

    // Drupal Web Development Page
    slidesDrupal:[
        {
            title: "Advanced Drupal Development",
            text: "Create powerful digital experiences with custom modules, specialized workflows, and business-specific functionality.",
            image: '/images/services/drupal/customization-cogwheel.svg',
        },
        {
            title: "Modern UX Design & Frontend",
            text: "Deliver exceptional user experiences through custom themes, dynamic interfaces, and brand-aligned responsive design.",
            image: '/images/services/drupal/customize-edit.svg',
        },
        {
            title: "Seamless System Integration",
            text: "Connect your Drupal platform with enterprise systems, from CRMs to marketing automation, creating unified digital operations.",
            image: '/images/services/drupal/master-plan-integrate.svg',
        },
        {
            title: "Strategic Platform Evolution",
            text: "Ensure continuity with expert migrations and strategic upgrades that maximize Drupal's latest capabilities.",
            image: '/images/services/drupal/data-migration.svg',
        },
        {
            title: "Performance Engineering",
            text: "Achieve optimal speed and reliability through advanced caching, database optimization, and infrastructure tuning.",
            image: '/images/services/craft/code-editing.svg',
        },
        {
            title: "Enterprise Security Framework",
            text: "Protect your digital assets with comprehensive security protocols, continuous monitoring, and compliance measures.",
            image: '/images/services/automatisation/master-plan.svg',
        },
    ],
    keypointsDrupal:[
        {
            title: "Scale with Confidence",
            text: "Our Drupal implementations ensure seamless growth, delivering consistent performance as your organization expands.",
        },
        {
            title: "Enterprise-grade Security",
            text: "Benefit from Drupal's industry-leading security framework, enhanced with our advanced threat protection protocols.",
        },
        {
            title: "Unlimited Customization",
            text: "From complex workflows to seamless integrations, we craft Drupal solutions that align perfectly with your business requirements.",
        },
    ],

    // Email Journeys Page
    slidesEmails:[
        {
            title: "Onboarding Excellence",
            text: "Strategic welcome sequences that set the foundation for lasting customer relationships.",
            image: '/images/services/emails/graphic-style.svg',
        },
        {
            title: "Behavioral Automation",
            text: "Dynamic journeys that adapt in real-time to subscriber engagement patterns.",
            image: '/images/services/emails/web-design.svg',
        },
        {
            title: "Lead Nurture Architecture",
            text: "Sophisticated drip campaigns that qualify and convert prospects systematically.",
            image: '/images/services/emails/catalog-alt.svg',
        },
        {
            title: "Customer Journey Mapping",
            text: "Data-driven pathway design that optimizes touchpoints for maximum impact.",
            image: '/images/services/emails/drawer-alt.svg',
        },
        {
            title: "Conversion Optimization",
            text: "Scientific testing and refinement of sequences to maximize revenue potential.",
            image: '/images/services/drupal/customization-cogwheel.svg',
        },
        {
            title: "Analytics & Intelligence",
            text: "Advanced tracking systems that transform subscriber behavior into actionable insights.",
            image: '/images/services/ai/brain-circuit.svg',
        },
    ],
    keypointsEmails:[
        {
            title: "Drowning in Low Response Rates?",
            text: "Break through inbox clutter with behavior-driven sequences that deliver compelling content exactly when your audience is most receptive.",
        },
        {
            title: "Manual Campaign Overwhelm?",
            text: "Eliminate repetitive tasks and human error through intelligent automation that scales your reach while maintaining personal connection.",
        },
        {
            title: "Missing Revenue Opportunities?",
            text: "Convert untapped potential into revenue with strategic journeys that guide prospects naturally through your sales pipeline.",
        },
    ],

    // Mobile Applications Page
    slidesMobile:[
        {
            title: "iOS & Android Native Apps",
            text: "Platform-optimized applications for Apple's App Store and Google Play that deliver premium user experiences.",
            image: '/images/services/mobile/mobile-notch.svg',
        },
        {
            title: "Cross-Platform & Hybrid Development",
            text: "Unified experiences across platforms using React Native and Flutter frameworks.",
            image: '/images/services/mobile/web.svg',
        },
        {
            title: "UI/UX Design for Mobile",
            text: "Intuitive, compelling interfaces that maximize usability and user satisfaction.",
            image: '/images/services/mobile/responsive-design.svg',
        },
        {
            title: "Enterprise Mobile Solutions",
            text: "Tailored applications that optimize business processes, enhance productivity, and streamline operations.",
            image: '/images/services/mobile/corporate-alt.svg',
        },
        {
            title: "API & Third-Party Integrations ",
            text: "Fluid integration with business tools, payment systems, analytics, and cloud services.",
            image: '/images/services/craft/api.svg',
        },
        {
            title: "Ongoing Support & App Evolution",
            text: "Continuous enhancement through updates, monitoring, and feature development.",
            image: '/images/services/automatisation/master-plan-integrate.svg',
        },
    ],
    keypointsMobile:[
        {
            title: "Struggling to reach your audience?",
            text: "Put your brand directly in users' hands with a mobile app that maximizes accessibility and engagement.",
        },
        {
            title: "Need seamless cross-platform performance?",
            text: "Our native and hybrid apps deliver exceptional experiences across iOS, Android, and other platforms.",
        },
        {
            title: "Looking for a scalable, future-proof solution?",
            text: "We create robust, adaptable apps that grow alongside your business and evolving user needs.",
        },
    ],

    // Optimizely Page
    slidesOptimizely:[
        {
            title: "Bespoke CMS Development",
            text: "We create tailored Optimizely websites that align with your content strategy and business objectives.",
            image: '/images/services/optimizely/customization-cogwheel.svg',
        },
        {
            title: "Commerce Cloud Excellence",
            text: "We implement Optimizely's commerce platform to deliver conversion-focused shopping experiences.",
            image: '/images/services/optimizely/seller-store.svg',
        },
        {
            title: "AI-Powered Personalization",
            text: "We leverage Optimizely's AI capabilities to create dynamic, personalized content experiences.",
            image: '/images/services/optimizely/cyborg.svg',
        },
        {
            title: "Global Content Management",
            text: "We architect multi-site, multi-language environments for seamless worldwide content deployment.",
            image: '/images/services/optimizely/language-exchange.svg',
        },
        {
            title: "Seamless Integration Services",
            text: "We connect Optimizely with your essential business tools to create a unified digital ecosystem.",
            image: '/images/services/craft/settings-window.svg',
        },
        {
            title: "Performance & Security Excellence",
            text: "We optimize every aspect of your Optimizely implementation for speed, security, and compliance.",
            image: '/images/services/security/screen.svg',
        },
    ],
    keypointsOptimizely:[
        {
            title: "Content Management Bottlenecks?",
            text: "We optimize your Optimizely CMS architecture to enhance content workflows, empowering teams to create and deliver content efficiently.",
        },
        {
            title: "Seeking Targeted User Experiences?",
            text: "Harness Optimizely's AI engine to deliver personalized content that drives meaningful engagement.",
        },
        {
            title: "Want Frictionless Digital Commerce?",
            text: "Our Optimizely Commerce Cloud integration creates compelling shopping experiences that maximize conversion rates.",
        },
    ],

    // VR/AR Experiences Page
    slidesVrar:[
        {
            title: "360° Virtual Tours & Walkthroughs",
            text: "Experience spaces through interactive, crystal-clear 360° tours—ideal for real estate, tourism, and corporate presentations.",
            image: '/images/services/vrar/smartphone-ar.svg',
        },
        {
            title: "Augmented Reality (AR) Product Visualization",
            text: "Let customers explore and interact with 3D products in their space through intuitive AR applications.",
            image: '/images/services/vrar/head-vr.svg',
        },
        {
            title: "VR Training & Simulation",
            text: "Create true-to-life training environments for education, healthcare, and corporate learning that enhance retention and engagement.",
            image: '/images/services/vrar/chart-user.svg',
        },
        {
            title: "Interactive AR Marketing & Retail Experiences",
            text: "Revolutionize shopping with AR-powered virtual try-ons, store navigation, and dynamic displays.",
            image: '/images/services/vrar/seller.svg',
        },
        {
            title: "Virtual Reality Brand Activations",
            text: "Deliver compelling brand narratives through immersive experiences at trade shows, exhibitions, and campaigns.",
            image: '/images/services/design_creative/graphic-style.svg',
        },
        {
            title: "Multi-Platform VR/AR App Development",
            text: "Launch VR/AR experiences across mobile, web, and leading headsets including Oculus, HTC Vive, and Microsoft HoloLens.",
            image: '/images/services/ecommerce/master-plan-integrate.svg',
        },
    ],
    keypointsVrar:[
        {
            title: "Need stronger audience engagement?",
            text: "VR/AR delivers immersive, interactive environments that mesmerize users and create enduring impact.",
        },
        {
            title: "Want dynamic product presentations?",
            text: "From virtual showrooms to interactive 3D demos, we showcase your products through rich, engaging experiences.",
        },
        {
            title: "Seeking breakthrough brand experiences?",
            text: "We craft bespoke VR/AR solutions for events, retail, training, and digital platforms that maximize engagement and conversion.",
        },
    ],

    // E-commerce Page
    slidesCommerce:[
        {
            title: "Platform Architecture",
            text: "Strategic selection and implementation across Shopify Plus, Drupal Commerce, Craft Commerce, and Optimizely ecosystems.",
            image: '/images/services/ecommerce/master-plan-integrate.svg',
        },
        {
            title: "Growth Engineering",
            text: "Systematic optimization that transforms traffic patterns into revenue streams.",
            image: '/images/services/ecommerce/chart-mixed-up-circle-dollar.svg',
        },
        {
            title: "Neural Commerce Systems",
            text: "AI engines that decode purchase patterns, automate merchandising, and orchestrate personalized experiences.",
            image: '/images/services/ecommerce/inventory-alt.svg',
        },
        {
            title: "Digital Supply Chain",
            text: "Intelligent inventory systems that predict demand and optimize fulfillment.",
            image: '/images/services/ecommerce/seller.svg',
        },
        {
            title: "Experience Orchestration",
            text: "Strategic customer journeys that maximize engagement and conversion.",
            image: '/images/services/research/analyse-alt.svg',
        },
        {
            title: "Performance Analytics",
            text: "Advanced metrics that decode customer behavior and guide strategic evolution.",
            image: '/images/services/maintenance/chart-user.svg',
        },
    ],
    keypointsCommerce:[
        {
            title: "Platform Paralysis",
            text: "Navigate complex platform decisions with strategic guidance that aligns technology with trajectory.",
        },
        {
            title: "Growth Constraints",
            text: "Break through plateaus with intelligent systems that scale your operations and amplify revenue.",
        },
        {
            title: "Market Visibility",
            text: "Illuminate opportunities through advanced analytics that decode customer behavior and market dynamics.",
        },
    ],

    // Content Strategy Page
    slidesContent:[
        {
            title: "Strategic Content Planning & Creation",
            text: "Data-informed content strategies that align with your business goals and resonate with your target audience.",
            image: '/images/services/content/strategy-chess-risk.svg',
        },
        {
            title: "SEO Content Optimization",
            text: "Research-driven keyword strategies and content optimization that enhance your search visibility and organic reach.",
            image: '/images/services/content/discover.svg',
        },
        {
            title: "Multilingual Content Adaptation",
            text: "Cultural and linguistic expertise that ensures your message maintains its impact across international markets.",
            image: '/images/services/content/english.svg',
        },
    ],
    keypointsContent:[
        {
            title: "Content that fails to convert?",
            text: "Our data-driven content strategies transform casual readers into engaged customers, blending creative storytelling with proven conversion techniques.",
            image: '/content/crossed-hand.svg',
            background:'bg-slate200'
        },
        {
            title: "Lost in translation?",
            text: "Our multilingual content expertise ensures your message maintains its impact across borders, preserving brand voice while respecting cultural nuances.",
            image: '/content/brain-doubt.svg',
            background:'bg-blue2'
        },
        {
            title: "Invisible to search engines?",
            text: "Our SEO-optimized content strategies enhance your digital visibility, driving organic traffic through strategic keyword integration and competitive analysis.",
            image: '/content/ghost.svg',
            background:'bg-white'
        },
    ],

    // Research & Consultation Page
    slidesResearch:[
        {
            title: "Competitive Intelligence & Analysis",
            text: "In-depth market research that reveals competitor strategies, industry trends, and opportunities for differentiation.",
            image: '/images/services/research/discover.svg',
        },
        {
            title: "Strategic SEO Research",
            text: "Advanced keyword analysis and content opportunity mapping that enhances your digital visibility and organic growth.",
            image: '/images/services/research/analyse-alt.svg',
        },
        {
            title: "Solution Architecture & Design",
            text: "Systematic approach to solving complex business challenges through innovative digital solutions and strategic frameworks.",
            image: '/images/services/research/roadmap.svg',
        },
    ],
    keypointsResearch:[
        {
            title: "Struggling to understand market dynamics?",
            text: "Our competitive analysis reveals actionable insights that sharpen your strategic positioning and uncover untapped opportunities.",
            image: '/research/ranking.svg',
            background:'bg-blue2'
        },
        {
            title: "Digital visibility challenges?",
            text: "Our data-driven SEO research identifies high-impact keywords and content opportunities that amplify your online presence.",
            image: '/research/tower.svg',
            background:'bg-slate200'
        },
        {
            title: "Complex business challenges?",
            text: "Our solution design process transforms intricate problems into clear, actionable strategies that drive measurable results.",
            image: '/research/map.svg',
            background:'bg-white'
        },
    ],

    // Technical Audits Page
    slidesAudits:[
        {
            title: "Market & Competitive Research",
            text: "In-depth analysis of market trends, competitor strategies, and emerging opportunities in your digital landscape.",
            image: '/images/services/audits/discover.svg',
        },
        {
            title: "Performance Pattern Analysis",
            text: "Systematic evaluation of your digital assets, identifying successful elements, challenges, and optimization opportunities.",
            image: '/images/services/audits/analyse-alt.svg',
        },
        {
            title: "Strategic Opportunity Mapping",
            text: "Research-backed roadmap combining market opportunities with internal performance insights to guide strategic planning.",
            image: '/images/services/audits/assessment-alt.svg',
        },
    ],
    keypointsAudits:[
        {
            title: "Need data-backed direction?",
            text: "Our research methodology uncovers market opportunities while our audit process evaluates your current digital performance.",
            image: '/audits/ranking.svg',
            background:'bg-white'
        },
        {
            title: "Uncertain about priorities?",
            text: "We identify both immediate improvements and long-term strategic opportunities through systematic analysis.",
            image: '/audits/scale.svg',
            background:'bg-blue2'
        },
        {
            title: "Missing the complete picture?",
            text: "Our holistic approach examines both internal performance and external opportunities to guide strategic planning.",
            image: '/audits/tv-retro.svg',
            background:'bg-slate200'
        },
    ],

    // Maintenance Page
    slidesMaintenance:[
        {
            title: "Proactive Platform Updates",
            text: "Regular CMS updates, security patches, and functionality improvements that keep your site secure and efficient.",
            image: '/images/services/maintenance/smartphone-ar.svg',
        },
        {
            title: "Performance Optimization",
            text: "Continuous monitoring and enhancement of site speed, responsiveness, and overall user experience.",
            image: '/images/services/maintenance/head-vr.svg',
        },
        {
            title: "Technical Support & Bug Resolution",
            text: "Swift identification and resolution of technical issues, ensuring uninterrupted platform performance.",
            image: '/images/services/maintenance/chart-user.svg',
        },
        {
            title: "Strategic Improvements",
            text: "Ongoing enhancements that align with emerging technologies and evolving business needs.",
            image: '/images/services/maintenance/seller.svg',
        },
    ],
    keypointsMaintenance:[
        {
            title: "Performance declining over time?",
            text: "Our proactive maintenance approach optimizes speed and functionality, keeping your platform running at peak efficiency.",
            image: '/maintenance/limit.svg',
            background:'bg-slate200'
        },
        {
            title: "Security vulnerabilities?",
            text: "Regular CMS updates and security patches protect your digital assets against emerging threats.",
            image: '/maintenance/siren.svg',
            background:'bg-white'
        },
        {
            title: "Frustrated by recurring issues?",
            text: "Our systematic maintenance process identifies and resolves problems at their source, preventing future complications.",
            image: '/maintenance/angry.svg',
            background:'bg-blue2'
        },
    ],

    // Security Page
    slidesSecurity:[
        {
            title: "Compliance Implementation",
            text: "Strategic deployment of security frameworks aligned with SOC 2, ISO 27001, PCI, and PIPEDA requirements.",
            image: '/images/services/security/smartphone-ar.svg',
        },
        {
            title: "Data Protection Architecture",
            text: "Robust security protocols that safeguard sensitive information across your digital ecosystem.",
            image: '/images/services/security/head-vr.svg',
        },
        {
            title: "Security Assessment & Monitoring",
            text: "Continuous evaluation and protection against emerging threats and vulnerabilities.",
            image: '/images/services/security/chart-user.svg',
        },
    ],
    keypointsSecurity:[
        {
            title: "Meeting compliance requirements?",
            text: "Our security implementations align with industry standards including SOC 2, ISO 27001, PCI, and PIPEDA regulations.",
            image: '/security/screen.svg',
            background:'bg-white'
        },
        {
            title: "Protecting sensitive data?",
            text: "Our comprehensive security framework safeguards critical information through multiple layers of protection.",
            image: '/security/browser.svg',
            background:'bg-slate200'
        },
        {
            title: "Navigating complex regulations?",
            text: "We transform intricate compliance requirements into clear, actionable security protocols.",
            image: '/security/shield.svg',
            background:'bg-blue2'
        },
    ],

    // Accessibility Page
    slidesAccessibility:[
        {
            title: "WCAG 2.1 Compliance Implementation",
            text: "Thorough integration of accessibility standards across your digital platform, ensuring universal usability.",
            image: '/images/services/accessibility/smartphone-ar.svg',
        },
        {
            title: "Accessibility Audit & Remediation",
            text: "Comprehensive evaluation and enhancement of existing digital assets to meet accessibility requirements.",
            image: '/images/services/accessibility/head-vr.svg',
        },
        {
            title: "Inclusive Design & Development",
            text: "Creation of digital experiences that serve all users effectively while maintaining visual appeal.",
            image: '/images/services/accessibility/chart-user.svg',
        },
    ],
    keypointsAccessibility:[
        {
            title: "Missing potential users?",
            text: "Our accessibility implementations ensure your digital content reaches and serves all users, regardless of their abilities.",
            image: '/accessibility/person.svg',
            background:'bg-blue2'
        },
        {
            title: "Concerned about compliance?",
            text: "We ensure your platform meets or exceeds WCAG 2.1 guidelines, protecting you from accessibility-related liabilities.",
            image: '/accessibility/psychology.svg',
            background:'bg-white'
        },
        {
            title: "Complex accessibility requirements?",
            text: "Our expertise transforms WCAG 2.1 guidelines into practical, effective implementations that enhance user experience for everyone.",
            image: '/accessibility/chart.svg',
            background:'bg-slate200'
        },
    ],


    // Our process Sections
    processDesign:[
        {
            title: "Vision & Planning",
            text: "We assess digital assets, analyzing content, brand voice, and infrastructure to ensure alignment. We create visual representations, mapping user journeys and core functions. Our goal is to deliver resourceful solutions for digital success.",
            image: '/images/process_vision_and_planning.png',
        },
        {
            title: "Design & Development",
            text: "We craft a distinctive visual presence that authentically represents your brand's character and connects with your target demographic. We build websites that prioritize intuitive navigation, adapt seamlessly to all devices, and incorporate search optimization principles. Our development process emphasizes accessibility standards and exceptional craftsmanship throughout.",
            image: '/images/process_design_and_development.png',
        },
        {
            title: "Maintenance & Evolution",
            text: "We deliver continuous technical oversight and updates to maintain your site's security and current functionality. Our team tracks site performance metrics, interprets user data, and implements evidence-based refinements that improve visitor satisfaction and increase meaningful actions. We guide your digital approach through thoughtful adaptation as industry standards and user expectations shift.",
            image: '/images/process_maintenance.png',
        }
    ],

}
