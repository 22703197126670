import {home} from "./home";
import {services} from "./services";
import {caseStudies} from "./case-studies";
import {about} from "./about";
import {interfaces} from "./interfaces";
import {industries} from "./industries";
import {newDesign} from "./new-design";
import {newAi} from "./new-ai";
import {newAutomation} from "./new-automation";
import {newCloud} from "./new-cloud";
import {newCraft} from "./new-craft";
import {newCustom} from "./new-custom";
import {newCustomWeb} from "./new-customweb";
import {newDrupalWeb} from "./new-drupalweb";
import {newEmails} from "./new-emails";
import {newMobile} from "./new-mobile";
import {newOptimizely} from "./new-optimizely";
import {newVrar} from "./new-vrar";
import {commerce} from "./commerce";
import {contentStrategy} from "./content-strategy";
import {research} from "./research";
import {audits} from "./audits";
import {maintenance} from "./maintenance";
import {security} from "./security";
import {accessibility} from "./accessibility";


export const fr = {
    meta: {
        home: 'Agence de développement Drupal à Québec',
        contact: 'Nous joindre',
        services: 'Nos services',
        automation: 'Services d\'automatisation',
        cloudhosting: 'Hébergement cloud (CI/CD)',
        craftweb: 'Services de développement CraftCMS',
        customweb: 'Services de développement web sur mesure',
        design: 'Services de design et créatifs',
        drupalweb: 'Services de développement Drupal',
        emails: 'Services de courriels automatisés',
        interfaces: 'Services de développement d\'interfaces personnalisées',
        mobile: 'Services de développement d\'applications mobiles',
        vrar: 'Services de développement d\'expériences VR/AR',
        casestudies: 'Études de cas',
        casestudies1: 'Études de cas - Webcampus',
        casestudies2: 'Études de cas - JB Conseil',
        casestudies3: 'Études de cas - Haribo',
        casestudies4: 'Études de cas - NSI Solutions (ACP 005)',
        about: 'À propos',
        careers: 'Carrières',
        whyrollin: 'Pourquoi travailler avec nous?',
        resources: 'Ressources techniques',
        optimizely: 'Optimizely CMS/DXP',
        ai: 'Intelligence Artificielle',
        industries: 'Industries',
        commerce: 'E-commerce',
        contentStrategy: 'Stratégie de contenu',
        research: 'Recherche et consultation',
        audits: 'Audits techniques',
        maintenance: 'Maintenance',
        accessibility: 'Accessibilité',
        security: 'Sécurité',
    },
    general: {
        "read_more": "En savoir plus",
        "email": "bonjour",
        "featured": "En vedette",
        "interested": "Vous pourriez aussi être intéressé par...",
        "platforms": "Plateformes",
        "deliverables": "Livrables",
        "techstack": "Technologies",
        "message": "Nous avons bien reçu votre demande, nous vous répondrons rapidement.",
        "error": "Une erreur s'est produite, veuillez réessayer plus tard.",
        "casestudy": "Lire l'étude de cas",
        "notfound": "Introuvable",
        "notfound1": "La page que vous recherchez n'existe pas.",
        "notfound2": "Retour à l'accueil",
        "lastupdate": "Dernière mise à jour",
        "search": "Rechercher...",
        "noresults": "Il n'y a pas de résulat pour votre recherche.",
        "noresults2": "Retour aux ressources.",
        "related": "Dans la même catégorie",
        "share": "Partager cet article",
        'back': 'Retour au blog',
    },
    menu: {
        home: 'Accueil',
        services: 'Nos services',
        about: 'À propos',
        casestudies: 'Portfolio',
        customweb: 'Développement web',
        drupalweb: 'Développement Drupal',
        craftweb: 'Développement Craft CMS',
        optimizely: 'Optimizely DXP',
        interfaces: 'Interfaces d\'administration',
        mobile: 'Applications mobiles',
        ecommerce: 'E-Commerce',
        design: 'Design & Créatif',
        vrar: 'Expériences VR/AR',
        contentstrategy: 'Stratégie de contenu',
        research: 'Recherche et consultation',
        audits: 'Audits techniques',
        automation: 'Automatisation',
        emails: 'Courriels automatisés',
        ai: 'Intelligence Artificielle',
        maintenance: 'Maintenance',
        cloudhosting: 'Infrastructure Cloud',
        security: 'Sécurité',
        accessibility: 'Accessibilité',
        whyrollin: 'Pourquoi Rollin?',
        careers: 'Carrières',
        resources: 'Ressources',
        industries: 'Industries',
        contact: 'Nous joindre',
        access: 'Accès client',
    },
    "book": "Prendre rendez-vous",
    "menutexts": {
        "development": "Nos solutions numériques résoudent des défis réels.",
        "designstrategy": "Nous créons des interfaces qui guident les utilisateurs vers l'avant.",
        "automationai": "Nous concevons des systèmes intelligents qui transforment la complexité en efficacité.",
        "securitycomp": "Nous protégeons vos actifs numériques.",
        "maintenanceevol": "Nous soutenons les plateformes numériques à mesure qu'elles se développent.",
        "customweb": "Services de développement web sur mesure et adaptés à votre réalité.",
        "drupalweb": "Un CMS de niveau entreprise pour la création de solutions numériques vastes et évolutives",
        "craftweb": "Une alternative puissante à WordPress pour les sites web de petite et moyenne envergure.",
        "optimizely": "Des solutions puissantes qui stimulent le succès et la croissance de votre entreprise.",
        "interfaces": "Des interfaces intranet et extranet sur mesure conçues pour améliorer l'efficacité de votre entreprise.",
        "mobile": "Exploitez la technologie mobile pour atteindre de nouveaux marchés et élargir votre portée.",
        "ecommerce": "Nous construisons des plateformes de commerce numérique qui transforment les visiteurs en acheteurs.",
        "design": "Des designs riches et colorés qui renforcent le positionnement de votre marque.",
        "vrar": "Des environnements immersifs et captivants permettant aux clients d'interagir avec vos produits.",
        "contentstrategy": "Efficient planning and coordination to ensure timely, successful projects.",
        "research": "Analyses approfondies pour guider des décisions numériques plus intelligentes.",
        "audits": "Optimiser votre site web pour une meilleure visibilité et une croissance durable.",
        "automation": "Automatisez les tâches répétitives pour que votre équipe puisse se concentrer sur ce qu'elle fait de mieux.",
        "emails": "Campagnes de courriels automatisés envoyés à vos abonnés en fonction de leur comportement.",
        "ai": "Exploitez la puissance de l'IA pour automatiser les tâches et améliorer les capacités de votre organization.",
        "maintenance": "Soutien continu pour assurer le bon fonctionnement de votre site.",
        "cloudhosting": "Hébergement conforme aux normes SOC 2, ISO 27001 et PCI.",
        "security": "Protéger votre site web avec des défenses robustes et de pointe.",
        "accessibility": "Des expériences inclusives et conviviales pour tous.",
    },
    "about-texts": {
        "why-rollin": "Parce que nous sommes passionnés",
        "careers": "Nous sommes une équipe familiale et toujours à la recherche de nouveaux talents.",
        "resources": "Comprenez le raisonnement technique derrière les technologies que nous utilisons.",
        "industries": "Services de conception et de développement web pour votre secteur."
    },
    "aria-labels": {
        "senior_web_developer_mail": "Postuler pour le poste de Développeur web senior",
        "sales_specialist_mail": "Postuler pour le poste de Directeur des ventes",
        "junior_web_developer_mail": "Postuler pour le poste de Développeur web junior",
        "graphic_designer_mail": "Postuler pour le poste de Graphiste",
        "motion_designer_mail": "Postuler pour le poste de Motion designer",
        "footer_phone": "Appeler nous",
        "footer_phone_toll_free": "Appeler nous sans frais",
        "footer_email": "Envoyer un courriel",
        "footer_linkedin": "Suivez Rollin sur LinkedIn",
        "glow-button": "Contactez-nous",
    },
    pages: {
        home: home,
        services: services,
        caseStudies: caseStudies,
        about: about,
        interfaces: interfaces,
        industries: industries,
        newDesign: newDesign,
        newAi: newAi,
        newAutomation: newAutomation,
        newCloud: newCloud,
        newCraft: newCraft,
        newCustom: newCustom,
        newCustomWeb: newCustomWeb,
        newDrupalWeb: newDrupalWeb,
        newEmails: newEmails,
        newMobile: newMobile,
        newOptimizely: newOptimizely,
        newVrar: newVrar,
        commerce: commerce,
        contentStrategy: contentStrategy,
        research: research,
        audits: audits,
        maintenance: maintenance,
        security: security,
        accessibility: accessibility,
    },
    contact: {
        "title": "Discutons!",
        "description_1": "Vous savez ce que vous désirez entreprendre et vous avez besoin d'un partenaire technique pour le réaliser.",
        "emphasis_1": "Nous sommes là pour vous aider.",
        "description_2": "Expliquez-nous votre projet en remplissant le formulaire ou en nous envoyant un courriel à",
        form: {
            "name": "Nom",
            "email": "Courriel",
            "phone": "Téléphone",
            "company": "Entreprise",
            "project": "Description du projet",
            "budget": "Budget",
            "how_did": "Comment avez-vous entendu parler de nous?",
            "submit": "Envoyer",
            "required": "est requis",
            "telvalidation": "Le téléphone doit être dans le format xxx-xxx-xxxx",
            "emailvalidation": "Veuillez entrer un courriel valide",
            "message": "Message",
        },
        options: [
            {value: 'Referral', label: 'Référence'},
            {value: 'Google', label: 'Recherche Google'},
            {value: 'LinkedIn', label: 'LinkedIn'},
            {value: 'Dribbble', label: 'Dribbble'},
            {value: 'DesignRush', label: 'DesignRush'},
            {value: 'Reddit', label: 'Reddit'},
            {value: 'Other', label: 'Autre'},
        ]
    },
    footer: {
        "ready_to_start": "Prêt à commencer ",
        "your_project": "votre projet?",
        "our_team_of_developers": "Notre équipe expérimentée de développement est prête à transformer votre vision en réalité et à donner vie à votre prochaine innovation.",
        "contact_us": "Nous joindre",
        "follow": "Suivez-nous sur",
    },
    login: 'Courriel',
    password: 'Mot de passe',
    connect: 'Connexion',
    testimonials: [
        {
            name: 'Mary price',
            position: 'Marketing Director',
            company: 'Pulse Canada',
            text: 'Would recommend <i>Rollin</i> took over a website project for us that had originally been developed by another agency, but had not met our needs in terms of functionality. They re-built and completed it very quickly and even made improvements to our user experience beyond what we had initially thought of. They were adaptable to emerging requests and easy to communicate with throughout the whole process.',
        },
        {
            name: 'Josée Boulianne',
            position: 'CEO',
            company: 'JB Conseil',
            text: 'Visionnaire ! Le travail de <i>Rollin</i> est au dela de mes attentes , qualité , rapidité et visionnaire ! ',
        },
        {
            name: 'Natalie Bertrand',
            position: 'CEO',
            company: 'Gestion mieux-être',
            text: 'Extraordinaire ! Un support extraordinaire et rassurant. Chaque étape est réalisée avec professionnalisme. Merci à toute l\'équipe de <i>Rollin</i>',
        },
        {
            name: 'Louis Drapeau',
            position: 'CMO',
            company: 'Webcampus',
            text: 'Excellent ! Nous ne pouvons pas être plus satisfait des services fournis par <i>Rollin</i>. L\'équipe est professionnelle et a travaillé efficacement pour terminer notre projet à temps et à l\'intérieur de notre budget.',
        },
        {
            name: 'Suzy Walsh',
            position: 'Vice-president marketing',
            company: 'SB Canada',
            text: 'Amazing experience ! We had a great experience working with <i>Rollin</i>. They delivered an outstanding platform that worked exactly like we wanted and are still improving it to this day.',
        },
        {
            name: 'Rita Beneen',
            position: 'Brand supervisor',
            company: 'Diesel',
            text: 'Professional, knowledgeable<br> and responsive</b>The team was professional, knowledgeable, and responsive to all of our requests. We would highly recommend <i>Rollin</i> to anyone looking for quality.',
        },
        {
            name: 'Christine Ward-Page',
            position: 'CEO',
            company: 'eOceans',
            text: 'Thank you <i>Rollin</i> enthusiastically helped us to improve important customer-facing features of our app — enabling us to meet the needs of ocean explorers around the world.',
        },
    ],

    // Design & Creative Page
    slidesDesign:[
        {
            title: "Identité de marque et conception de logo",
            text: "Des systèmes visuels distinctifs qui captent l'attention et créent des connexions durables.",
            image: '/images/services/design_creative/graphic-style.svg',
        },
        {
            title: "UI/UX et conception web",
            text: "Des interfaces axées sur la performance qui maximisent l'engagement et la conversion.",
            image: '/images/services/design_creative/web-design.svg',
        },
        {
            title: "Marketing imprimé et numérique",
            text: "Des actifs stratégiques qui amplifient votre message et incitent à l'action.",
            image: '/images/services/design_creative/catalog-alt.svg',
        },
        {
            title: "Illustration et graphiques personnalisés",
            text: "Des visuels sur mesure qui racontent votre histoire avec impact et originalité.",
            image: '/images/services/design_creative/drawer.svg',
        },
        {
            title: "Motion design et animation",
            text: "Des expériences dynamiques qui captent l'attention et améliorent l'engagement.",
            image: '/images/services/craft/web-design.svg',
        },
        {
            title: "Conception d'emballage et de produit",
            text: "Des solutions d'emballage stratégiques qui améliorent la perception et stimulent les ventes.",
            image: '/images/services/custom-web/code-editing.svg',
        },
    ],
    keypointsDesign:[
        {
            title: "Vous vous fondez dans le décor?",
            text: "Démarquez-vous avec un design personnalisé qui capture votre essence unique et attire l'attention sur les marchés saturés.",
        },
        {
            title: "Des difficultés avec la conversion?",
            text: "Transformez les visiteurs en acheteurs avec des designs basés sur la psychologie qui guident, engagent et convertissent.",
        },
        {
            title: "Déconnexion de marque?",
            text: "Unifiez votre expérience de marque avec des systèmes de design cohérents qui renforcent la reconnaissance et la confiance à travers tous les points de contact.",
        },
    ],

    // Artificial Intelligence Page
    slidesAi:[
        {
            title: "Analyse prédictive et intelligence d'affaires",
            text: "Transformez des données complexes en insights stratégiques grâce à la prévision et la reconnaissance de modèles alimentées par l'IA.",
            image: '/images/services/ai/brain-circuit.svg',
        },
        {
            title: "Solutions personnalisées d'apprentissage automatique",
            text: "Des algorithmes sophistiqués qui évoluent et s'optimisent grâce à l'apprentissage continu.",
            image: '/images/services/ai/book-world.svg',
        },
        {
            title: "Traitement du langage naturel (NLP)",
            text: "Analyse de texte avancée, IA conversationnelle et engagement client sensible aux émotions.",
            image: '/images/services/ai/comment-alt.svg',
        },
        {
            title: "Automatisation intelligente des processus",
            text: "Améliorez les processus grâce à l'automatisation intelligente et à l'implémentation avancée de RPA.",
            image: '/images/services/ai/big-data-ai.svg',
        },
        {
            title: "Vision par ordinateur avancée",
            text: "Déployez une reconnaissance visuelle de pointe pour la détection d'objets et l'analyse faciale.",
            image: '/images/services/design_creative/web-design.svg',
        },
        {
            title: "IA conversationnelle de nouvelle génération",
            text: "Transformez le service client avec des assistants virtuels adaptatifs et intelligents.",
            image: '/images/services/optimizely/cyborg.svg',
        },
    ],
    keypointsAi:[
        {
            title: "Bloqué par des processus manuels?",
            text: "Notre automatisation IA élimine les tâches répétitives, réduisant les frais généraux tout en permettant aux équipes de se concentrer sur les initiatives stratégiques.",
        },
        {
            title: "À la recherche d'insights commerciaux plus profonds?",
            text: "Nos systèmes d'IA analysent des ensembles de données complexes pour découvrir des renseignements exploitables qui façonnent les décisions stratégiques.",
        },
        {
            title: "Vous souhaitez améliorer l'engagement client?",
            text: "Nos plateformes d'IA apprennent des interactions des utilisateurs pour offrir des expériences personnalisées à grande échelle.",
        },
    ],

    // Automation Page

    slidesAutomation:[
        {
            title: "Optimisation des processus et intelligence des processus",
            text: "Transformez les procédures manuelles en processus automatisés, stimulant la productivité et assurant la précision.",
            image: '/images/services/automatisation/master-plan.svg',
        },
        {
            title: "Gestion automatisée des données",
            text: "Transformez les données brutes en insights exploitables grâce à l'extraction automatisée, la vérification et des rapports complets.",
            image: '/images/services/automatisation/master-plan-integrate.svg',
        },
        {
            title: "Excellence en automatisation marketing",
            text: "Déployez une automatisation sophistiquée pour les campagnes ciblées, les parcours clients et l'optimisation des conversions.",
            image: '/images/services/automatisation/practice.svg',
        },
        {
            title: "Expérience client intelligente",
            text: "Implémentez des chatbots avancés et des systèmes de support automatisés qui améliorent la satisfaction client et l'efficacité des réponses.",
            image: '/images/services/automatisation/mobile-message.svg',
        },
        {
            title: "Intelligence de la chaîne d'approvisionnement",
            text: "Déployez une gestion intelligente des stocks et une automatisation de la chaîne d'approvisionnement pour optimiser les niveaux de stock et minimiser les déchets.",
            image: '/images/services/content/strategy-chess-risk.svg',
        },
        {
            title: "Innovation alimentée par l'IA",
            text: "Exploitez des solutions d'IA avancées pour l'analyse prédictive, les insights consommateurs et l'excellence opérationnelle.",
            image: '/images/services/ai/big-data-ai.svg',
        },
    ],
    keypointsAutomation:[
        {
            title: "Piégé dans des tâches répétitives?",
            text: "Nos solutions éliminent les limitations manuelles, améliorent la précision et accélèrent l'exécution des processus.",
        },
        {
            title: "À la recherche d'une optimisation des coûts?",
            text: "Implémentez une automatisation qui réduit les dépenses opérationnelles, élimine le gaspillage et renforce votre résultat net.",
        },
        {
            title: "Prêt pour une croissance exponentielle?",
            text: "Déployez des cadres d'automatisation évolutifs qui s'adaptent et se développent avec l'évolution de votre entreprise.",
        },
    ],

    // Cloud Infrastructure Page

    slidesCloud:[
        {
            title: "Architecture d'infrastructure cloud et migration",
            text: "Migration experte et solutions cloud personnalisées optimisées pour AWS, Azure et Google Cloud.",
            image: '/images/services/cloud/system-cloud.svg',
        },
        {
            title: "Ingénierie de pipeline CI/CD",
            text: "Automatisation sophistiquée de construction, test et déploiement qui accélère les cycles de livraison.",
            image: '/images/services/cloud/network-cloud.svg',
        },
        {
            title: "Architecture multi-cloud et hybride",
            text: "Implémentations multi-cloud stratégiques qui optimisent la disponibilité, la flexibilité et l'efficacité des coûts.",
            image: '/images/services/cloud/clouds.svg',
        },
        {
            title: "Sécurité cloud et conformité",
            text: "IAM avancé, cryptage et surveillance de sécurité pour la protection des données et l'assurance de conformité.",
            image: '/images/services/cloud/cloud-check.svg',
        },
        {
            title: "Kubernetes et orchestration de conteneurs",
            text: "Déploiements de conteneurs d'entreprise utilisant Docker, Kubernetes et Helm pour une évolutivité maximale.",
            image: '/images/services/drupal/data-migration.svg',
        },
        {
            title: "Gestion des coûts cloud",
            text: "Optimisation stratégique des ressources et élimination du gaspillage pour maximiser le retour sur investissement cloud.",
            image: '/images/services/ecommerce/chart-mixed-up-circle-dollar.svg',
        },
    ],
    keypointsCloud:[
        {
            title: "Gestion d'écosystèmes cloud complexes?",
            text: "Nous concevons des architectures cloud optimisées qui maximisent la performance tout en assurant une sécurité et une conformité à toute épreuve.",
        },
        {
            title: "À la recherche de déploiements rapides et fiables?",
            text: "Nos pipelines CI/CD automatisent les processus de développement, permettant une intégration, des tests et des déploiements sans temps d'arrêt.",
        },
        {
            title: "Préoccupé par la protection des données?",
            text: "Nous intégrons des protocoles de sécurité et des cadres de conformité de pointe pour protéger vos actifs.",
        },
    ],
    // Craft CMS Page
    slidesCraft:[
        {
            title: "Développement sur mesure",
            text: "Nous concevons des plateformes propulsées par Craft qui amplifient votre marque et dépassent les attentes des utilisateurs.",
            image: '/images/services/craft/code-editing.svg',
        },
        {
            title: "Design stratégique",
            text: "Nos designers créent des interfaces immersives qui valorisent votre marque tout en priorisant l'expérience utilisateur.",
            image: '/images/services/craft/web-design.svg',
        },
        {
            title: "Ingénierie de plugins personnalisés",
            text: "Nous étendons les capacités de Craft grâce à des plugins conçus sur mesure qui résolvent vos défis uniques.",
            image: '/images/services/craft/settings-window.svg',
        },
        {
            title: "Intégrations transparentes",
            text: "Nous unifions votre écosystème numérique en connectant les CRM, ERP et outils d'affaires essentiels.",
            image: '/images/services/craft/api.svg',
        },
        {
            title: "Ingénierie de performance",
            text: "Nous optimisons chaque aspect de votre plateforme Craft pour une performance, une sécurité et une visibilité optimales.",
            image: '/images/services/maintenance/limit.svg',
        },
        {
            title: "Architecture headless",
            text: "Nous implémentons des architectures de contenu flexibles qui alimentent des expériences omnicanales.",
            image: '/images/services/security/browser.svg',
        },
    ],
    keypointsCraft:[
        {
            title: "Au-delà de WordPress",
            text: "Craft CMS offre une personnalisation avancée et des outils de développement, vous donnant un contrôle complet sur votre écosystème numérique.",
        },
        {
            title: "Fondation de niveau entreprise",
            text: "Construit sur le framework Yii, Craft CMS offre une sécurité de niveau accrue et des performances impressionante pour les applications à haut débit.",
        },
        {
            title: "Plateforme durable",
            text: "L'architecture modulaire et la modélisation flexible du contenu de Craft évoluent avec votre organisation, éliminant la dette technique.",
        },
    ],

    // Custom Interface Page
    slidesCustom:[
        {
            title: "Hubs de collaboration d'entreprise",
            text: "Plateformes de nouvelle génération qui améliorent la communication interne et stimulent l'innovation en milieu de travail.",
            image: '/images/services/custom/portal-enter.svg',
        },
        {
            title: "Systèmes de visualisation d'intelligence",
            text: "Tableaux de bord stratégiques qui transforment des données complexes en insights exploitables, permettant un leadership décisif.",
            image: '/images/services/custom/web-design.svg',
        },
        {
            title: "Solutions d'automatisation des processus",
            text: "Interfaces avancées qui rationalisent les opérations, éliminent la redondance et maximisent l'efficacité organisationnelle.",
            image: '/images/services/custom/arrow-progress.svg',
        },
        {
            title: "Portails d'engagement des parties prenantes",
            text: "Plateformes sophistiquées qui améliorent la collaboration et délivrent des informations critiques à la demande.",
            image: '/images/services/custom/people-network-partner.svg',
        },
        {
            title: "Solutions d'automatisation des processus",
            text: "Interfaces avancées qui rationalisent les opérations, éliminent la redondance et maximisent l'efficacité organisationnelle.",
            image: '/images/services/automatisation/master-plan-integrate.svg',
        },
        {
            title: "Portails d'engagement des parties prenantes",
            text: "Plateformes sophistiquées qui améliorent la collaboration et délivrent des informations critiques à la demande.",
            image: '/images/services/automatisation/mobile-message.svg',
        },
    ],
    keypointsCustom:[
        {
            title: "Des limitations vous ralentissent?",
            text: "Nos interfaces adaptatives éliminent les points de friction et optimisent vos opérations principales.",
        },
        {
            title: "La sécurité des données vous empêche de dormir?",
            text: "Nous implémentons une sécurité de niveau entreprise pour protéger vos actifs et maintenir la conformité.",
        },
        {
            title: "Des systèmes déconnectés causent de la frustration?",
            text: "Nos intégrations transparentes unifient votre pile technologique pour une efficacité maximale.",
        },
    ],

    // Custom Web Development Page
    slidesCustomWeb:[
        {
            title: "Applications web personnalisées",
            text: "Solutions conçues sur mesure qui résolvent des défis complexes et accélèrent la croissance des entreprises.",
            image: '/images/services/custom-web/app-notification.svg',
        },
        {
            title: "Développement e-commerce",
            text: "Plateformes évolutives qui maximisent les ventes et offrent des expériences d'achat exceptionnelles.",
            image: '/images/services/custom-web/marketplace-alt.svg',
        },
        {
            title: "Développement et intégration d'API",
            text: "Solutions de connectivité transparentes qui unifient votre écosystème numérique.",
            image: '/images/services/custom-web/api.svg',
        },
        {
            title: "CMS headless et découplé",
            text: "Architecture de contenu flexible qui alimente des expériences omnicanales.",
            image: '/images/services/custom-web/code-editing.svg',
        },
        {
            title: "Développement de portail d'entreprise",
            text: "Plateformes centralisées qui optimisent les opérations et améliorent la collaboration.",
            image: '/images/services/optimizely/language-exchange.svg',
        },
        {
            title: "Développement de plateforme SaaS",
            text: "Solutions robustes et évolutives qui transforment votre modèle d'affaires.",
            image: '/images/services/optimizely/cyborg.svg',
        },
    ],
    keypointsCustomWeb:[
        {
            title: "Limité par les templates?",
            text: "Libérez-vous avec des solutions sur mesure qui correspondent exactement à vos spécifications et amplifient votre avantage concurrentiel.",
        },
        {
            title: "Performance à l'épreuve du temps?",
            text: "Évoluez en toute confiance avec des architectures flexibles conçues pour la croissance et le succès à long terme.",
        },
        {
            title: "Exigences uniques?",
            text: "Des intégrations complexes aux processus personnalisés, nous construisons des solutions qui transforment vos défis en opportunités.",
        },
    ],

    // Drupal Web Development Page
    slidesDrupal:[
        {
            title: "Développement Drupal avancé",
            text: "Créez des expériences numériques puissantes avec des modules personnalisés, des processus spécialisés et des fonctionnalités spécifiques à votre entreprise.",
            image: '/images/services/drupal/customization-cogwheel.svg',
        },
        {
            title: "Design UX moderne et frontend",
            text: "Offrez des expériences utilisateur exceptionnelles grâce à des thèmes personnalisés, des interfaces dynamiques et un design responsive aligné sur votre marque.",
            image: '/images/services/drupal/customize-edit.svg',
        },
        {
            title: "Intégration transparente des systèmes",
            text: "Connectez votre plateforme Drupal avec des systèmes d'entreprise, des CRM à l'automatisation marketing, créant des opérations numériques unifiées.",
            image: '/images/services/drupal/master-plan-integrate.svg',
        },
        {
            title: "Évolution stratégique de plateforme",
            text: "Assurez la continuité avec des migrations expertes et des mises à niveau stratégiques qui maximisent les dernières capacités de Drupal.",
            image: '/images/services/drupal/data-migration.svg',
        },
        {
            title: "Ingénierie de performance",
            text: "Atteignez une vitesse et une fiabilité optimales grâce à la mise en cache avancée, l'optimisation de base de données et l'ajustement d'infrastructure.",
            image: '/images/services/craft/code-editing.svg',
        },
        {
            title: "Cadre de sécurité d'entreprise",
            text: "Protégez vos actifs numériques avec des protocoles de sécurité complets, une surveillance continue et des mesures de conformité.",
            image: '/images/services/automatisation/master-plan.svg',
        },
    ],
    keypointsDrupal:[
        {
            title: "Évoluez en toute confiance",
            text: "Nos implémentations Drupal assurent une croissance harmonieuse, offrant des performances constantes à mesure que votre organisation se développe.",
        },
        {
            title: "Sécurité de niveau entreprise",
            text: "Bénéficiez du cadre de sécurité leader de Drupal, amélioré par nos protocoles avancés de protection contre les menaces.",
        },
        {
            title: "Personnalisation illimitée",
            text: "Des processus complexes aux intégrations transparentes, nous créons des solutions Drupal qui s'alignent parfaitement avec vos exigences commerciales.",
        },
    ],

    // Email Journeys Page
    slidesEmails:[
        {
            title: "Excellence d'intégration",
            text: "Séquences d'accueil stratégiques qui établissent les fondations de relations clients durables.",
            image: '/images/services/emails/graphic-style.svg',
        },
        {
            title: "Automatisation comportementale",
            text: "Parcours dynamiques qui s'adaptent en temps réel aux modèles d'engagement des abonnés.",
            image: '/images/services/emails/web-design.svg',
        },
        {
            title: "Architecture de nurture des leads",
            text: "Campagnes de goutte à goutte sophistiquées qui qualifient et convertissent les prospects systématiquement.",
            image: '/images/services/emails/catalog-alt.svg',
        },
        {
            title: "Cartographie du parcours client",
            text: "Conception de parcours basée sur les données qui optimise les points de contact pour un impact maximal.",
            image: '/images/services/emails/drawer-alt.svg',
        },
        {
            title: "Optimisation de conversion",
            text: "Tests scientifiques et raffinement des séquences pour maximiser le potentiel de revenus.",
            image: '/images/services/drupal/customization-cogwheel.svg',
        },
        {
            title: "Analytique et intelligence",
            text: "Systèmes de suivi avancés qui transforment le comportement des abonnés en insights exploitables.",
            image: '/images/services/ai/brain-circuit.svg',
        },
    ],
    keypointsEmails:[
        {
            title: "Noyé dans des taux de réponse faibles?",
            text: "Percez à travers l'encombrement des boîtes de réception avec des séquences basées sur le comportement qui délivrent un contenu convaincant exactement quand votre audience est la plus réceptive.",
        },
        {
            title: "Submergé par les campagnes manuelles?",
            text: "Éliminez les tâches répétitives et les erreurs humaines grâce à une automatisation intelligente qui étend votre portée tout en maintenant une connexion personnelle.",
        },
        {
            title: "Opportunités de revenus manquées?",
            text: "Convertissez le potentiel inexploité en revenus avec des parcours stratégiques qui guident naturellement les prospects à travers votre pipeline de vente.",
        },
    ],

    // Mobile Applications Page
    slidesMobile:[
        {
            title: "Applications natives iOS et Android",
            text: "Applications optimisées pour l'App Store d'Apple et Google Play qui offrent des expériences utilisateur premium.",
            image: '/images/services/mobile/mobile-notch.svg',
        },
        {
            title: "Développement multiplateforme et hybride",
            text: "Expériences unifiées à travers les plateformes utilisant les frameworks React Native et Flutter.",
            image: '/images/services/mobile/web.svg',
        },
        {
            title: "Design UI/UX pour mobile",
            text: "Interfaces intuitives et convaincantes qui maximisent l'utilisabilité et la satisfaction des utilisateurs.",
            image: '/images/services/mobile/responsive-design.svg',
        },
        {
            title: "Solutions mobiles d'entreprise",
            text: "Applications sur mesure qui optimisent les processus d'affaires, améliorent la productivité et rationalisent les opérations.",
            image: '/images/services/mobile/corporate-alt.svg',
        },
        {
            title: "API et intégrations tierces",
            text: "Intégration fluide avec les outils d'entreprise, systèmes de paiement, analytiques et services cloud.",
            image: '/images/services/craft/api.svg',
        },
        {
            title: "Support continu et évolution d'application",
            text: "Amélioration continue à travers des mises à jour, de la surveillance et du développement de fonctionnalités.",
            image: '/images/services/automatisation/master-plan-integrate.svg',
        },
    ],
    keypointsMobile:[
        {
            title: "Difficultés à atteindre votre audience?",
            text: "Mettez votre marque directement dans les mains des utilisateurs avec une application mobile qui maximise l'accessibilité et l'engagement.",
        },
        {
            title: "Besoin d'une performance multiplateforme transparente?",
            text: "Nos applications natives et hybrides offrent des expériences exceptionnelles sur iOS, Android et autres plateformes.",
        },
        {
            title: "À la recherche d'une solution évolutive et pérenne?",
            text: "Nous créons des applications robustes et adaptables qui évoluent avec votre entreprise et les besoins changeants des utilisateurs.",
        },
    ],

    // Optimizely Page
    slidesOptimizely:[
        {
            title: "Développement CMS sur mesure",
            text: "Nous créons des sites web Optimizely personnalisés qui s'alignent avec votre stratégie de contenu et vos objectifs d'affaires.",
            image: '/images/services/optimizely/customization-cogwheel.svg',
        },
        {
            title: "Excellence Commerce Cloud",
            text: "Nous implémentons la plateforme commerce d'Optimizely pour offrir des expériences d'achat axées sur la conversion.",
            image: '/images/services/optimizely/seller-store.svg',
        },
        {
            title: "Personnalisation alimentée par l'IA",
            text: "Nous exploitons les capacités d'IA d'Optimizely pour créer des expériences de contenu dynamiques et personnalisées.",
            image: '/images/services/optimizely/cyborg.svg',
        },
        {
            title: "Gestion de contenu global",
            text: "Nous concevons des environnements multi-sites et multilingues pour un déploiement de contenu mondial transparent.",
            image: '/images/services/optimizely/language-exchange.svg',
        },
        {
            title: "Services d'intégration transparente",
            text: "Nous connectons Optimizely avec vos outils d'affaires essentiels pour créer un écosystème numérique unifié.",
            image: '/images/services/craft/settings-window.svg',
        },
        {
            title: "Excellence en performance et sécurité",
            text: "Nous optimisons chaque aspect de votre implémentation Optimizely pour la vitesse, la sécurité et la conformité.",
            image: '/images/services/security/screen.svg',
        },
    ],
    keypointsOptimizely:[
        {
            title: "Des limitations dans la gestion de votre contenu?",
            text: "Nous optimisons votre architecture CMS Optimizely pour améliorer les processus de contenu, permettant aux équipes de créer et de livrer du contenu efficacement.",
        },
        {
            title: "À la recherche d'expériences utilisateur ciblées?",
            text: "Exploitez le moteur d'IA d'Optimizely pour offrir du contenu personnalisé qui génère un engagement significatif.",
        },
        {
            title: "Vous voulez un commerce numérique sans friction?",
            text: "Notre intégration Optimizely Commerce Cloud crée des expériences d'achat convaincantes qui maximisent les taux de conversion.",
        },
    ],

    // VR/AR Experiences Page
    slidesVrar:[
        {
            title: "Visites virtuelles et parcours à 360°",
            text: "Explorez des espaces à travers des visites interactives à 360° d'une clarté cristalline—idéales pour l'immobilier, le tourisme et les présentations d'entreprise.",
            image: '/images/services/vrar/smartphone-ar.svg',
        },
        {
            title: "Visualisation de produits en réalité augmentée (AR)",
            text: "Permettez aux clients d'explorer et d'interagir avec des produits 3D dans leur espace grâce à des applications AR intuitives.",
            image: '/images/services/vrar/head-vr.svg',
        },
        {
            title: "Formation et simulation en VR",
            text: "Créez des environnements de formation fidèles à la réalité pour l'éducation, la santé et l'apprentissage en entreprise qui améliorent la rétention et l'engagement.",
            image: '/images/services/vrar/chart-user.svg',
        },
        {
            title: "Expériences marketing et retail interactives en AR",
            text: "Révolutionnez le shopping avec des essayages virtuels, la navigation en magasin et des affichages dynamiques alimentés par la AR.",
            image: '/images/services/vrar/seller.svg',
        },
        {
            title: "Activations de marque en réalité virtuelle",
            text: "Proposez des récits de marque captivants à travers des expériences immersives lors de salons professionnels, d'expositions et de campagnes.",
            image: '/images/services/design_creative/graphic-style.svg',
        },
        {
            title: "Développement d'applications VR/AR multiplateforme",
            text: "Lancez des expériences VR/AR sur mobile, web et les principaux casques, y compris Oculus, HTC Vive et Microsoft HoloLens.",
            image: '/images/services/ecommerce/master-plan-integrate.svg',
        },
    ],
    keypointsVrar:[
        {
            title: "Besoin d'un engagement plus fort de l'audience?",
            text: "La VR/AR offre des environnements immersifs et interactifs qui captivent les utilisateurs et créent un impact durable.",
        },
        {
            title: "Vous voulez des présentations de produits dynamiques?",
            text: "Des showrooms virtuels aux démos 3D interactives, nous présentons vos produits à travers des expériences riches et engageantes.",
        },
        {
            title: "À la recherche d'expériences de marque innovantes?",
            text: "Nous créons des solutions VR/AR sur mesure pour les événements, le retail, la formation et les plateformes numériques qui maximisent l'engagement et la conversion.",
        },
    ],

    // E-commerce Page
    slidesCommerce:[
        {
            title: "Architecture de plateforme",
            text: "Sélection stratégique et implémentation à travers les écosystèmes Shopify Plus, Drupal Commerce, Craft Commerce et Optimizely.",
            image: '/images/services/ecommerce/master-plan-integrate.svg',
        },
        {
            title: "Ingénierie de croissance",
            text: "Optimisation systématique qui transforme les modèles de trafic en flux de revenus.",
            image: '/images/services/ecommerce/chart-mixed-up-circle-dollar.svg',
        },
        {
            title: "Systèmes de commerce neuronal",
            text: "Moteurs d'IA qui décodent les modèles d'achat, automatisent le merchandising et orchestrent des expériences personnalisées.",
            image: '/images/services/ecommerce/inventory-alt.svg',
        },
        {
            title: "Chaîne d'approvisionnement numérique",
            text: "Systèmes d'inventaire intelligents qui prédisent la demande et optimisent la réalisation.",
            image: '/images/services/ecommerce/seller.svg',
        },
        {
            title: "Orchestration d'expérience",
            text: "Parcours clients stratégiques qui maximisent l'engagement et la conversion.",
            image: '/images/services/research/analyse-alt.svg',
        },
        {
            title: "Analytique de performance",
            text: "Métriques avancées qui décodent le comportement client et guident l'évolution stratégique.",
            image: '/images/services/maintenance/chart-user.svg',
        },
    ],
    keypointsCommerce:[
        {
            title: "Paralysie de plateforme",
            text: "Naviguez à travers des décisions de plateforme complexes avec des conseils stratégiques qui alignent la technologie avec la trajectoire.",
        },
        {
            title: "Contraintes de croissance",
            text: "Franchissez les plateaux avec des systèmes intelligents qui font évoluer vos opérations et amplifient les revenus.",
        },
        {
            title: "Visibilité du marché",
            text: "Illuminez les opportunités grâce à des analyses avancées qui décodent le comportement client et les dynamiques du marché.",
        },
    ],

    // Content Strategy Page
    slidesContent:[
        {
            title: "Planification et création de contenu stratégique",
            text: "Stratégies de contenu basées sur les données qui s'alignent avec vos objectifs d'affaires et résonnent avec votre public cible.",
            image: '/images/services/content/strategy-chess-risk.svg',
        },
        {
            title: "Optimisation de contenu SEO",
            text: "Stratégies de mots-clés et optimisation de contenu basées sur la recherche qui améliorent votre visibilité dans les recherches et votre portée organique.",
            image: '/images/services/content/discover.svg',
        },
        {
            title: "Adaptation de contenu multilingue",
            text: "Expertise culturelle et linguistique qui garantit que votre message maintient son impact à travers les marchés internationaux.",
            image: '/images/services/content/english.svg',
        },
    ],
    keypointsContent:[
        {
            title: "Du contenu qui ne convertit pas?",
            text: "Nos stratégies de contenu basées sur les données transforment les lecteurs occasionnels en clients engagés, combinant la narration créative avec des techniques de conversion éprouvées.",
            image: '/content/crossed-hand.svg',
            background:'bg-slate200'
        },
        {
            title: "Perdu dans la traduction?",
            text: "Notre expertise en contenu multilingue garantit que votre message maintient son impact au-delà des frontières, préservant la voix de la marque tout en respectant les nuances culturelles.",
            image: '/content/brain-doubt.svg',
            background:'bg-blue2'
        },
        {
            title: "Invisible pour les moteurs de recherche?",
            text: "Nos stratégies de contenu optimisées pour le SEO améliorent votre visibilité numérique, générant du trafic organique grâce à l'intégration stratégique de mots-clés et l'analyse concurrentielle.",
            image: '/content/ghost.svg',
            background:'bg-white'
        },
    ],

    // Research & Consultation Page
    slidesResearch:[
        {
            title: "Intelligence et analyse concurrentielle",
            text: "Recherche de marché approfondie qui révèle les stratégies des concurrents, les tendances de l'industrie et les opportunités de différenciation.",
            image: '/images/services/research/discover.svg',
        },
        {
            title: "Recherche SEO stratégique",
            text: "Analyse avancée de mots-clés et cartographie des opportunités de contenu qui améliorent votre visibilité numérique et votre croissance organique.",
            image: '/images/services/research/analyse-alt.svg',
        },
        {
            title: "Architecture et conception de solutions",
            text: "Approche systématique pour résoudre des défis d'affaires complexes à travers des solutions numériques innovantes et des cadres stratégiques.",
            image: '/images/services/research/roadmap.svg',
        },
    ],
    keypointsResearch:[
        {
            title: "Difficultés à comprendre les dynamiques du marché?",
            text: "Notre analyse concurrentielle révèle des insights exploitables qui affinent votre positionnement stratégique et découvrent des opportunités inexploitées.",
            image: '/research/ranking.svg',
            background:'bg-blue2'
        },
        {
            title: "Défis de visibilité numérique?",
            text: "Notre recherche SEO basée sur les données identifie des mots-clés à fort impact et des opportunités de contenu qui amplifient votre présence en ligne.",
            image: '/research/tower.svg',
            background:'bg-slate200'
        },
        {
            title: "Défis d'affaires complexes?",
            text: "Notre processus de conception de solutions transforme des problèmes complexes en stratégies claires et exploitables qui génèrent des résultats mesurables.",
            image: '/research/map.svg',
            background:'bg-white'
        },
    ],

    // Technical Audits Page
    slidesAudits:[
        {
            title: "Recherche de marché et concurrentielle",
            text: "Analyse approfondie des tendances du marché, des stratégies concurrentielles et des opportunités émergentes dans votre paysage numérique.",
            image: '/images/services/audits/discover.svg',
        },
        {
            title: "Analyse des modèles de performance",
            text: "Évaluation systématique de vos actifs numériques, identifiant les éléments réussis, les défis et les opportunités d'optimisation.",
            image: '/images/services/audits/analyse-alt.svg',
        },
        {
            title: "Cartographie des opportunités stratégiques",
            text: "Feuille de route basée sur la recherche combinant les opportunités du marché avec les insights de performance interne pour guider la planification stratégique.",
            image: '/images/services/audits/assessment-alt.svg',
        },
    ],
    keypointsAudits:[
        {
            title: "Besoin d'orientation basée sur les données?",
            text: "Notre méthodologie de recherche découvre les opportunités du marché tandis que notre processus d'audit évalue votre performance numérique actuelle.",
            image: '/audits/ranking.svg',
            background:'bg-white'
        },
        {
            title: "Incertain des priorités?",
            text: "Nous identifions à la fois les améliorations immédiates et les opportunités stratégiques à long terme grâce à une analyse systématique.",
            image: '/audits/scale.svg',
            background:'bg-blue2'
        },
        {
            title: "Il vous manque l'image complète?",
            text: "Notre approche holistique examine à la fois la performance interne et les opportunités externes pour guider la planification stratégique.",
            image: '/audits/tv-retro.svg',
            background:'bg-slate200'
        },
    ],

    // Maintenance Page
    slidesMaintenance:[
        {
            title: "Mises à jour proactives de plateforme",
            text: "Mises à jour régulières du CMS, correctifs de sécurité et améliorations de fonctionnalités qui maintiennent votre site sécurisé et efficace.",
            image: '/images/services/maintenance/smartphone-ar.svg',
        },
        {
            title: "Optimisation de performance",
            text: "Surveillance et amélioration continues de la vitesse du site, de la réactivité et de l'expérience utilisateur globale.",
            image: '/images/services/maintenance/head-vr.svg',
        },
        {
            title: "Support technique et résolution de bugs",
            text: "Identification et résolution rapides des problèmes techniques, assurant une performance ininterrompue de la plateforme.",
            image: '/images/services/maintenance/chart-user.svg',
        },
        {
            title: "Améliorations stratégiques",
            text: "Améliorations continues qui s'alignent avec les technologies émergentes et l'évolution des besoins d'affaires.",
            image: '/images/services/maintenance/seller.svg',
        },
    ],
    keypointsMaintenance:[
        {
            title: "Performance en déclin avec le temps?",
            text: "Notre approche de maintenance proactive optimise la vitesse et la fonctionnalité, gardant votre plateforme à son efficacité maximale.",
            image: '/maintenance/limit.svg',
            background:'bg-slate200'
        },
        {
            title: "Vulnérabilités de sécurité?",
            text: "Des mises à jour régulières du CMS et des correctifs de sécurité protègent vos actifs numériques contre les menaces émergentes.",
            image: '/maintenance/siren.svg',
            background:'bg-white'
        },
        {
            title: "Frustré par des problèmes récurrents?",
            text: "Notre processus de maintenance systématique identifie et résout les problèmes à leur source, prévenant les complications futures.",
            image: '/maintenance/angry.svg',
            background:'bg-blue2'
        },
    ],

    // Security Page
    slidesSecurity:[
        {
            title: "Implémentation de conformité",
            text: "Déploiement stratégique de cadres de sécurité alignés avec les exigences SOC 2, ISO 27001, PCI et PIPEDA.",
            image: '/images/services/security/smartphone-ar.svg',
        },
        {
            title: "Architecture de protection des données",
            text: "Protocoles de sécurité robustes qui protègent les informations sensibles à travers votre écosystème numérique.",
            image: '/images/services/security/head-vr.svg',
        },
        {
            title: "Évaluation et surveillance de sécurité",
            text: "Évaluation continue et protection contre les menaces et vulnérabilités émergentes.",
            image: '/images/services/security/chart-user.svg',
        },
    ],
    keypointsSecurity:[
        {
            title: "Respect des exigences de conformité?",
            text: "Nos implémentations de sécurité s'alignent avec les normes de l'industrie, y compris les réglementations SOC 2, ISO 27001, PCI et PIPEDA.",
            image: '/security/screen.svg',
            background:'bg-white'
        },
        {
            title: "Protection des données sensibles?",
            text: "Notre cadre de sécurité complet protège les informations critiques à travers plusieurs couches de protection.",
            image: '/security/browser.svg',
            background:'bg-slate200'
        },
        {
            title: "Navigation dans des réglementations complexes?",
            text: "Nous transformons des exigences de conformité complexes en protocoles de sécurité clairs et exploitables.",
            image: '/security/shield.svg',
            background:'bg-blue2'
        },
    ],

    // Accessibility Page
    slidesAccessibility:[
        {
            title: "Implémentation de conformité WCAG 2.1",
            text: "Intégration approfondie des normes d'accessibilité à travers votre plateforme numérique, assurant une utilisabilité universelle.",
            image: '/images/services/accessibility/smartphone-ar.svg',
        },
        {
            title: "Audit d'accessibilité et correction",
            text: "Évaluation complète et amélioration des actifs numériques existants pour répondre aux exigences d'accessibilité.",
            image: '/images/services/accessibility/head-vr.svg',
        },
        {
            title: "Conception et développement inclusifs",
            text: "Création d'expériences numériques qui servent efficacement tous les utilisateurs tout en maintenant l'attrait visuel.",
            image: '/images/services/accessibility/chart-user.svg',
        },
    ],
    keypointsAccessibility:[
        {
            title: "Utilisateurs potentiels manqués?",
            text: "Nos implémentations d'accessibilité garantissent que votre contenu numérique atteint et sert tous les utilisateurs, indépendamment de leurs capacités.",
            image: '/accessibility/person.svg',
            background:'bg-blue2'
        },
        {
            title: "Préoccupé par la conformité?",
            text: "Nous assurons que votre plateforme respecte ou dépasse les directives WCAG 2.1, vous protégeant des responsabilités liées à l'accessibilité.",
            image: '/accessibility/psychology.svg',
            background:'bg-white'
        },
        {
            title: "Exigences d'accessibilité complexes?",
            text: "Notre expertise transforme les directives WCAG 2.1 en implémentations pratiques et efficaces qui améliorent l'expérience utilisateur pour tous.",
            image: '/accessibility/chart.svg',
            background:'bg-slate200'
        },
    ],

    // Our process Sections
    processDesign: [
        {
            title: "Vision et planification",
            text: "Nous évaluons les actifs numériques, analysant le contenu, la voix de la marque et l'infrastructure pour assurer l'alignement. Nous créons des représentations visuelles, cartographiant les parcours utilisateurs et les fonctions essentielles. Notre objectif est de fournir des solutions ingénieuses pour le succès numérique.",
            image: '/images/process_vision_and_planning.png',
        },
        {
            title: "Conception et développement",
            text: "Nous élaborons une présence visuelle distinctive qui représente authentiquement le caractère de votre marque et établit une connexion avec votre public cible. Nous construisons des sites web qui privilégient une navigation intuitive, s'adaptent parfaitement à tous les appareils et intègrent des principes d'optimisation pour les moteurs de recherche. Notre processus de développement met l'accent sur les normes d'accessibilité et un savoir-faire exceptionnel à chaque étape.",
            image: '/images/process_design_and_development.png',
        },
        {
            title: "Maintenance et évolution",
            text: "Nous assurons une surveillance technique continue et des mises à jour pour maintenir la sécurité et les fonctionnalités actuelles de votre site. Notre équipe suit les indicateurs de performance du site, interprète les données utilisateurs et met en œuvre des améliorations fondées sur des preuves qui augmentent la satisfaction des visiteurs et multiplient les actions significatives. Nous guidons votre approche numérique par une adaptation réfléchie à mesure que les normes de l'industrie et les attentes des utilisateurs évoluent.",
            image: '/images/process_maintenance.png',
        }
    ],

}
