export const commerce = {
    "title": "Commerce électronique",
    "portfolio": "Quelques-unes de nos réalisations",
    hero:{
        "title": "Là où la vision<br>",
        "span": "rencontre le revenu",
        "text": "Nous concevons des plateformes de commerce numérique qui allient innovation et fiabilité, transformant les visiteurs en acheteurs grâce à un design intelligent et une optimisation stratégique.",
    },
    keypoints:{
        "title": "Le <span>commerce numérique</span> exige plus que des templates",
        "span":"",
        "text": "Que vous vous développiez avec Shopify Plus, que vous exploitiez la flexibilité de Drupal Commerce ou que vous utilisiez les capacités d'entreprise d'Optimizely, le commerce électronique moderne nécessite des solutions qui s'adaptent aux comportements changeants des consommateurs.",
    },
    banner:{
        "title": "Une solution commerce qui ",
        "span": "captive l'imagination",
        "title2": "",
        "text": "<strong>De l'évolutivité de Shopify Plus aux fonctionnalités d'entreprise d'Optimizely, nous sélectionnons et implémentons des plateformes qui s'alignent à votre trajectoire.</strong><br><br>Nos solutions fusionnent l'intuition humaine avec l'intelligence artificielle, créant des expériences qui convertissent les transactions en relations.",
    },
    carousel:{
        "title": "Des solutions de <span>commerce électronique</span> qui découplent la croissance",
        "span": "",
        "text": "En exploitant des plateformes allant de Shopify Plus à Drupal Commerce, nous fournissons des solutions complètes qui combinent une fiabilité de niveau entreprise avec une intelligence de pointe.",
    },
    form:{
        "title": "Élevez votre <br>",
        "span": "commerce numérique",
        "title2": "",
        "text": "Prêt à transformer votre vision de vente au détail en excellence numérique? Construisons ensemble un écosystème de commerce électronique qui favorise une croissance durable.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "Élargissez vos possibilités grâce au ",
        "span": "commerce électronique",
        "text": "Bâtissons une stratégie prévisible qui motive votre audience à acheter vos produits.",
        "button": "Discutons-en",
    }
}