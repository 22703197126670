export const newCraft = {
    "title": "Craft CMS development",
    "portfolio": "Some of our work",
    hero:{
        "title": "Flexible, intuitive, and<br>",
        "span": "built for performance",
        "text": "We leverage Craft CMS, a powerful, secure, and developer-friendly content management system, to create custom digital experiences that are as intuitive as they are scalable.",
    },
    keypoints:{
        "title": "A CMS that adapts to you — ",
        "span":"not the other way around.",
        "text": "Content management shouldn’t be frustrating or limiting. Craft CMS combines flexibility with an intuitive user experience, making it an ideal choice for businesses that need a powerful, yet user-friendly alternative to WordPress.",
    },
    banner:{
        "title": "Where vision meets ",
        "span": "excellence",
        "title2": "",
        "text": "<strong>Craft CMS isn’t just another content management system — it’s a content-first, design-flexible, and developer-friendly platform built for businesses that need precision, performance, and scalability.</strong><br><br>We ensure that every Craft CMS project is tailored to your unique requirements, combining intuitive content management with cutting-edge development practices.",
    },
    carousel:{
        "title": "Enterprise <span>Craft CMS</span> solutions ",
        "span": "",
        "text": "We provide full-scale Craft CMS development services, from setup to advanced integrations, ensuring your digital platform is powerful, efficient, and scalable.",
    },
    form:{
        "title": "Build your<br>website with<br>",
        "span": "Craft CMS",
        "title2": "",
        "text": "Looking for a flexible, scalable CMS that puts content creators and developers in control?<br><br><b>We’re here to help.</b>",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "Unleash your ",
        "span": "digital potential",
        "text": "Let’s build a content-first, high-performance digital experience tailored to your business reality.",
        "button": "Let's talk",
    }
}