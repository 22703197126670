export const newDesign = {
    "title": "Design & créatif",
    "portfolio": "Quelques-unes de nos réalisations",
    hero:{
        "title": "Création de visuels<br>qui ",
        "span": "captivent et convertissent",
        "text": "Nous concevons des visuels convaincants qui ne sont pas seulement beaux, mais efficaces. Ancrée dans une compréhension stratégique et la psychologie comportementale, notre approche créative améliore l'identité de marque et l'engagement des utilisateurs.",
    },
    keypoints:{
        "title": "Quand un beau design<br>",
        "span":"ne suffit pas",
        "text": "Une esthétique frappante n'est que le début. Pour vraiment résonner, votre marque a besoin de visuels qui communiquent, persuadent et incitent à l'action.",
    },
    banner:{
        "title": "Un <span>design stratégique</span> au service de votre histoire",
        "span": "",
        "title2": "",
        "text": "<strong>Nous ne nous contentons pas de créer, nous élaborons des expériences qui façonnent la perception et stimulent l'engagement.</strong><br><br> Qu'il s'agisse d'une identité de marque complète, d'une interface web centrée sur l'utilisateur ou d'éléments marketing à fort impact, nous combinons créativité et données pour concevoir des solutions qui donnent des résultats.",
    },
    carousel:{
        "title": "<span>Services de design</span> adaptés à votre marque",
        "span": "",
        "text": "Des expériences numériques aux supports imprimés, nous créons des visuels qui valorisent votre marque et établissent des connexions significatives avec votre public.",
    },

    form:{
        "title": "Donnons vie<br>",
        "span": "à votre vision",
        "title2": "",
        "text": "<strong>À la recherche d'un partenaire créatif?</strong><br><br>Notre équipe est prête à vous aider à façonner le prochain grand moment de votre marque.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "Donnons vie à",
        "span": "votre vision",
        "text": "Notre équipe créative est prête à concevoir pour vous des visuels qui captivent et convertissent.",
    },
}