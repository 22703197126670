export const accessibility = {
    "title": "Accessibility",
    "portfolio": "Some of our work",
    hero:{
        "title": "Inclusive, Compliant, and User-Centric ",
        "span": "Digital Experiences",
        "text": "Accessibility isn’t just a legal requirement—it’s about creating a digital world that welcomes everyone. We design and optimize websites to meet WCAG 2.1 standards, ensuring usability for all, regardless of ability.",
    },
    keypoints:{
        "title": "Digital Accessibility Is Essential, ",
        "span":"Not Optional",
        "text": "A truly effective website must be usable by everyone, including individuals with visual, auditory, motor, and cognitive impairments. WCAG 2.1 compliance ensures your platform is accessible, legally compliant, and optimized for a broader audience.",
    },
    banner:{
        "title": "Accessibility That Enhances ",
        "span": "User Experience",
        "title2": "",
        "text": "<strong>An accessible website isn’t just about compliance—it’s about inclusivity, usability, and engagement.</strong><br><br>By meeting WCAG 2.1 standards, we ensure your website is functional, navigable, and welcoming to all users.",
    },
    carousel:{
        "title": "Comprehensive<br>",
        "span": "Accessibility Services",
        "text": "We provide tailored accessibility solutions that improve usability, ensure compliance, and create a more inclusive digital experience.",
    },
    form:{
        "title": "Make Your Website ",
        "span": "Accessible to Everyone",
        "title2": "",
        "text": "Let’s improve usability, expand your audience, and ensure compliance with WCAG 2.1 standards.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "Accessibility",
        "span": "empowers everyone.",
        "text": "Partner with us to create digital experiences that welcome and serve all users.",
        "button": "Let's talk",
    }
}