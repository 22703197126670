export const newCustomWeb = {
    "title": "Custom web development",
    "portfolio": "Some of our work",
    hero:{
        "title": "Build ",
        "span": "without boundaries",
        "text": "We build tailored digital experiences that surpass the limitations of off-the-shelf solutions, ensuring your web presence aligns perfectly with your brand and business objectives.",
    },
    keypoints:{
        "title": "When off-the-shelf<br>solutions ",
        "span":"fall short",
        "text": "Your business is unique—so why settle for a one-size-fits-all solution that limits your potential?",
    },
    banner:{
        "title": "Technology ",
        "span": "that transforms",
        "title2": "",
        "text": "<strong>We craft digital experiences that not only align with your brand’s identity but also provide an intuitive and efficient user experience.</strong><br><br>Combining strategy, creativity, and technical excellence, we build solutions that drive engagement and streamline operations.",
    },
    carousel:{
        "title": "Enterprise-grade ",
        "span": "development solutions",
        "text": "Leverage cutting-edge technology and proven expertise to build robust, secure, and scalable web platforms that drive your business forward.",
    },
    form:{
        "title": "Engineering",
        "span": "<br>excellence",
        "title2": "",
        "text": "<strong>Tell us about your project, and we’ll create a custom solution that aligns with <u>your</u> reality.</strong><br><br>Our team of senior developers is ready to bring your vision to life. Let’s build something extraordinary together.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "Let’s bring your",
        "span": "vision to life",
        "text": "Your business deserves a web solution that works for you. We’re here to create a platform that fits your needs today and grows with you tomorrow.",
        "button": "Let's talk",
    }
}