export const accessibility = {
    "title": "Accessibilité",
    "portfolio": "Quelques-unes de nos réalisations",
    hero:{
        "title": "Inclusives, conformes et <span>centrées sur l'utilisateur</span>",
        "span": "",
        "text": "L'accessibilité n'est pas seulement une exigence légale, il s'agit de créer un monde numérique qui accueille tout le monde. Nous concevons et optimisons des sites web pour répondre aux normes WCAG 2.1, garantissant une utilisation pour tous, quelle que soit leur capacité.",
    },
    keypoints:{
        "title": "L'accessibilité numérique est essentielle, ",
        "span":"pas optionnelle",
        "text": "Un site web vraiment efficace doit être utilisable par tous, y compris les personnes ayant des déficiences visuelles, auditives, motrices et cognitives. La conformité WCAG 2.1 garantit que votre plateforme est accessible, légalement conforme et optimisée pour un public plus large.",
    },
    banner:{
        "title": "Offrez une <span>expérience utilisateur universelle</span>",
        "span": "",
        "title2": "",
        "text": "<strong>Un site web accessible ne concerne pas seulement la conformité—il s'agit d'inclusivité, d'utilisabilité et d'engagement.</strong><br><br>En respectant les normes WCAG 2.1, nous garantissons que votre site web est fonctionnel, navigable et accueillant pour tous les utilisateurs.",
    },
    carousel:{
        "title": "Services d'accessibilité<br>",
        "span": "complets",
        "text": "Nous fournissons des solutions d'accessibilité sur mesure qui améliorent l'utilisabilité, assurent la conformité et créent une expérience numérique plus inclusive.",
    },
    form:{
        "title": "Rendez votre site web ",
        "span": "accessible à tous",
        "title2": "",
        "text": "Améliorons l'utilisabilité, élargissons votre audience et assurons la conformité aux normes WCAG 2.1.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "Accessibilité",
        "span": "donne du pouvoir à tous.",
        "text": "Collaborez avec nous pour créer des expériences numériques qui accueillent et servent tous les utilisateurs.",
        "button": "Discutons",
    }
}