export const newDrupalWeb = {
    "title": "Développement Drupal",
    "portfolio": "Quelques-unes de nos réalisations",
    hero:{
        "title": "<span>Solutions Drupal</span> puissantes, évolutives et sécurisées.",
        "span": "",
        "text": "Nous exploitons la puissance de Drupal, un framework de niveau entreprise conçu pour les entreprises qui exigent flexibilité, sécurité et performance.",
    },
    keypoints:{
        "title": "Quand votre <span>écosystème numérique</span> exige davantage.",
        "span":"",
        "text": "Les solutions prêtes à l'emploi ne peuvent pas toujours suivre la complexité des entreprises en croissance. Drupal offre une flexibilité inégalée, mais sa mise en œuvre efficace nécessite une expertise.",
    },
    banner:{
        "title": "La <span>puissance de Drupal</span>, personnalisée pour vous",
        "span": "",
        "title2": "",
        "text": "<strong>Drupal est plus qu'un CMS—c'est un cadre numérique évolutif qui prend en charge des flux de travail complexes, la gestion multi-sites et une sécurité de niveau entreprise.</strong><br><br>Notre expertise garantit que votre environnement Drupal est optimisé, intuitif et construit pour durer.",
    },
    carousel:{
        "title": "Services de <span>développement Drupal</span> complets",
        "span": "",
        "text": "Nous fournissons des solutions Drupal de bout en bout, du développement à l'optimisation, garantissant que votre plateforme est performante et prête pour l'avenir.",
    },
    form:{
        "title": "Construisons votre<br>",
        "span": "solution Drupal",
        "title2": "",
        "text": "Que vous ayez besoin de développement personnalisé, d'optimisation ou d'une transformation Drupal à grande échelle, notre équipe est prête à vous aider.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "Parlons",
        "span": "Drupal",
        "text": "Nous sommes là pour vous aider à construire, optimiser et faire évoluer votre expérience numérique propulsée par Drupal.",
        "button": "Discutons-en",
    }
}