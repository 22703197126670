export const newCustom = {
    "title": "Interfaces d'administration",
    "portfolio": "Quelques-unes de nos réalisations",
    hero:{
        "title": "Précises et intuitives pour un ",
        "span": "impact maximum",
        "text": "Nous concevons des interfaces personnalisées et des solutions intranet qui favorisent la collaboration, optimisent les processus de travail et offrent une expérience numérique fluide adaptée aux besoins de votre organisation.",
    },
    keypoints:{
        "title": "La technologie devrait<br>simplifier, ",
        "span":"pas compliquer.",
        "text": "Une interface bien conçue devrait donner du pouvoir à vos équipes, rationaliser la communication et améliorer la productivité—mais y parvenir nécessite une conception réfléchie et une expertise technique.",
    },
    banner:{
        "title": "Une interface adaptée et ",
        "span": "conçue pour vos besoins",
        "title2": "",
        "text": "<strong>Chaque organisation a des procéssus et des exigences uniques.</strong><br><br>Nos interfaces personnalisées sont conçues pour améliorer la collaboration, gérer les ressources efficacement et offrir une expérience utilisateur fluide. Nous adaptons chaque solution pour répondre à vos besoins exacts, en veillant à ce que votre plateforme travaille pour vous, et non contre vous.",
    },
    carousel:{
        "title": "Intelligentes, évolutives et adaptées ",
        "span": "pour la performance",
        "text": "Nous créons des interfaces sur mesure qui comblent le fossé entre les personnes, les processus et la technologie, assurant que votre écosystème numérique fonctionne harmonieusement.",
    },
    form:{
        "title": "Transformez vos opérations via un portail qui ",
        "span": "travail pour vous",
        "title2": "",
        "text": "Parlez-nous de votre projet, et nous élaborerons une solution qui améliore la productivité, la sécurité et la collaboration au sein de votre organisation.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "Une solution qui vous éleve,",
        "span": "et non l'inverse",
        "text": "Créons ensemble une interface personnalisée, conviviale et sécurisée qui répond aux demandes de votre organisation.",
        "button": "Discutons-en",
    }
}