export const audits = {
    "title": "Technical Audits",
    "portfolio": "Some of our work",
    hero:{
        "title": "Insights that support actionable ",
        "span": "improvements",
        "text": "We conduct in-depth research and audits to evaluate what works, identify missed opportunities, and optimize digital strategies for long-term success.",
    },
    keypoints:{
        "title": "Know where you stand — ",
        "span":"and how to improve",
        "text": "Understanding your strengths and weaknesses is the key to growth. Our research and auditing services provide a clear picture of your performance, helping you refine strategies, fix inefficiencies, and leverage untapped opportunities.",
    },
    banner:{
        "title": "A data-driven approach to ",
        "span": "digital success",
        "title2": "",
        "text": "<strong>A strong digital presence isn’t built on assumptions.</strong><br><br>Through thorough research and auditing, we determine what’s effective, what’s holding you back, and what needs optimization—helping you build a smarter, more efficient strategy.",
    },
    carousel:{
        "title": "Research & audit services for ",
        "span": "measurable growth",
        "text": "We provide strategic research and audits that uncover insights, correct inefficiencies, and drive informed decision-making.",
    },
    form:{
        "title": "Auditing leads ",
        "span": "to better decisions",
        "title2": "",
        "text": "Tell us about your challenges, and we’ll provide the insights and recommendations to help you refine and optimize your strategy.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "Optimize, improve, and",
        "span": "stay ahead",
        "text": "A research-backed strategy leads to better business decisions. Let’s audit and refine your digital approach for maximum impact.",
        "button": "Let's talk",
    }
}