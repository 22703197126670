export const newAutomation = {
    "title": "Automatisation",
    "portfolio": "Quelques-uns de nos travaux",
    hero:{
        "title": "Déléguez le travail à<br>",
        "span": "vos systèmes.",
        "text": "Nous aidons les entreprises à automatiser les tâches répétitives, à simplifier les processus de travail et à optimiser les opérations, permettant aux équipes de se concentrer sur ce qui compte vraiment—l'innovation et la croissance.",
    },
    keypoints:{
        "title": "Éliminez les inefficacités et ",
        "span":"maximisez la productivité.",
        "text": "Les processus manuels chronophages ralentissent les équipes, augmentent les erreurs et limitent l'évolutivité. L'automatisation permet à votre entreprise de travailler plus rapidement, plus intelligemment et plus efficacement, sans sacrifier la qualité.",
    },
    banner:{
        "title": "Solutions automatisées pour ",
        "span": "entreprises<br>avant-gardistes",
        "title2": "",
        "text": "<strong>L'automatisation ne consiste pas seulement à gagner du temps—il s'agit de libérer du potentiel.</strong><br><br> Nous mettons en œuvre des solutions sur mesure qui améliorent la productivité, rationalisent les opérations et génèrent des résultats commerciaux mesurables. Qu'il s'agisse d'automatiser le service client, les rapports financiers ou la gestion des stocks, nous vous aidons à garder une longueur d'avance.",
    },
    carousel:{
        "title": "Des solutions intelligentes et évolutives pour ",
        "span": "chaque industrie",
        "text": "Nous développons des solutions d'automatisation personnalisées qui s'intègrent parfaitement à votre entreprise, optimisant l'efficacité dans tous les départements.",
    },
    form:{
        "title": "Le temps est votre ressource la plus précieuse, ",
        "span": "utilisez-le judicieusement.",
        "title2": "",
        "text": "Parlez-nous de vos processus, et nous concevrons une solution d'automatisation personnalisée qui augmente l'efficacité, réduit les coûts et stimule la croissance.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "L'automatisation, un synonyme de ",
        "span": "transformation.",
        "text": "Construisons ensemble des solutions d'automatisation intelligentes et évolutives qui éliminent les goulots d'étranglement et stimulent la croissance.",
        "button": "Discutons-en",
    }
}