export const newMobile = {
    "title": "Mobile applications",
    "portfolio": "Some of our work",
    hero:{
        "title": "Transform your vision into a seamless ",
        "span": "mobile experience",
        "text": "We design and develop high-performance mobile applications that connect with users, drive engagement, and align with your business objectives.",
    },
    keypoints:{
        "title": "Your audience is mobile - ",
        "span":"your business should be too.",
        "text": "In a digital-first world, mobile applications are no longer an option—they’re essential. But a great app isn’t just about functionality—it’s about creating an engaging, intuitive experience that keeps users coming back.",
    },
    banner:{
        "title": "An app that<br>",
        "span": "makes an impact",
        "title2": "",
        "text": "<strong>A mobile application should be more than just functional—it should create lasting value.</strong><br><br>Whether you need a customer-facing app, an internal business tool, or a revenue-driving platform, we develop solutions that blend performance, usability, and innovation.",
    },
    carousel:{
        "title": "Custom solutions for<br>",
        "span": "iOS, Android & beyond",
        "text": "We develop feature-rich, scalable, and high-performance mobile applications tailored to your specific business requirements.",
    },
    form:{
        "title": "Let’s build your<br>",
        "span": "mobile audience",
        "title2": "",
        "text": "<strong>Your customers expect fast, seamless, and engaging mobile experiences.</strong><br><br>Our team is here to help you design and develop an app that connects with users and drives real results.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "The right mobile experience fuels",
        "span": "engagement and growth.",
        "text": "Let’s build an app that delivers value, drives results, and evolves with your business.",
        "button": "Let's talk",
    }
}