export const maintenance = {
    "title": "Maintenance",
    "portfolio": "Some of our work",
    hero:{
        "title": "Keep your digital assets ",
        "span": "performing at peak efficiency",
        "text": "We offer a comprehensive range of maintenance packages that ensure the security, speed, and continuous optimization of your digital assets.",
    },
    keypoints:{
        "title": "Protection through ",
        "span":"scheduled and proactive maintenance",
        "text": "Digital excellence requires vigilant upkeep. Regular maintenance isn't just about fixing issues—it's about preventing them before they impact your business.",
    },
    banner:{
        "title": "Beyond basic<br>",
        "span": "maintenance",
        "title2": "",
        "text": "<strong>Digital maintenance is an investment in business continuity.</strong><br><br>Our comprehensive approach combines proactive updates, performance optimization, and strategic improvements to ensure your platform not only functions flawlessly but evolves with your needs. From critical security patches to speed enhancements, we maintain your digital presence with precision and foresight.",
    },
    carousel:{
        "title": "Comprehensive<br>",
        "span": "digital care",
        "text": "We deliver ongoing maintenance services that protect your investment and enhance your platform's performance over time.",
    },
    form:{
        "title": "Maintain peak ",
        "span": "digital performance.",
        "title2": "",
        "text": "Let's ensure your platform remains secure, efficient, and optimized for success. Partner with us for proactive maintenance that protects your digital investment.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "Give yourself peace of mind through ",
        "span": "consistent maintenance",
        "text": "Transform your website maintenance from reactive repairs to proactive optimization.",
        "button": "Let's talk",
    }
}