export const industries = {
    "governments":"Government",
    "healthcares":"Healthcare",
    "finances":"Finance",
    "medias":"Media",
    "defences":"Military",
    "title": "expertise",
    "titleSpan": "Industry-Specific ",
    "titleText": "We craft custom digital solutions based on our extensive experience across multiple industries. By understanding each sector's unique technical needs, regulatory requirements, and operational constraints, we deliver practical solutions that align perfectly with user expectations and industry standards.",
    government:{
        hero:{
            "type": "Public Administration",
            "title": "Modernize public service delivery through ",
            "span": "pragmatic digital reform",
            "subtitle": "Bridging constituent needs with administrative capabilities",
            "text": "Public agencies operate with outdated systems that create procedural bottlenecks and strain taxpayer-funded resources. Citizens increasingly expect government services to match private sector digital standards, creating implementation pressure despite strict fiscal constraints.",
        },
        block1:{
            "title": "Accountable Technology Investment",
            "text": "Public sector organizations face intense oversight regarding technology expenditures. We develop solutions with documentable public benefit, extend existing infrastructure lifespans, and build sustainable systems that function effectively across administrative transitions.",
        },
        block2:{
            "title": "Constituent-Accessible Services",
            "text": "Procedural complexity and disconnected systems frustrate citizens while increasing administrative overhead. Our platforms simplify public interactions, reduce paperwork burdens, and create logical service pathways, improving constituent satisfaction while decreasing staff workload.",
        },
        block3:{
            "title": "Information-Based Governance",
            "text": "Agencies collect extensive data but struggle to transform it into actionable information. We construct analytical frameworks that organize disparate facts into coherent insights, supporting evidence-based policy decisions that improve resource allocation and service effectiveness.",
        },
    },
    healthcare:{
        hero:{
            "type": "healthcare sector",
            "title": "Enhance clinical outcomes through ",
            "span": "supportive digital systems",
            "subtitle": "Technology that augments medical expertise rather than replacing human judgment",
            "text": "Clinical providers face documentation burdens that reduce direct patient contact time. Properly implemented digital tools streamline administrative functions while supporting practitioners, improving both treatment effectiveness and operational efficiency.",
        },
        block1:{
            "title": "Clinical Documentation Efficiency",
            "text": "Medical professionals divert substantial time from patient care to record-keeping. We create intuitive systems that minimize administrative documentation, improve information capture, and ensure regulatory compliance without compromising the provider-patient relationship.",
        },
        block2:{
            "title": "Medical Information Continuity",
            "text": "Isolated platforms create dangerous knowledge gaps and redundant processes. Our integration methods connect disparate clinical systems, ensuring patient information transfers securely between departments and providers without duplicate data entry or communication failures.",
        },
        block3:{
            "title": "Patient Health Management",
            "text": "Patients need consistent access to their medical information. We develop accessible health portals that improve treatment adherence, reduce unnecessary clinical visits, and strengthen provider relationships through transparent information and appropriate self-service options.",
        },
    },
    finance:{
        hero:{
            "type": "financial institutions",
            "title": "Develop financial interactions that reinforce trust and ",
            "span": "maintain institutional stability",
            "subtitle": "Security foundations with service excellence for competitive differentiation",
            "text": "Financial institutions balance rigorous security requirements against evolving client expectations. Legacy infrastructure constrains operational responsiveness, creating vulnerabilities to more adaptable competitors who gain market position through streamlined, intuitive financial services that clients now consider standard.",
        },
        block1:{
            "title": "Low-Friction Security Protocols",
            "text": "Security measures frequently introduce client frustration. We design authentication and transaction processes that maintain robust protection while eliminating unnecessary complications, balancing regulatory compliance with practical usability.",
        },
        block2:{
            "title": "Core Banking System Adaptation",
            "text": "Fundamental financial infrastructure resists replacement. We create contemporary client interfaces that integrate with established backend systems, extending institutional technology investments while delivering modern experiences that meet current expectations.",
        },
        block3:{
            "title": "Client Experience Distinction",
            "text": "Financial institutions increasingly compete on service quality metrics. Our specialized solutions emphasize your institutional strengths and address specific market segment requirements, creating distinctive service interactions that standardized platforms cannot replicate.",
        },
    },
    media:{
        hero:{
            "type": "media and communications",
            "title": "Translate editorial content into ",
            "span": "measurable business outcomes",
            "subtitle": "Sustainable revenue approaches for digital publishers",
            "text": "Publishing organizations face formidable challenges in audience development and revenue generation as traditional models deteriorate. Technical quality creates competitive differentiation through superior audience experience, content engagement, and diversified monetization strategies.",
        },
        block1:{
            "title": "Audience Retention Improvement",
            "text": "Content abandonment and minimal engagement undermine editorial value. We develop performance-optimized platforms with contextual discovery features, strengthening key audience metrics that support advertising yield and subscription conversion.",
        },
        block2:{
            "title": "Revenue Channel Diversification",
            "text": "Traditional advertising no longer sustains quality content production. Our integrated systems for subscription management, micropayments, and membership programs establish varied revenue sources beyond increasingly competitive advertising markets.",
        },
        block3:{
            "title": "Editorial Workflow Efficiency",
            "text": "Resource limitations constrain content production capacity. We implement streamlined publishing processes and management tools that reduce technical complications, allowing editorial staff to focus on creating substantive content rather than managing production logistics.",
        },
    },
    military:{
        hero:{
            "type": "armed forces",
            "title1": "Deploy ",
            "title2": "information systems with ",
            "span1": "mission-essential ",
            "span2": "operational reliability",
            "subtitle": "Security by design, consistent performance under adverse conditions",
            "text": "Defense organizations operate where system failure compromises mission objectives. Information infrastructure must maintain functionality against sophisticated adversarial threats while supporting critical operations across contested and degraded environments.",
        },
        block1:{
            "title": "Operational Security Infrastructure",
            "text": "Defense applications confront persistent, advanced threats beyond commercial security solutions. We implement specialized security frameworks that protect classified information through defense-in-depth strategies specifically engineered for elevated threat environments.",
        },
        block2:{
            "title": "Communications Resilience",
            "text": "Military operations routinely occur in compromised network environments. Our solutions ensure critical functionality persists regardless of connection quality, with graceful capability reduction rather than complete failure when networks face disruption.",
        },
        block3:{
            "title": "Command Decision Support",
            "text": "Effective leadership requires timely access to mission-critical information. We develop specialized information structures enabling commanders to rapidly access relevant intelligence across security domains while maintaining appropriate information controls and data integrity.",
        },
    },
}