export const newDrupalWeb = {
    "title": "Drupal development",
    "portfolio": "Some of our work",
    hero:{
        "title": "Powerful, scalable, and secure ",
        "span": "Drupal solutions",
        "text": "We harness the power of Drupal, an enterprise-level framework designed for businesses that demand flexibility, security, and performance.",
    },
    keypoints:{
        "title": "When your digital ecosystem ",
        "span":"demands more.",
        "text": "Off-the-shelf solutions can’t always keep up with the complexity of growing businesses. Drupal offers unmatched flexibility,but implementing it effectively requires expertise.",
    },
    banner:{
        "title": "The <span>power of Drupal</span>, customized for you",
        "span": "",
        "title2": "",
        "text": "<strong>Drupal is more than a CMS—it’s a scalable digital framework that supports complex workflows, multi-site management, and enterprise-grade security.</strong><br><br>Our expertise ensures your Drupal environment is optimized, intuitive, and built to last.",
    },
    carousel:{
        "title": "Comprehensive <span>Drupal development</span> services",
        "span": "",
        "text": "We provide end-to-end Drupal solutions, from development to optimization, ensuring your platform is high-performing and future-ready.",
    },
    form:{
        "title": "Let’s build your<br>",
        "span": "Drupal solution",
        "title2": "",
        "text": "Whether you need custom development, optimization, or a full-scale Drupal transformation, our team is ready to help.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "Let’s talk",
        "span": "Drupal",
        "text": "We’re here to help you build, optimize, and scale your Drupal-powered digital experience.",
        "button": "Let's talk",
    }
}