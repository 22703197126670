export const research = {
    "title": "Recherche et consultation",
    "portfolio": "Quelques-uns de nos travaux",
    hero:{
        "title": "Décisions fondées sur la recherche, ",
        "span": "stratégies appuyées par les données",
        "text": "Nous fournissons des recherches approfondies et des consultations d'experts pour aider les entreprises à prendre des décisions éclairées, à surpasser leurs concurrents et à développer des solutions qui s'alignent avec leurs objectifs.",
    },
    keypoints:{
        "title": "Sans recherche, <span>la stratégie</span> n'est que supposition",
        "span":"",
        "text": "Une stratégie solide commence par des données. Qu'il s'agisse de comprendre votre paysage concurrentiel, d'identifier des mots-clés à fort impact ou de concevoir des solutions qui résolvent de véritables défis commerciaux, la recherche est le fondement du succès.",
    },
    banner:{
        "title": "Guidez vos décisions par des ",
        "span": "stratégies plus intelligentes",
        "title2": "",
        "text": "<strong>Le succès ne consiste pas à suivre les tendances, mais à comprendre ce qui fonctionne.</strong><br><br>Grâce à la recherche concurrentielle, l'analyse SEO et la conception de solutions, nous fournissons les informations dont vous avez besoin pour élaborer des stratégies gagnantes.",
    },
    carousel:{
        "title": "Recherche ciblée et ",
        "span": "consultation stratégique",
        "text": "Nous offrons des services de recherche et de consultation sur mesure qui permettent aux entreprises de disposer des bonnes données et de perspectives exploitables.",
    },
    form:{
        "title": "Une recherche qui conduit à des ",
        "span": "actions mesurables",
        "title2": "",
        "text": "Parlez-nous de vos défis, et nous vous fournirons la recherche et les perspectives stratégiques dont vous avez besoin pour avancer en toute confiance.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "De connaissance à ",
        "span": "avantages",
        "text": "Confiez-nous l'élaboration de votre stratégie, fondée sur une recherche approfondie et des méthodologies éprouvées.",
        "button": "Discutons-en",
    }
}