export const security = {
    "title": "Security",
    "portfolio": "Some of our work",
    hero:{
        "title": "<span>Security services</span> to protect and strengthen your ",
        "span": "digital assets",
        "text": "Your digital assets hold immense value—protecting them isn’t just a precaution, it’s a necessity. We implement robust security frameworks that safeguard data, ensure compliance, and fortify your business against evolving threats.",
    },
    keypoints:{
        "title": "Cybersecurity isn’t an option — ",
        "span":"It’s a business imperative",
        "text": "With increasing cyber threats and stricter regulatory requirements, businesses must adopt proactive security measures to prevent breaches, ensure compliance, and protect customer trust. Our security services provide the highest level of protection, meeting industry standards such as SOC 2, ISO 27001, PCI DSS, and PIPEDA.",
    },
    banner:{
        "title": "Compliance-driven<br>",
        "span": "security solutions",
        "title2": "",
        "text": "<strong>Security is more than just firewalls and encryption—it’s about compliance, risk management, and long-term resilience.</strong><br><br>Our approach ensures your business meets the highest security standards while staying agile and prepared for future threats.",
    },
    carousel:{
        "title": "Comprehensive security ",
        "span": "& compliance services",
        "text": "We deliver end-to-end security solutions that protect your systems, ensure regulatory compliance, and strengthen your overall security posture.",
    },
    form:{
        "title": "<span>Security services</span><br>you can trust",
        "span": "",
        "title2": "",
        "text": "Tell us about your security challenges, and we’ll build a compliance-driven, proactive security strategy tailored to your business needs.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "Protect Your Data,",
        "span": "Secure Your Future",
        "text": "Contact us to implement comprehensive protections that safeguards your assets and reputation.",
        "button": "Let's talk",
    }
}