export const newCloud = {
    "title": "Cloud infrastructure (CI/CD)",
    "portfolio": "Some of our work",
    hero:{
        "title": "Discover the power of<br>",
        "span": "Cloud architecture",
        "text": "We design and implement high-performance cloud infrastructure and CI/CD pipelines that enhance agility, security, and scalability—empowering your business to innovate faster.",
    },
    keypoints:{
        "title": "Unlock the full potential of ",
        "span":"cloud computing",
        "text": "Cloud technologies provide on-demand scalability, cost efficiency, and enhanced security—but managing cloud infrastructureeffectively requires deep expertise and a strategic approach. ",
    },
    banner:{
        "title": "Future-proof Cloud solutions for<br>",
        "span": "modern businesses",
        "title2": "",
        "text": "<strong>Cloud computing isn’t just about migrating infrastructure—it’s about building a foundation for innovation.</strong><br><br> We help businesses deploy, optimize, and automate cloud environments that improve performance, reduce costs, and enhance operational efficiency.",
    },
    carousel:{
        "title": "End-to-end cloud solutions for scalable, secure, and ",
        "span": "automated workflows",
        "text": "We provide custom cloud and DevOps solutions that streamline deployment, enhance security, and ensure peak performance.",
        "slide1Title": "",
        "slide1Text": "",
        "slide2Title": "",
        "slide2Text": "",
        "slide3Title": "",
        "slide3Text": "",
        "slide4Title": "",
        "slide4Text": "",
    },
    form:{
        "title": "Turn vision into ",
        "span": "reality",
        "title2": "",
        "text": "Tell us about your challenges, and we’ll help your organization implement policies to secure and scale your infrastructure.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "The right cloud infrastructure drives",
        "span": "agility, security, and innovation.",
        "text": "Let’s build a scalable, automated, and secure cloud environment to support your business growth.",
        "button": "Let's talk",
    }
}