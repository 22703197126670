export const contentStrategy = {
    "title": "Stratégie de contenu",
    "portfolio": "Quelques-unes de nos réalisations",
    hero:{
        "title": "Créez du contenu qui résonne et ",
        "span": "génère des résultats.",
        "text": "Nous développons des stratégies de contenu complètes qui allient une narration convaincante à des analyses basées sur les données, garantissant que votre message atteint et engage votre public cible sur tous les canaux.",
    },
    keypoints:{
        "title": "<span>Le contenu est votre atout</span>, le pilier sur lequel bâtir votre réussite",
        "span":"",
        "text": "Dans notre ère digitale, un contenu stratégique façonne la perception de la marque, stimule l'engagement et alimente la croissance. Le succès exige plus qu'un simple texte bien rédigé — il nécessite une approche calculée qui s'aligne sur les objectifs commerciaux et résonne à travers les cultures.",
    },
    banner:{
        "title": "Contenu stratégique qui ",
        "span": "connecte avec les émotions",
        "title2": "",
        "text": "<strong>La stratégie de contenu transcende la rédaction traditionnelle.</strong><br><br>Notre approche globale combine expertise créative et précision analytique pour élaborer un contenu qui captive les audiences, se classe dans les résultats de recherche et performe sur les marchés mondiaux.",
    },
    carousel:{
        "title": "Solutions de contenu aux ",
        "span": "résultats mesurables",
        "text": "Nous créons et exécutons des stratégies de contenu qui s'alignent sur vos objectifs d'affaires, garantissant que chaque élément de contenu serve un objectif stratégique.",
    },
    form:{
        "title": "Transformez votre contenu en ",
        "span": "un puissant atout commercial.",
        "title2": "",
        "text": "Partagez vos défis en matière de contenu, et nous développerons une approche stratégique qui élève la voix de votre marque, améliore votre présence numérique et stimule un engagement significatif sur tous les canaux.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "Un contenu puissant : ",
        "span": "connexion, conversion, croissance.",
        "text": "Collaborez avec nous pour construire un écosystème de contenu qui engage votre audience et stimule une reconnaissance durable.",
        "button": "Discutons-en",
    }
}