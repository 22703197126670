export const caseStudies = {
  "let_us_show_you": "Let us show you the solutions that",
  "exceeded": "exceeded",
  "our_customers_expectations": "our customers' expectations.",
  "our_work": "Our portfolio",
  "our_work2": "Case Studies",
  "creativity" : "A picture is worth ",
  "in_action": "a thousand words",
  "portfolio": "Our team combines cutting-edge technology with strategic thinking to create websites that aren't just visually stunning, but also powerful business tools.",
  "intranet": "Intranet",
  "e_learning_platform": "A comprehensive e-learning platform designed specifically for the higher education sector.",
  "drupal": "Drupal",
  "e_commerce": "E-commerce",
  "vue_js": "VueJS",
  "aws": "AWS",
  "read_more": "Read more",
  "team_description": "We're a small team of senior developers who knows how to ",
  "listen": "listen",
  "75_automations": "75+ automations",
  "payment_system": "Payment system",
  "fully_automated_system": "A fully automated time and task management system that automatically generates invoices, emails, budgets and more.",
  "react": "React",
  "zoho": "Zoho",
  "simplicity_and_security": "We build complex interfaces with ",
  "simplicity": "simplicity",
  "and": "and",
  "security": "security",
  "in_mind": "in mind.",
  "contest_system": "Contest system",
  "secure_contest_management_system": "A secure contest management system designed to easily manage multiple micro-sites while being capable of handling millions of entries.",
  "laravel": "Laravel",
  "nuxt": "Nuxt",
  "docker": "Docker",
  "challenge": "<span>Understanding</span> the challenge",
  "solution": "Finding the right <span>solution</span>",
  "results": "Delivering <span>outstanding</span> results",

  "finance1": "And",
  "finance2": "innovative",
  "finance3": "solutions for the finance industry",
  "nsi1": "A unique solution which allows Zoho Books users to issue automated payments to suppliers while meeting the requirements of the CPA 005 standard",
  'see_more': 'Load more projects',
  'see_more2': 'See more projects',

  webcampus: {
    "text1": "A comprehensive e-learning platform designed specifically for the higher education sector.",
    "platforms": "Web, desktop & mobile",
    "deliverables": "UI, UX, administration interface & e-commerce",
    "techStack": "Symfony 5, Drupal 9 & AWS",
    "challenge": "A group of university professors reached out to us to develop an e-learning solution for internal department managers and partners from the private sector",
    "challengeImage": "webcampus-tablet",
    "solution": "Our team made the strategic decision to leverage Drupal's powerful features to develop a robust e-learning platform with advanced security measures and e-commerce capabilities.",
    "icon1Text": "Authoritative colour principles",
    "icon2Text": "Clean and intuitive design",
    "icon3Text": "Responsive layout",
    "icon4Text": "Drupal 9 CMS",
    "icon5Text": "AWS Microservices architecture",
    "icon6Text": "User and course security",
    "presentationImage": "webcampus-devices",
    "results": "While the platform remains in its beta stage and has not been made available to the general public, our system currently boasts more than 5000 users from the private sector and offers over 900 hours of high-quality content for the continuous education of managers and university personnel.",
    "result1Text": "monthly users",
    "result2Text": "hours of quality content",
    "result3Text": "monthly growth engagement",
    "result4Text": "in course sales"
  },
  jbconseil: {
    "title": "A fully automated task, time and reporting solution for the accounting industry.",
    "platforms": "Web, desktop & tablets",
    "deliverables": "75+ automations, third-party integrations",
    "techStack": "Drupal 9, React, Zoho Books",
    "understandingTitle": "Understanding the challenge",
    "understandingText": "A company specializing in corporate accounting required an innovative solution to streamline management processes by automating task assignments, time tracking, report generation, and billing workflows.",
    "solutionTitle": "Finding the right solution",
    "solutionText": "Our team opted to leverage the exceptional capabilities of Drupal's Queue Operations API to create more than 75 automated task allocation scenarios to fully automate task and time entries, as well as billing and invoicing processes.",
    "icon1Text": "75+ automated scenarios",
    "icon2Text": "Zoho Books integration",
    "icon3Text": "Responsive layout",
    "icon4Text": "Automated tasks allocation",
    "icon5Text": "Automated time entries",
    "icon6Text": "Automated invoicing",
    "resultsTitle": "Delivering outstanding results",
    "resultsText": "Our innovative solution spared the client thousands of hours annually, enabling them to increase cash flow with reduced management efforts and focus on their core expertise: accounting.",
    "result1": "500k",
    "result1Text": "saved in salaries annually",
    "result2": "2600+",
    "result2Text": "hours saved annually",
    "result3": "175%",
    "result3Text": "ROI",
    "result4": "75+",
    "result4Text": "automations"
  },
  haribo: {
    "title": "An intuitive contest management solution to easily create and manage online promotions",
    "platforms": "Web, desktop & mobile",
    "deliverables": "Contest system, integrated micro-sites",
    "techStack": "Laravel, NuxtJS, Docker",
    "understandingTitle": "Understanding the challenge",
    "understandingText1": "Haribo, the company behind the gummy bears we all know and love, has tasked us to develop a secure contest management platform.<br/><br/>This sophisticated backend solution would ease the process of deploying online contests and promotions, while simultaneously expand their subscriber base for future marketing initiatives.",
    "solutionTitle": "Finding the right solution",
    "solutionText": "Leveraging the robust capabilities of Laravel and NuxtJS, our team developed a cutting-edge web application designed to generate lightning-fast contest micro-sites. With a strong focus on heightened security, the platform comes equipped with all the essential features required to effortlessly launch nationwide promotions at the click of a button.",
    "icon1Text": "Multi-contest system",
    "icon2Text": "Lightning-fast micro-sites",
    "icon3Text": "Responsive layout",
    "icon4Text": "Secure data vault",
    "icon5Text": "Prize management",
    "icon6Text": "Reporting system",
    "resultsTitle": "Delivering outstanding results",
    "resultsText": "The platform has proven to be a resounding success and is now actively utilized to manage contests across the nation, garnering hundreds of thousands of entries per promotional campaign.",
    "result1": "1.5M+",
    "result1Text": "combined contest entries",
    "result2": "500k+",
    "result2Text": "new email subscribers",
    "result3": "200",
    "result3Text": "cool prizes distributed"
  },
  nsi: {
    "title": "An automated payment solution (CPA 005) for Zoho Books users in Canada",
    "customerLogo": "logo-nsi",
    "mainImage": "nsi-main",
    "text1": "A unique solution which allows Canadian Zoho Books users to issue payments to suppliers automatically while meeting the requirements of the CPA 005 standard established by the Canadian Payments Association.",
    "platforms": "Web, Desktop",
    "deliverables": "API, Payment Solution",
    "techStack": "Symfony 5, VueJS and Zoho Books",
    "challenge": "Build a secure ecosystem which is compliant with the CPA 005 standard and allows Zoho Books' customers to export their bill payments and synchronize them with their bank.",
    "challengeImage": "nsi-tablet",
    "solution": "Our team developed the first payment solution for Zoho that is compliant with the Canadian federal standard for electronic payments. Integrated with Zoho Books, the system facilitates transactions by allowing organizations to export and pay supplier invoices in a single operation.",
    "icon1Text": "Two-way sync with Zoho Books",
    "icon2Text": "Simple and intuitive interface",
    "icon3Text": "Secure environment",
    "icon4Text": "Bills payments",
    "icon5Text": "Timesheets synchronization",
    "icon6Text": "Reporting system",
    "presentationImage": "nsi-devices",
    "results": "Since the launch of this platform, more than 5 million transactions have been carried out with authorized Canadian banks. Saving considerable time for service department managers and accounting staff.",
    "result1": "5M+",
    "result1Text": "transactions",
    "result2": "340%",
    "result2Text": "efficiency gain",
    "result3": "100 000$+",
    "result3Text": "annual savings",
    "result4": "400+",
    "result4Text": "happy Zoho Books customers"
  },
}
