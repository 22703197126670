export const newCustomWeb = {
    "title": "Développement web",
    "portfolio": "Quelques-unes de nos réalisations",
    hero:{
        "title": "Développez ",
        "span": "sans limites",
        "text": "Nous créons des expériences numériques sur mesure qui dépassent les limites des solutions communes, assurant ainsi que votre présence web s'aligne parfaitement avec votre marque et vos objectifs commerciaux.",
    },
    keypoints:{
        "title": "Quand les <span>solutions prêtes à l'emploi</span> ne suffisent pas",
        "span":"",
        "text": "Votre entreprise est unique—alors pourquoi vous contenter d'une solution universelle qui limite votre potentiel?",
    },
    banner:{
        "title": "Une plateforme web aussi unique ",
        "span": "que votre entreprise",
        "title2": "",
        "text": "<strong>Nous créons des expériences numériques qui non seulement s'alignent avec l'identité de votre marque, mais offrent également une expérience utilisateur intuitive et efficace.</strong><br><br>En combinant stratégie, créativité et excellence technique, nous construisons des solutions qui stimulent l'engagement et rationalisent les opérations.",
    },
    carousel:{
        "title": "Solutions de <span>développement web</span> pour grandes entreprises",
        "span": "",
        "text": "Leverage cutting-edge technology and proven expertise to build robust, secure, and scalable web platforms that drive your business forward.",
    },
    form:{
        "title": "Prêt pour une <span>solution numérique</span> sur mesure?",
        "span": "",
        "title2": "",
        "text": "<strong>Parlez-nous de votre projet, et nous créerons une solution personnalisée qui répond à <u>votre</u> réalité.</strong><br><br>Notre équipe de développeurs seniors est prête à donner vie à votre vision. Construisons ensemble quelque chose d'extraordinaire.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "Donnons vie à",
        "span": "votre vision",
        "text": "Votre entreprise mérite une solution web qui travaille pour vous. Nous sommes là pour créer une plateforme qui répond à vos besoins aujourd'hui et qui évolue avec vous demain.",
        "button": "Discutons-en",
    }
}