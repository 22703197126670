export const contentStrategy = {
    "title": "Content Strategy",
    "portfolio": "Some of our work",
    hero:{
        "title": "Craft content that resonates<br>",
        "span": "and drives results.",
        "text": "We develop comprehensive content strategies that blend compelling storytelling with data-driven insights, ensuring your message reaches and engages your target audience across all channels.",
    },
    keypoints:{
        "title": "Content isn't just king —it's the ",
        "span":"kingdom's foundation.",
        "text": "In our digital era, strategic content shapes brand perception, drives engagement, and fuels growth. Success demands more than just well-written copy—it requires a calculated approach that aligns with business objectives and resonates across cultures.",
    },
    banner:{
        "title": "Strategic content that ",
        "span": "drives emotion",
        "title2": "",
        "text": "<strong>Content strategy transcends traditional copywriting.</strong><br><br>Our comprehensive approach combines creative expertise with analytical precision to craft content that captivates audiences, ranks in search results, and performs across global markets.",
    },
    carousel:{
        "title": "Content strategies that deliver ",
        "span": "measurable impact",
        "text": "We create and execute content strategies that align with your business objectives, ensuring every piece of content serves a strategic purpose.",
    },
    form:{
        "title": "Transform your content into a<br>",
        "span": "powerful business asset.",
        "title2": "",
        "text": "Share your content challenges, and we'll develop a strategic approach that elevates your brand voice, enhances your digital presence, and drives meaningful engagement across all channels.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "Content that connects, converts,",
        "span": "and scales.",
        "text": "Partner with us to build a content ecosystem that engages your audience and drives sustainable recognition.",
        "button": "Let's talk",
    }
}