export const newCustom = {
    "title": "Admin interfaces",
    "portfolio": "Some of our work",
    hero:{
        "title": "Precision-engineered for ",
        "span": "maximum impact",
        "text": "We design custom interfaces and intranet solutions that enhance collaboration, optimize workflows, and provide a seamless digital experience tailored to your organization’s needs.",
    },
    keypoints:{
        "title": "Technology that <span>works for you</span>, not against you",
        "span":"",
        "text": "A well-designed interface should empower your teams, streamline communication, and enhance productivity—but achieving this requires thoughtful design and technical expertise.",
    },
    banner:{
        "title": "The right interface, built for ",
        "span": "your requirements",
        "title2": "",
        "text": "<strong>Every organization has unique workflows and requirements.</strong><br><br>Our custom interfaces are designed to enhance collaboration, manage resources efficiently, and provide a seamless user experience. We tailor every solution to fit your exact needs, ensuring that your platform works for you, not against you.",
    },
    carousel:{
        "title": "Smart, scalable, and tailored for ",
        "span": "performance",
        "text": "We create bespoke interfaces that bridge the gap between people, processes, and technology, ensuring your digital ecosystem runs smoothly.",
    },
    form:{
        "title": "Transform your operations with a portal that ",
        "span": "works for you",
        "title2": "",
        "text": "Tell us about your project, and we’ll craft a solution that enhances productivity, security, and collaboration within your organization.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "Technology that elevates,",
        "span": "not complicate",
        "text": "Let’s create a custom, user-friendly, and secure interface that fits your organization’s requirements.",
        "button": "Let's talk",
    }
}