export const newOptimizely = {
    "title": "Optimizely CMS/DXP",
    "portfolio": "Some of our work",
    hero:{
        "title": "Smarter digital experiences<br>",
        "span": "with Optimizely",
        "text": "As certified Optimizely partners, we build sophisticated content and digital experience platforms (DXP) that seamlessly blend performance, personalization, and scalability to help businesses grow.",
    },
    keypoints:{
        "title": "Transforming content management into ",
        "span":"data-driven digital excellence",
        "text": "Today’s enterprises need more than just a CMS. Optimizely combines content, experimentation, AI-driven personalization, and commerce in a single powerful platform—but unlocking its full potential requires expert implementation.",
    },
    banner:{
        "title": "Beyond CMS—Your complete ",
        "span": "digital experience engine",
        "title2": "",
        "text": "<strong>Optimizely transcends traditional content management, offering a comprehensive platform for creating and optimizing data-driven customer journeys.</strong><br><br>From sophisticated content management to A/B testing and AI personalization, we customize Optimizely solutions to match your strategic objectives.",
    },
    carousel:{
        "title": "End-to-end solutions for<br>",
        "span": "Optimizely CMS & DXP",
        "text": "We provide complete Optimizely CMS/DXP solutions, from implementation to optimization, ensuring your platform is flexible, scalable, and future-ready.",
    },
    form:{
        "title": "Build your ",
        "span": "Optimizely<br>",
        "title2": "experience",
        "text": "Looking for a scalable, intelligent, and conversion-focused digital experience?<br><br>Tell us about your project, and we’ll craft a solution that transforms your digital presence with Optimizely.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "Your digital platform should",
        "span": "drive growth, not limit it.",
        "text": "Let’s build a scalable, intelligent, and high-performing Optimizely experience tailored to your business needs.",
        "button": "Let's talk",
    }
}