export const newMobile = {
    "title": "Applications mobiles",
    "portfolio": "Quelques-unes de nos réalisations",
    hero:{
        "title": "Transformez votre vision en une <span>expérience mobile</span> exceptionelle",
        "span": "",
        "text": "Nous concevons et développons des applications mobiles haute performance qui établissent une connexion avec les utilisateurs, stimulent l'engagement et s'alignent sur vos objectifs commerciaux.",
    },
    keypoints:{
        "title": "<span>Votre public est mobile</span>, votre entreprise devrait l'être aussi.",
        "span":"",
        "text": "Dans un monde numérique, les applications mobiles ne sont plus une option, elles sont essentielles. Mais une excellente application ne se limite pas à la fonctionnalité, il s'agit de créer une expérience engageante et intuitive qui incite les utilisateurs à revenir.",
        "keypoint1Title": "Difficultés à atteindre votre public?",
        "keypoint1Text": "Une application mobile met votre marque directement entre les mains des utilisateurs, améliorant l'accessibilité et l'engagement client.",
        "keypoint2Title": "Besoin d'une performance fluide multiplateforme?",
        "keypoint2Text": "Nous développons des applications natives et hybrides qui offrent des expériences fluides sur iOS, Android et au-delà.",
        "keypoint3Title": "À la recherche d'une solution évolutive et pérenne?",
        "keypoint3Text": "Nous concevons des applications sécurisées et évolutives qui évoluent avec votre entreprise et les besoins des utilisateurs.",
    },
    banner:{
        "title": "<span>Une application</span> qui a de l'impact",
        "span": "",
        "title2": "",
        "text": "<strong>Une application mobile devrait être plus que fonctionnelle, elle devrait créer une valeur durable.</strong><br><br>Que vous ayez besoin d'une application destinée aux clients, d'un outil interne pour l'entreprise ou d'une plateforme génératrice de revenus, nous développons des solutions qui allient performance, convivialité et innovation.",
    },
    carousel:{
        "title": "Des solutions personnalisées pour <span>iOS, Android</span> et au-delà",
        "span": "",
        "text": "Nous développons des applications mobiles riches en fonctionnalités, évolutives et haute performance, adaptées à vos besoins commerciaux spécifiques.",
        "slide1Title": "Applications natives<br>iOS et Android",
        "slide1Text": "Nous créons des applications entièrement optimisées et spécifiques aux plateformes pour l'App Store d'Apple et Google Play, garantissant des expériences utilisateur fluides.",
        "slide2Title": "Développement<br>multiplateforme et hybride",
        "slide2Text": "En utilisant des frameworks comme React Native et Flutter, nous créons des applications qui fonctionnent sans effort sur plusieurs plateformes.",
        "slide3Title": "Conception UI/UX<br>pour mobile",
        "slide3Text": "Nous créons des interfaces intuitives et visuellement attrayantes qui améliorent la convivialité et la satisfaction client.",
        "slide4Title": "Solutions mobiles<br>d'entreprise",
        "slide4Text": "Applications mobiles sur mesure conçues pour rationaliser les processus d'affaires, augmenter la productivité et améliorer les opérations internes.",
    },
    form:{
        "title": "Construisons votre<br>",
        "span": "audience mobile",
        "title2": "",
        "text": "Vos clients s'attendent à des expériences mobiles rapides, fluides et engageantes. Nous vous aidons à concevoir et développer une application qui établit une connexion avec les utilisateurs et génère des résultats concrets.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "La bonne expérience mobile alimente",
        "span": "l'engagement et la croissance.",
        "text": "Créons ensemble une application qui apporte de la valeur, génère des résultats et évolue avec votre entreprise.",
        "button": "Discutons-en",
    }
}