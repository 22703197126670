export const research = {
    "title": "Research & Consultation",
    "portfolio": "Some of our work",
    hero:{
        "title": "Research-driven insights, ",
        "span": "strategy-backed decisions",
        "text": "We provide in-depth research and expert consultation to help businesses make informed decisions, outperform competitors, and develop solutions that align with their goals.",
    },
    keypoints:{
        "title": "Strategy without research is just ",
        "span":"guesswork",
        "text": "A solid strategy starts with data. Whether it’s understanding your competitive landscape, identifying high-impact keywords, or designing solutions that solve real business challenges,research is the foundation of success.",
    },
    banner:{
        "title": "Data-driven decisions, ",
        "span": "smarter strategies",
        "title2": "",
        "text": "<strong>Success isn’t about following trends—it’s about understanding what works.</strong><br><br>Through competitive research, SEO analysis, and solution design, we provide the insights you need to craft winning strategies.",
    },
    carousel:{
        "title": "Targeted research & ",
        "span": "strategic consulting",
        "text": "We deliver tailored research and consulting services that empower businesses with the right data and actionable insights.",
    },
    form:{
        "title": "Research that leads to ",
        "span": "actionable results",
        "title2": "",
        "text": "Tell us about your challenges, and we’ll provide the research and strategic insights you need to move forward with confidence.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "Turn insights into",
        "span": "advantages",
        "text": "Allow us to support your strategy, using thorough research and proven methodologies.",
        "button": "Let's talk",
    }
}