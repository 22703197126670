export const newEmails = {
    "title": "Parcours de courriels",
    "portfolio": "Quelques-unes de nos réalisations",
    hero:{
        "title": "Développez de solides <span>relations clients</span>",
        "span": "",
        "text": "Transformez vos abonnés en clients fidèles grâce à des parcours de courriel automatisés qui délivrent le bon message au bon moment.",
    },
    keypoints:{
        "title": "<span>Marketing par courriel</span><br>plus intelligent",
        "span":"",
        "text": "Le marketing par email efficace ne se limite pas à l'envoi de messages. Il nécessite une communication pertinente et bien chronométrée basée sur le comportement des clients.",
        "keypoint1Title": "Faibles taux d'ouverture<br>et de clics?",
        "keypoint1Text": "Envoyez du contenu ciblé qui atteint votre audience quand elle est le plus susceptible de s'engager.",
        "keypoint2Title": "Opportunités de vente perdues?",
        "keypoint2Text": "Guidez les clients potentiels à travers votre processus de vente avec des séquences de courriel bien structurées.",
        "keypoint3Title": "Trop de tâches manuelles?",
        "keypoint3Text": "Automatisez les processus répétitifs tout en gardant les messages personnels et pertinents.",
    },
    banner:{
        "title": "Automatisation de courriel ",
        "span": "stratégique",
        "title2": "",
        "text": "<strong>L'automatisation n'est pas seulement une commodité—c'est un moyen de construire de meilleures relations clients.</strong><br><br>Nous utilisons les données, le timing et des stratégies de messagerie pour créer des séquences de courriel qui génèrent des résultats.",
    },
    carousel:{
        "title": "Communications au service de <span>votre développement</span>",
        "span": "",
        "text": "Nous créons des séquences de courriel automatisées qui s'adaptent au comportement des clients et vous aident à atteindre vos objectifs.",
        "slide1Title": "Emails<br>d'intégration",
        "slide1Text": "Accueillez les nouveaux abonnés avec des messages qui présentent votre marque et établissent les attentes.",
        "slide2Title": "Automatisation basée<br>sur le comportement",
        "slide2Text": "Des emails qui s'ajustent en fonction de la façon dont les abonnés interagissent avec votre contenu.",
        "slide3Title": "Nurturing<br>de leads",
        "slide3Text": "Des séries de courriel structurées qui amènent les prospects vers un achat.",
        "slide4Title": "Cartographie du parcours<br>client",
        "slide4Text": "Un plan qui définit comment et quand communiquer avec les clients pour un meilleur engagement.",
    },
    form:{
        "title": "Préparez une <span>stratégie de courriel</span> efficace",
        "span": "",
        "title2": "",
        "text": "Créons ensemble des séquences de courriel automatisées qui augmentent l'engagement et stimulent les ventes.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "Superchargez votre",
        "span": "marketing par courriel",
        "text": "Construisons un système automatisé qui vous aide à vous connecter avec vos clients et à développer votre entreprise.",
        "button": "Discutons-en",
    }
}