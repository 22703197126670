export const industries = {
    "governments":"Gouvernement",
    "healthcares":"Santé",
    "finances":"Finance",
    "medias":"Médias",
    "defences":"Défense",
    "title": "sectorielle",
    "titleSpan": "Expertise ",
    "titleText": "Nous créons des solutions numériques personnalisées grâce à notre vaste expérience dans de multiples industries. En comprenant les besoins techniques spécifiques, les exigences réglementaires et les contraintes opérationnelles propres à chaque secteur, nous fournissons des solutions pratiques qui s'alignent parfaitement avec les attentes des utilisateurs et les normes de l'industrie. ",
    government:{
        hero:{
            "type": "Administration publique ",
            "title": "Modernisez les services publics grâce à une ",
            "span": "transformation numérique et une approche réaliste ",
            "subtitle": "Conciliez les attentes des citoyens avec les capacités administratives ",
            "text": "Les organismes publics fonctionnent avec des systèmes obsolètes qui créent des goulots d'étranglement et épuisent les ressources financées par les contribuables. Les citoyens s'attendent désormais à ce que les services gouvernementaux respectent les standards numériques du secteur privé, créant une pression de mise en œuvre malgré des contraintes budgétaires strictes. ",
        },
        block1:{
            "title": "Investissements technologiques responsables",
            "text": "Les organisations du secteur public font l'objet d'un contrôle rigoureux concernant leurs dépenses technologiques. Nous développons des solutions avec des bénéfices publics documentables, prolongeons la durée de vie des infrastructures existantes et construisons des systèmes durables qui résistent aux changements administratifs.",
        },
        block2:{
            "title": "Accessibilité des services publics",
            "text": "La complexité des processus et le manque d'interconnexion des systèmes irritent les citoyens et alourdissent le travail administratif. Nos plateformes simplifient les interactions publiques, réduisent les contraintes administratives et créent des parcours usagers logiques, améliorant la satisfaction des usagers tout en diminuant la charge de travail du personnel. ",
        },
        block3:{
            "title": "Pilotage par l'information",
            "text": "Les institutions publiques accumulent d'importantes données sans pouvoir les convertir en renseignements exploitables. Nous construisons des cadres analytiques qui transforment une multitude d'informations non structurées en analyses cohérentes, soutenant des décisions politiques fondées sur des preuves qui optimisent l'allocation des ressources et l'efficacité des services. ",
        },
    },
    healthcare:{
        hero:{
            "type": "Secteur de la santé ",
            "title": "Optimisez les performances médicales avec des ",
            "span": "outils numériques de soutien",
            "subtitle": "Des solutions qui renforcent le savoir-faire médical sans se substituer à l'appréciation clinique",
            "text": "Le personnel soignant est confronté à un fardeau administratif qui limite son temps auprès des patients. Bien intégrés, les outils numériques simplifient les tâches administratives tout en appuyant les praticiens, optimisant simultanément la qualité des soins et la performance organisationnelle. ",
        },
        block1:{
            "title": "Gestion efficiente des données médicales",
            "text": "Les professionnels de santé consacrent un temps considérable à la tenue des dossiers au détriment des soins. Nous créons des systèmes intuitifs qui minimisent la documentation administrative, améliorent la saisie d'informations et assurent la conformité réglementaire sans compromettre la relation soignant-patient. ",
        },
        block2:{
            "title": "Continuité des données de santé ",
            "text": "Nos solutions d'intégration relient les différentes plateformes médicales entre elles, assurant un transfert sécurisé des données patients entre départements et praticiens. Cette interconnexion élimine la redondance des saisies et maintient une continuité communicationnelle essentielle. Elle permet aux professionnels de santé d'accéder à une vision complète du parcours patient. ",
        },
        block3:{
            "title": "Suivi médical personnalisé ",
            "text": "Ces interfaces conviviales réduisent considérablement le nombre de consultations non nécessaires tout en renforçant la relation de confiance entre patients et soignants. Grâce à une information médicale claire et accessible, les patients bénéficient d'une meilleure compréhension de leur santé. ",
        },
    },
    finance:{
        hero:{
            "type": "Institutions financières ",
            "title": "Créez des échanges financiers qui inspirent confiance et ",
            "span": "maintiennent la stabilité institutionnelle",
            "subtitle": "Confidentialité absolue et transparence financière, le fondement de notre plateforme bancaire. ",
            "text": "Les organismes financiers conjuguent impératifs de sécurité stricts et attentes d'une clientèle en constante évolution. Les systèmes existants restreignent l'agilité opérationnelle, générant des faiblesses devant des compétiteurs plus souples imposant leurs services financiers fluides et intuitifs, désormais considérés comme la norme par les clients. ",
        },
        block1:{
            "title": "Protocoles de sécurité sans friction ",
            "text": "Les mesures de sécurité engendrent fréquemment de la frustration chez les clients. Nous concevons des processus d'authentification et de transaction qui maintiennent une protection rigoureuse tout en éliminant les complications superflues, établissant ainsi un équilibre optimal entre conformité réglementaire et utilisabilité pratique. ",
        },
        block2:{
            "title": "Modernisation des infrastructures essentielles du secteur bancaire",
            "text": "Les systèmes bancaires fondamentaux présentent une complexité de remplacement considérable. Notre approche consiste à développer des interfaces utilisateur modernes compatibles avec les infrastructures en place, permettant ainsi de préserver la valeur des investissements technologiques antérieurs tout en offrant des expériences contemporaines répondant aux exigences actuelles des utilisateurs. ",
        },
        block3:{
            "title": "Différenciation par l'expérience client  ",
            "text": "Les établissements financiers se distinguent aujourd'hui par la qualité de leur service client. Nos solutions personnalisées valorisent vos forces institutionnelles et ciblent les besoins de segments de marché précis, façonnant des expériences client uniques inégalables par les plateformes génériques. ",
        },
    },
    media:{
        hero:{
            "type": "Médias et communications ",
            "title": "Transformez le contenu éditorial en",
            "span": "résultats commerciaux mesurables ",
            "subtitle": "Modèles de revenus durables pour les médias numériques ",
            "text": "Dans un contexte où les modèles classiques s'essoufflent, les entreprises médiatiques font face à des défis majeurs pour fidéliser leur audience et générer des revenus. L'excellence technique s'impose désormais comme un véritable avantage concurrentiel, permettant d'offrir une expérience utilisateur optimisée, de renforcer l'engagement sur les contenus et de diversifier les stratégies de monétisation. ",
        },
        block1:{
            "title": "Optimisation de la fidélisation des lecteurs ",
            "text": "L'abandon rapide des pages et le manque d'interaction avec les contenus diminuent leur valeur. Nous développons des plateformes performantes avec des suggestions de lecture personnalisées, ce qui améliore significativement l'attention du public - un facteur clé pour optimiser les revenus publicitaires et transformer les visiteurs en abonnés. ",
        },
        block2:{
            "title": "Multiplication des sources de monétisation",
            "text": "Les revenus publicitaires classiques ne permettent plus de financer des contenus premium. Nos solutions intégrées pour la gestion des abonnements, micropaiements et programmes membres instaurent des flux de revenus diversifiés face à un marché publicitaire toujours plus saturé. ",
        },
        block3:{
            "title": "Amélioration des processus de production éditoriale ",
            "text": "Les contraintes de ressources limitent la production de contenu de qualité. L'implémentation de processus de publication simplifiés et d'outils de gestion efficaces permet de réduire les obstacles techniques. Ainsi, les équipes éditoriales peuvent se consacrer pleinement à la création de contenus à forte valeur ajoutée plutôt qu'à la gestion logistique des publications. ",
        },
    },
    military:{
        hero:{
            "type": "Forces armées ",
            "title1": "Mettez en œuvre des infrastructures techniques qui  ",
            "title2": "garantissent la continuité des opérations",
            "span1": "",
            "span2": "",
            "subtitle": "Protection intégrée dès la conception, performance stable même en zone de conflit ",
            "text": "Les institutions militaires évoluent dans des cadres où toute défaillance technique met en péril les objectifs opérationnels. Les systèmes d'information doivent conserver leur opérabilité contre des menaces adverses complexes tout en assurant des fonctions critiques dans des environnements contraints et détériorés. ",
        },
        block1:{
            "title": "Infrastructure de sécurité opérationnelle ",
            "text": "Les systèmes de défense font face à des cybermenaces sophistiquées que les solutions commerciales standards ne peuvent contrer. Notre approche intègre des dispositifs de sécurité renforcés qui protègent les données sensibles grâce à une architecture défensive multicouche, spécifiquement développée pour résister aux attaques dans les environnements à haut risque. ",
        },
        block2:{
            "title": "Robustesse des transmissions ",
            "text": "Les opérations militaires se déroulent régulièrement dans des environnements réseau compromis. Nos solutions garantissent que les fonctionnalités critiques persistent quelle que soit la qualité de la connexion, avec une réduction progressive des capacités plutôt qu'une défaillance complète lorsque les réseaux font face à des perturbations. ",
        },
        block3:{
            "title": "Solutions d'appui à la décision stratégique ",
            "text": "Un leadership efficace nécessite un accès immédiat aux informations critiques pour la mission. Nous développons des systèmes d'information spécialisés permettant aux commandants d'accéder rapidement aux renseignements pertinents à travers différents niveaux de sécurité, tout en maintenant les protocoles de confidentialité appropriés et l'intégrité des données. ",
        },
    },
}