export const commerce = {
    "title": "E-Commerce",
    "portfolio": "Some of our work",
    hero:{
        "title": "Where vision<br>",
        "span": "meets revenue",
        "text": "We architect digital commerce platforms that blend innovation with reliability, transforming browsers into buyers through intelligent design and strategic optimization.",
    },
    keypoints:{
        "title": "Digital commerce demands ",
        "span":"more than templates",
        "text": "Whether you're scaling with Shopify Plus, leveraging Drupal Commerce's flexibility, or harnessing Optimizely's enterprise capabilities, modern e-commerce requires solutions that adapt to shifting consumer behaviors.",
    },
    banner:{
        "title": "Commerce that ",
        "span": "captures imagination",
        "title2": "",
        "text": "<strong>VFrom Shopify Plus's scalability to Optimizely's enterprise features, we select and implement platforms that align with your growth trajectory.</strong><br><br>Our solutions merge human insight with machine intelligence, creating experiences that transform transactions into relationships.",
    },
    carousel:{
        "title": "E-commerce solutions that ",
        "span": "drives growth",
        "text": "Leveraging platforms from Shopify Plus to Optimizely Commerce, we deliver comprehensive solutions that combine enterprise-grade reliability with cutting-edge intelligence.",
    },
    form:{
        "title": "Elevate your ",
        "span": "digital commerce",
        "title2": "",
        "text": "Ready to transform your retail vision into digital excellence? Let's build an e-commerce ecosystem that drives sustainable growth.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "Expand your reach using ",
        "span": "e-commerce",
        "text": "Let’s create a predictive commerce strategy that motivates your audience to purchase.",
        "button": "Let's talk",
    }
}