export const newAi = {
    "title": "Artificial Intelligence",
    "portfolio": "Some of our work",
    hero:{
        "title": "Transform AI from a concept into a ",
        "span": "competitive edge",
        "text": "We develop intelligent AI solutions that enhance decision-making, automate complex workflows, and drive measurable business impact.",
    },
    keypoints:{
        "title": "AI isn’t just about automation,",
        "span":"it’s about transformation",
        "text": "AI is no longer a futuristic concept; it’s a business necessity. Companies that leverage AI gain efficiency, predictive insights,and adaptive learning capabilities—but to harness its full potential, you need the right strategy.",
    },
    banner:{
        "title": "AI-powered solutions for a ",
        "span": "smarter future",
        "title2": "",
        "text": "<strong>AI isn’t just about making processes faster—it’s about making them smarter.</strong><br><br> We create tailored AI solutions that seamlessly integrate with your existing systems, allowing businesses to automate, predict, and optimize operations.",
    },
    carousel:{
        "title": "Innovative AI solutions built for ",
        "span": "real-world impact",
        "text": "We design, develop, and deploy AI-driven solutions that optimize performance, improve decision-making, and enhance customer experiences.",
    },
    form:{
        "title": "Leveraging AI frameworks to ",
        "span": "foster innovation",
        "title2": "",
        "text": "Tell us about your AI project, and we’ll develop a solution that transforms your operations, improves your efficiency, and creates smarter interactions.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "AI to empower,",
        "span": "and revolutionize.",
        "text": "Let’s develop a scalable, intelligent AI solution that drives innovation and efficiency for your organization.",
        "button": "Let's talk",
    }
}