export const caseStudies = {
  "let_us_show_you": "Laissez-nous vous montrer les solutions qui",
  "exceeded": "ont dépassé",
  "our_customers_expectations": "les attentes de nos clients.",
  "our_work": "Portfolio",
  "our_work2": "Études de cas",
  "creativity" : "Une image vaut ",
  "in_action": "mille mots",
  "portfolio": "Notre équipe combine une technologie de pointe avec une réflexion stratégique pour créer des sites web qui ne sont pas seulement visuellement impressionnants, mais aussi de puissants outils d'affaires.",
  "intranet": "Intranet",
  "e_learning_platform": "Une plateforme complète d'apprentissage en ligne conçue spécifiquement pour le secteur de l'enseignement supérieur.",
  "drupal": "Drupal",
  "e_commerce": "E-commerce",
  "vue_js": "VueJS",
  "aws": "AWS",
  "read_more": "Lire la suite",
  "team_description": "Nous sommes une petite équipe de programmeurs séniors qui sait vous ",
  "listen": "écouter",
  "75_automations": "75+ automatisations",
  "payment_system": "Solution de paiement",
  "fully_automated_system": "Un système entièrement automatisé de gestion du temps et des tâches qui génère automatiquement des factures, des e-mails, des budgets et bien plus encore.",
  "react": "React",
  "zoho": "Zoho",
  "simplicity_and_security": "Nous construisons des interfaces complexes, à la fois ",
  "simplicity": "simples",
  "and": "et",
  "security": "sécuritaires. ",
  "in_mind": "",
  "contest_system": "Système de concours",
  "secure_contest_management_system": "Un système de gestion des concours sécurisé et conçu pour monitorer facilement plusieurs microsites tout en étant capable de gérer des millions de participants.",
  "laravel": "Laravel",
  "nuxt": "Nuxt",
  "docker": "Docker",
  "challenge": "<span>Comprendre</span> le défi",
  "solution": "Trouver la bonne <span>solution</span>",
  "results": "Livrer des résultats <span>incroyables</span>",

  "finance1": "Et des solutions",
  "finance2": "innovatrices",
  "finance3": "pour le secteur de la finance",
  "nsi1": "Une solution unique au Canada autorisant les utilisateurs de Zoho Books à émettre des paiements aux fournisseurs de manière automatique, en répondant aux exigences de la norme ACP 005",
  'see_more': 'Voir plus de projets',
  'see_more2': 'Voir plus de projets',

  webcampus: {
    "customerLogo": "webcampus-logo",
    "mainImage": "webcampus1",
    "text1": "Une plateforme d'apprentissage en ligne complète conçue spécifiquement pour le secteur de l'enseignement supérieur.",
    "platforms": "Web, bureau et mobile",
    "deliverables": "UI, UX, interface d'administration et e-commerce",
    "techStack": "Symfony 5, Drupal 9 et AWS",
    "challenge": "Un groupe de professeurs d'université nous a contacté pour développer une solution d'apprentissage en ligne pour les responsables de départements internes et les partenaires du secteur privé",
    "challengeImage": "webcampus-tablet",
    "solution": "Notre équipe a pris la décision stratégique d'exploiter les fonctionnalités puissantes de Drupal pour développer une plateforme d'apprentissage en ligne robuste avec des mesures de sécurité avancées et des capacités de commerce électronique.",
    "icon1Text": "Principes de couleur autoritaires",
    "icon2Text": "Design propre et intuitif",
    "icon3Text": "Mise en page responsive",
    "icon4Text": "CMS Drupal 9",
    "icon5Text": "Architecture de microservices AWS",
    "icon6Text": "Sécurité des utilisateurs et des cours",
    "presentationImage": "webcampus-devices",
    "results": "Alors que la plateforme est encore en phase bêta et n'est pas accessible au grand public, notre système compte actuellement plus de 5000 utilisateurs du secteur privé et propose plus de 900 heures de contenu de haute qualité pour la formation continue des responsables et du personnel universitaire.",
    "result1": "5000+",
    "result1Text": "utilisateurs mensuels",
    "result2": "900+",
    "result2Text": "heures de contenu de qualité",
    "result3": "+35%",
    "result3Text": "croissance de l'engagement mensuel",
    "result4": "1M+",
    "result4Text": "en ventes de cours"
  },
  jbconseil: {
    "title": "Une solution entièrement automatisée de gestion des tâches, du temps et des rapports pour l'industrie comptable.",
    "platforms": "Web, bureau et tablettes",
    "deliverables": "75+ automatisations, intégrations tierces",
    "techStack": "Drupal 9, React, Zoho Books",
    "understandingTitle": "Comprendre le défi",
    "understandingText": "Une entreprise spécialisée dans la comptabilité d'entreprise avait besoin d'une solution innovante pour maximiser les processus de gestion en automatisant les affectations de tâches, le suivi du temps, la génération de rapports et les opérations de facturation.",
    "solutionTitle": "Trouver la bonne solution",
    "solutionText": "Notre équipe a choisi d'exploiter les capacités exceptionnelles de l'API Queue Operations de Drupal pour créer plus de 75 scénarios d'attribution de tâches automatisés afin d'automatiser complètement les entrées de tâches et de temps, ainsi que les processus de facturation et de facturation.",
    "icon1Text": "75+ scénarios automatisés",
    "icon2Text": "Intégration Zoho Books",
    "icon3Text": "Mise en page responsive",
    "icon4Text": "Allocation de tâches automatisée",
    "icon5Text": "Entrées de temps automatisées",
    "icon6Text": "Facturation automatisée",
    "resultsTitle": "Obtenir des résultats exceptionnels",
    "resultsText": "Notre solution innovante a permis au client d'économiser des milliers d'heures chaque année, leur permettant d'augmenter leur trésorerie avec des efforts de gestion réduits et de se concentrer sur leur expertise principale : la comptabilité.",
    "result1": "500k",
    "result1Text": "économisés en salaires annuellement",
    "result2": "2600+",
    "result2Text": "heures économisées annuellement",
    "result3": "175%",
    "result3Text": "ROI",
    "result4": "75+",
    "result4Text": "automatisations"
  },
  haribo: {
    "title": "Une solution intuitive de gestion de concours pour créer et gérer facilement des promotions en ligne",
    "platforms": "Web, bureau et mobile",
    "deliverables": "Système de concours, micro-sites intégrés",
    "techStack": "Laravel, NuxtJS, Docker",
    "understandingTitle": "Comprendre le défi",
    "understandingText1": "Haribo, l'entreprise derrière les oursons en gélatine que nous connaissons et aimons tous, nous a chargés de développer une plateforme sécurisée de gestion de concours.<br/><br/>Cette solution backend sophistiquée faciliterait le déploiement de concours et de promotions en ligne, tout en élargissant simultanément leur base d'abonnés pour des initiatives marketing futures.",
    "understandingText2": "",
    "solutionTitle": "Trouver la bonne solution",
    "solutionText": "En tirant parti de la puissance de Laravel et NuxtJS, notre équipe a développé une application web conçue pour générer des micro-sites de concours ultra-rapides. Avec un accent particulier sur la sécurité, la plateforme est équipée de toutes les fonctionnalités essentielles requises pour lancer efficacement des promotions nationales.",
    "icon1Text": "Système multi-concours",
    "icon2Text": "Micro-sites ultra-rapides",
    "icon3Text": "Mise en page réactive",
    "icon4Text": "Coffre-fort de données sécurisé",
    "icon5Text": "Gestion des prix",
    "icon6Text": "Système de reporting",
    "resultsTitle": "Obtenir des résultats exceptionnels",
    "resultsText": "La plateforme s'est avérée être un succès retentissant et est désormais activement utilisée pour gérer des concours à travers la nation, recueillant des centaines de milliers de participations par campagne promotionnelle.",
    "result1": "1,5M+",
    "result1Text": "participations aux concours combinées",
    "result2": "500k+",
    "result2Text": "nouveaux abonnés par e-mail",
    "result3": "200",
    "result3Text": "prix sympas distribués"
  },
  nsi: {
    "title": "Une solution de paiement (ACP 005) automatisée pour les utilisateurs de Zoho Books au Canada",
    "customerLogo": "logo-nsi",
    "mainImage": "nsi-main",
    "text1": "Une solution unique au Canada permettant aux utilisateurs de Zoho Books d'émettre des paiements aux fournisseurs de manière automatique, en répondant aux exigences de la norme ACP 005 établie par l'Association canadienne des paiements.",
    "platforms": "Web, Bureau",
    "deliverables": "API, Solution de paiement",
    "techStack": "Symfony 5, VueJS et Zoho Books",
    "challenge": "Développer un écosystème sécuritaire, qui respecte la norme ACP 005, et qui offre aux utilisateurs de Zoho Books la possibilité d’exporter leurs paiements fournisseurs et de les synchroniser avec leur banque.",
    "challengeImage": "nsi-tablet",
    "solution": "Notre équipe a élaboré la première solution de paiements Zoho qui est conforme à la norme fédérale Canadienne en matière de paiements électroniques. Intégré à Zoho Books, le système facilite les transactions en permettant d’exporter et de payer les factures fournisseurs en une seule opération.",
    "icon1Text": "Synchronisation bi-directionnelle avec Zoho Books",
    "icon2Text": "Interface simple et intuitive",
    "icon3Text": "Environnement sécurisé",
    "icon4Text": "Paiement des factures fournisseurs",
    "icon5Text": "Synchronisation des feuilles de temps",
    "icon6Text": "Rapports et statistiques",
    "presentationImage": "webcampus-devices",
    "results": "Depuis le lancement de cette plateforme, plus de 5 millions de transactions ont été effectuées avec les banques canadiennes autorisées. L'économie de temps pour les gestionnaires des services des finances et de comptabilité est considérable.",
    "result1": "5M+",
    "result1Text": "transactions",
    "result2": "340%",
    "result2Text": "gains d'efficacité comptable",
    "result3": "100 000$+",
    "result3Text": "frais annuels économisés",
    "result4": "400+",
    "result4Text": "usagers Zoho Books satisfaits"
  },
}
