export const newVrar = {
    "title": "VR/AR Experiences",
    "portfolio": "Some of our work",
    hero:{
        "title": "Step beyond reality with ",
        "span": "immersive digital experiences",
        "text": "We create interactive VR and AR solutions that captivate audiences, enhance engagement, and bring your brand to life in entirely new dimensions.",
    },
    keypoints:{
        "title": "Unique activations that ",
        "span":"redefine engagement",
        "text": "Virtual and augmented reality are reshaping how businesses connect, engage, and communicate—but creating meaningful VR/AR experiences requires expertise in both technology and storytelling.",
    },
    banner:{
        "title": "Experiences that ",
        "span": "make you feel",
        "title2": "",
        "text": "<strong>VR and AR go beyond traditional screens—they place users inside the experience.</strong><br><br> Whether it’s virtual tours, interactive training simulations, or augmented product interactions, we create high-impact solutions that leave a lasting impression.",
    },
    carousel:{
        "title": "Custom-built <span>virtual reality experiences</span> for every industry",
        "span": "",
        "text": "We develop highly immersive VR/AR solutions tailored to businesses, events, education, and entertainment.",
    },
    form:{
        "title": "VR/AR experiences ",
        "span": "are standing out",
        "title2": "",
        "text": "Tell us about your project, and we’ll create an immersive VR/AR solution that drives innovation and impact.",
    },
    testimonials:{
        "title": "Check what our clients",
        "span": "say about us",
    },
    bottom:{
        "title": "Innovation happens where ",
        "span": "reality meets imagination",
        "text": "Let’s create a fully immersive VR or AR experience that transforms how your audience interacts with your brand.",
        "button": "Let's talk",
    }
}