export const audits = {
    "title": "Audits techniques",
    "portfolio": "Une partie de notre travail",
    hero:{
        "title": "Des perspectives qui supportent des ",
        "span": "améliorations exploitables",
        "text": "Nous menons des recherches et des audits approfondis pour évaluer ce qui fonctionne, identifier les opportunités manquées et optimiser les stratégies numériques pour un succès à long terme.",
    },
    keypoints:{
        "title": "Évaluez votre position ",
        "span":"et les axes d'amélioration",
        "text": "Comprendre vos forces et vos faiblesses est la clé de la croissance. Nos services de recherche et d'audit fournissent une image claire de votre performance, vous aidant à affiner vos stratégies, corriger les inefficacités et exploiter les opportunités inexploitées.",
    },
    banner:{
        "title": "<span>Une approche axée sur les données</span> pour conquérir le monde moderne",
        "span": "",
        "title2": "",
        "text": "<strong>Une présence numérique forte ne se construit pas sur des suppositions.</strong><br><br>Grâce à des recherches et des audits approfondis, nous déterminons ce qui est efficace, ce qui vous freine et ce qui doit être optimisé—vous aidant à construire une stratégie plus intelligente et plus efficace.",
    },
    carousel:{
        "title": "<span>Recherche et audit stratégiques</span> pour une croissance quantifiable",
        "span": "",
        "text": "Nous fournissons des recherches et des audits stratégiques qui révèlent des insights, corrigent les inefficacités et favorisent une prise de décision éclairée.",
    },
    form:{
        "title": "Les audits mènent à ",
        "span": "de meilleures décisions",
        "title2": "",
        "text": "Parlez-nous de vos défis, et nous vous fournirons les perspectives et recommandations pour vous aider à affiner et optimiser votre stratégie.",
    },
    testimonials:{
        "title": "Découvrez ce que nos clients",
        "span": "disent sur nous",
    },
    bottom:{
        "title": "Optimisez, améliorez et",
        "span": "gardez une longueur d'avance",
        "text": "Une stratégie basée sur la recherche mène à de meilleures décisions commerciales. Auditons et affinons votre approche numérique pour un impact maximal.",
        "button": "Discutons-en",
    }
}